import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

    constructor(
        private primengConfig: PrimeNGConfig,
        private translateService: TranslateService
    ) {
        this.translateService.addLangs([ 'en' ]);
        this.translateService.setDefaultLang('en');


        const browserLang = this.translateService.getBrowserLang();
        const lang = browserLang.match(/en/) ? browserLang : 'en';
        this.translateService.use(lang);
    }

    ngOnInit() {
        this.primengConfig.ripple = true;
    }
}
