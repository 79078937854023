import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Injectable, effect, inject } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Observable, catchError, of, throwError } from "rxjs";
import { AuthState } from "src/app/shared/auth/auth.state";
import { NotificationsService } from "src/app/shared/services/notifications.service";
import { PrimeFilteringService } from "src/app/shared/services/prime-filtering.service";
import { environment } from "src/environments/environment";
@Injectable({ providedIn: 'root' })
export class MeasurementGuideStepsService {
  private readonly modelAPI = environment.baseApiUrl + 'measuring-guides';
  private readonly defaultOptions = {
    headers: new HttpHeaders({}) 
  };

  private authState = inject(AuthState);
  private notificationsService = inject(NotificationsService);
  private translateService = inject(TranslateService);
  private primeFilteringService = inject(PrimeFilteringService);

  constructor(private httpClient: HttpClient) {
    effect(() => {
        this.defaultOptions.headers = this.defaultOptions.headers.set(
            'Authorization',
            `Bearer ${ this.authState.token() }`
        );
    })

    if (this.authState.isAuthenticated()) {
        this.defaultOptions.headers = this.defaultOptions.headers.set(
            'Authorization',
            `Bearer ${ this.authState.token() }`
        );
    }
  }

  getAll(measurementGuide, payload): Observable<object[]> {    
    let url = new URL(this.modelAPI + `/${measurementGuide.id}/steps`);

    if (payload.pageSize) {
        url.searchParams.set('per_page', payload.pageSize);
        url.searchParams.set('page', payload.pageNumber);
    }

    if (payload.filter) {
        let filters = this.primeFilteringService.build(payload.filter);
        Object.entries(filters).forEach(([ key, value ]) => url.searchParams.set(key, value as string));
    }

    if (payload.order) {
        url.searchParams.set('sort', payload.order.direction + payload.order.field);
    }

    return this.httpClient.get<object[]>(url.toString(), this.defaultOptions)
    .pipe(
        catchError(() => {
          this.notificationsService.error(this.translateService.instant('measurement-guide.steps.messages.show_error'));
          return of();
        })
    );
  }

  getOne(measurementGuide, entity): Observable<object> {
    return this.httpClient.get<object[]>(`${this.modelAPI}/${measurementGuide['id']}/steps/${entity['id']}`,this.defaultOptions)
    .pipe(
        catchError(() => {
          this.notificationsService.error(this.translateService.instant('measurement-guide.steps.messages.get_one_error'));
          return of();
        })
    );
  }

  create(measurementGuide, entity): Observable<object> {
    return this.httpClient.post<object>(`${this.modelAPI}/${measurementGuide['id']}/steps`, entity, this.defaultOptions)
    .pipe(
        catchError(() => {
          this.notificationsService.error(this.translateService.instant('measurement-guide.steps.messages.create_error'));
          return of();
        })
    );
  }

  update(measurementGuide, entity, data): Observable<object> {
    return this.httpClient.put<object>(`${this.modelAPI}/${measurementGuide['id']}/steps/${entity['id']}`, data, this.defaultOptions).pipe(
      catchError(() => {
        this.notificationsService.error(this.translateService.instant('measurement-guide.steps.messages.update_error'));
        return of();
      })
    );
  }

  delete(measurementGuide, entity): Observable<object> {
    return this.httpClient.delete<object>(`${this.modelAPI}/${measurementGuide['id']}/steps/${entity['id']}`,this.defaultOptions)
        .pipe(
            catchError(() => {
              this.notificationsService.error(this.translateService.instant('measurement-guide.steps.messages.delete_error'));
              return of();
            })
        );
  }

  addImage(measurementGuide, entity, data): Observable<object> {
    return this.httpClient.post<object>(`${this.modelAPI}/${measurementGuide['id']}/steps/${entity['id']}/images`, data, this.defaultOptions)
    .pipe(
        catchError(() => {
          this.notificationsService.error(this.translateService.instant('measurement-guide.steps.messages.add_image_error'));
          return of();
        })
    );
  }

  deleteImage(measurementGuide, entity, image): Observable<object> {
    return this.httpClient.delete<object>(`${this.modelAPI}/${measurementGuide['id']}/steps/${entity['id']}/images/${image['id']}`, this.defaultOptions)
    .pipe(
        catchError(() => {
          this.notificationsService.error(this.translateService.instant('measurement-guide.steps.messages.delete_image_error'));
          return of();
        })
    );
  }
}