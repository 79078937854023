import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, inject, } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { AuthState } from './auth.state';
import { Router } from '@angular/router';
import { NotificationsService } from '../services/notifications.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';

type User = {
    id: number,
    name: string,
    email: string,
    email_verified_at: Date,
    created_at: Date,
    updated_at: Date,
    deleted_at: Date,
    logged_at: Date
}

const MAIN_ROUTE = '';
const LOGIN_ROUTE = 'login';

@Injectable({ providedIn: 'root' })
export class AuthService {
    private httpClient = inject(HttpClient);
    private router = inject(Router);
    private translateService = inject(TranslateService);
    private notificationService = inject(NotificationsService);
    private state = inject(AuthState);

    private readonly ENDPOINTS = {
        currentUser: environment.baseApiUrl + 'users/me',
        login: environment.baseApiUrl + 'login',
        logout: environment.baseApiUrl + 'logout',
    }


    protected defaultOptions = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            'Accepts': 'application/json'
        })
    };

    constructor() {
        if (this.state.isAuthenticated()) {
            this.defaultOptions.headers = this.defaultOptions.headers.append(
                'Authorization',
                `Bearer ${ this.state.token() }`
            );
        }
    }

    private async getToken({ email, password }) {
        let response = await lastValueFrom(
            this.httpClient.post<{ token: string }>(
                this.ENDPOINTS[ 'login' ],
                { email, password }
                , this.defaultOptions)
        );
        this.state.setToken(response.token);
        this.defaultOptions.headers = this.defaultOptions.headers.append(
            'Authorization',
            `Bearer ${ this.state.token() }`
        );
    }

    async login({ email, password }) {
        try {
            await this.getToken({ email, password });
            let user = await lastValueFrom(
                this.httpClient.get<User>(this.ENDPOINTS[ 'currentUser' ], this.defaultOptions)
            );
            this.state.setCurrentUser(user);
            await this.router.navigate([ MAIN_ROUTE ]);
        } catch (error) {
            this.notificationService.error(this.translateService.instant('auth.errorOnLogin'));
        }
    }

    async logout() {
        this.state.clear();

        if (this.state.isAuthenticated()) {
            console.log(this.defaultOptions);

            await lastValueFrom(
                this.httpClient.post(this.ENDPOINTS[ 'logout' ], null, this.defaultOptions)
            );
        }

        await this.router.navigate([ LOGIN_ROUTE ]);
    }
}
