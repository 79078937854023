<div class="grid">
    <div class="col-12">
        <div class="card">
            <h5>{{ 'device-list.table.title' | translate }}</h5>
            <p-table #dataTable [value]="entities()" [scrollable]="true" scrollHeight="calc(100vh - 380px)"
                [rowHover]="true" styleClass="p-datatable-sm" [(selection)]="selectedEntities" responsiveLayout="scroll"
                lazy="true" [lazyLoadOnInit]="lazyLoadOnInit" (onLazyLoad)="onLazyLoad($event)" 
                sortField="name" paginator="true" [rows]="rows"
                [first]="first" [totalRecords]="entitiesCount" [rowsPerPageOptions]="[10, 20, 30, 50, 100]"
                [showCurrentPageReport]="true"
                [currentPageReportTemplate]="translateService.instant('table.paginator.template')">
                <ng-template pTemplate="caption">
                    <div class="flex justify-content-between align-items-center">
                        <div>
                            <button pButton pRipple icon="pi pi-plus"
                                class="p-button-outlined p-button-rounded p-button-success mr-2" (click)="newEntity()">
                                <span class="hidden md:block ml-2"><b>{{ translateService.instant('app.new')
                                        }}</b></span>
                            </button>
                            <button pButton pRipple icon="pi pi-trash"
                                class="p-button-outlined p-button-rounded p-button-danger mr-2"
                                (click)="deleteSelectedEntities()"
                                [disabled]="!selectedEntities || !selectedEntities.length">
                                <span class="hidden md:block ml-2"><b>{{ translateService.instant('app.delete')
                                        }}</b></span>
                            </button>
                            <button pButton pRipple icon="pi pi-file-excel"
                                class="p-button-outlined p-button-rounded p-button-success mr-2"
                                (click)="exportExcel(dataTable.filters, dataTable.sortField, dataTable.sortOrder)">
                                <span class="hidden md:block ml-2"><b>{{ translateService.instant('app.export') }}</b></span>
                            </button>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th style="width: 3rem">
                            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                        </th>
                        <th pSortableColumn="name">
                            <div class="flex justify-content-between align-items-center">
                                <div>
                                    {{ 'device-list.table.name' | translate }}
                                    <p-sortIcon field="name"></p-sortIcon>
                                </div>
                                <p-columnFilter type="text" field="name" display="menu"
                                    placeholder="{{ 'table.filter.textPlaceholder' | translate }}"></p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="created_at">
                            <div class="flex justify-content-between align-items-center">
                                <div>
                                    {{ 'device-list.table.created_at' | translate }}
                                    <p-sortIcon field="created_at"></p-sortIcon>
                                </div>
                                <p-columnFilter type="date" field="created_at" display="menu"
                                    placeholder="{{ 'table.filter.textPlaceholder' | translate }}"></p-columnFilter>
                            </div>
                        </th>
                        <th></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-entity>
                    <tr>
                        <td>
                            <p-tableCheckbox [value]="entity"></p-tableCheckbox>
                        </td>
                        <td>
                            {{entity.name}}
                        </td>
                        <td>
                            {{ entity.created_at || '' | date: 'MM/dd/yyyy HH:mm:ss'}}
                        </td>
                        <td>
                            <div class="flex justify-content-end">
                                <button pButton pRipple icon="pi pi-clone" title="{{ 'app.buttonTitle.clone' | translate }}"
                                    class="p-button-outlined p-button-rounded p-button-info mr-2"
                                    (click)="showCloneDialog(entity)"></button>
                                <button pButton pRipple icon="pi pi-pencil" title="{{ 'app.buttonTitle.edit' | translate }}"
                                    class="p-button-outlined p-button-rounded p-button-success mr-2"
                                    (click)="editEntity(entity)"></button>
                                <button pButton pRipple icon="pi pi-trash" title="{{ 'app.buttonTitle.delete' | translate }}"
                                    class="p-button-outlined p-button-rounded p-button-warning"
                                    (click)="deleteEntity(entity)"></button>
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>

<p-confirmDialog></p-confirmDialog>

<!-- DEVICE DIALOG -->
<p-dialog *ngIf="selectedEntity"
    [header]="translateService.instant('device-list.details.header').replace('{guide.name}', selectedEntity.name ?? '')"
    [(visible)]="entityDialogVisible" [breakpoints]="{ '960px': '75vw' }" class="details-dialog" [draggable]="false"
    [resizable]="false">
    <p-tabView>
        <p-tabPanel [header]="translateService.instant('device-list.details.tab_details')">
            <form [formGroup]="entityForm">
                <div class="grid p-fluid">
                    <div class="col-12 md:col-6">
                        <div class="field">
                            <label [ngClass]="{'text-red-500': isInvalid(entityForm, 'name')}" for="name">{{
                                'device-list.table.name' | translate }} *</label>
                            <input type="text" pInputText id="name"
                                [ngClass]="{'ng-invalid ng-dirty' : isInvalid(entityForm, 'name')}"
                                formControlName="name" />
                            <small class="ng-dirty ng-invalid" *ngIf="isInvalid(entityForm, 'name')">{{
                                translateService.instant('app.isRequired').replace('{field}',
                                translateService.instant('device-list.table.name')) }}</small>
                        </div>
                    </div>
                    <div class="col-12 md:col-6">
                        <div class="field">
                            <label [ngClass]="{'text-red-500': isInvalid(entityForm, 'distance')}" for="distance">{{
                                'device-list.table.distance' | translate }} *</label>
                            <input type="text" pInputText id="distance"
                                [ngClass]="{'ng-invalid ng-dirty' : isInvalid(entityForm, 'distance')}"
                                formControlName="distance" />
                            <small class="ng-dirty ng-invalid" *ngIf="isInvalid(entityForm, 'distance')">{{
                                translateService.instant('app.isRequired').replace('{field}',
                                translateService.instant('device-list.table.distance')) }}</small>
                        </div>
                    </div>
                </div>
            </form>
            <ng-template pTemplate="footer">
                <div class="flex justify-content-end align-items-center">
                    <div>
                        <button pButton pRipple [label]="translateService.instant('app.cancel')" icon="pi pi-times"
                            class="p-button-text" (click)="entityDialogVisible = false"></button>
                        <button pButton pRipple [label]="translateService.instant('app.save')" icon="pi pi-check"
                            class="p-button-text" (click)="saveEntity()"></button>
                    </div>
                </div>
            </ng-template>
        </p-tabPanel>
    </p-tabView>
</p-dialog>

<!-- CLONE DIALOG -->
<p-dialog *ngIf="selectedEntity" [header]="this.translateService.instant('app.cloneDialog.header')"
    [(visible)]="cloneEntityDialogVisible" [modal]="true" [draggable]="false" [resizable]="false" 
    [breakpoints]="{ '960px': '75vw' }">
    <div class="flex flex-column ">
        <label for="name">{{ this.translateService.instant('app.cloneDialog.label').replace('{field}', translateService.instant('device-list.table.name')) }}</label>
        <input type="text" pInputText id="version" [(ngModel)]="newName" />
    </div>
    <ng-template pTemplate="footer">
        <div class="flex justify-content-end align-items-center">
            <div>
                <button pButton pRipple [label]="translateService.instant('app.save')" icon="pi pi-check"
                    [disabled]="newName === ''" class="p-button-text" (click)="cloneEntity()"></button>
            </div>
        </div>
    </ng-template>
</p-dialog>