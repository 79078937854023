import { NgModule } from '@angular/core';
import { CommonModule, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AppLayoutModule } from './layout/app.layout.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { TableModule } from 'primeng/table';
import { ChipModule } from 'primeng/chip';
import { AvatarModule } from 'primeng/avatar';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { EditorModule } from 'primeng/editor';
import { AccordionModule } from 'primeng/accordion';
import { TimesCircleIcon } from 'primeng/icons/timescircle';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { DropdownModule } from 'primeng/dropdown';
import { CheckboxModule } from 'primeng/checkbox';
import { PasswordModule } from 'primeng/password';
import { TabViewModule } from 'primeng/tabview';
import { CalendarModule } from 'primeng/calendar';
import { InputNumberModule } from 'primeng/inputnumber';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { ToolbarModule } from 'primeng/toolbar';
import { UsersListComponent } from './components/users-list/users-list.component';
import { LoginComponent } from './components/login/login.component';
import { SessionsListComponent } from './components/sessions-list/sessions-list.component';
import { TagModule } from 'primeng/tag';
import { SkeletonModule } from 'primeng/skeleton';
import { CardModule } from 'primeng/card';
import { MeasurementGuideListComponent } from './components/measurement-guide-list/measurement-guide-list.component';
import { DeviceListComponent } from './components/device-list/device-list.component';
import { UsersService } from './components/users-list/users.service';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { RecommendationGuideListComponent } from './components/recommentation-guide-list/recommendation-guide-list.component';
import { ReportTemplateListComponent } from './components/report-template-list/report-template-list.component';
import { FileUploadModule } from 'primeng/fileupload';
import { GalleriaModule } from 'primeng/galleria';
import { FieldsetModule } from 'primeng/fieldset';
import { BadgeModule } from 'primeng/badge';
import { SidebarModule } from 'primeng/sidebar';
import { StyleClassModule } from 'primeng/styleclass';
import { ImageAnnotationComponent } from './shared/components/image-annotation/image-annotation.component';
import { ChipsModule } from 'primeng/chips';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { InputMaskModule } from 'primeng/inputmask';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [
        AppComponent,
        UsersListComponent,
        LoginComponent,
        SessionsListComponent,
        MeasurementGuideListComponent,
        DeviceListComponent,
        RecommendationGuideListComponent,
        ReportTemplateListComponent,
        ImageAnnotationComponent,
    ],
    imports: [
        AppRoutingModule,
        AppLayoutModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [ HttpClient ]
            },
            defaultLanguage: 'en'
        }),
        MonacoEditorModule.forRoot(),
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        TableModule,
        ToolbarModule,
        ChipModule,
        AvatarModule,
        DialogModule,
        MultiSelectModule,
        InputTextModule,
        ConfirmDialogModule,
        TimesCircleIcon,
        DropdownModule,
        CheckboxModule,
        PasswordModule,
        TabViewModule,
        ReactiveFormsModule,
        DropdownModule,
        CalendarModule,
        InputNumberModule,
        MessageModule,
        MessagesModule,
        ButtonModule,
        RippleModule,
        TagModule,
        SkeletonModule,
        CardModule,
        EditorModule,
        AccordionModule,
        ToastModule,
        FileUploadModule,
        GalleriaModule,
        FieldsetModule,
        BadgeModule,
        SidebarModule,
        StyleClassModule,
        ChipsModule,
        AutoCompleteModule,
        InputMaskModule,
    ],
    providers: [
        { provide: LocationStrategy, useClass: HashLocationStrategy },
        UsersService,
        MessageService,
        ToastModule,
    ],
    bootstrap: [ AppComponent ]
})
export class AppModule { }
