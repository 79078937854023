import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Injectable, effect, inject } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Observable, catchError, lastValueFrom, of, throwError } from "rxjs";
import { AuthState } from "src/app/shared/auth/auth.state";
import { NotificationsService } from "src/app/shared/services/notifications.service";
import { RecommendationGuidesState } from "./recommendation-guides.state";
import { RecommendationGuide } from "./recomendation-guide.model";
import { environment } from "src/environments/environment";
import { PrimeFilteringService } from "src/app/shared/services/prime-filtering.service";


type RecommendationGuidePaginator = {
    loading: boolean,
    current_page: number,
    data: RecommendationGuide[],
    last_page: number,
    per_page: number,
    total: number
}


@Injectable({ providedIn: 'root' })
export class RecommendationGuidesService {

    private httpClient = inject(HttpClient);
    private notificationsService = inject(NotificationsService);
    private translateService = inject(TranslateService);
    private primeFilteringService = inject(PrimeFilteringService);

    private authState = inject(AuthState);
    private state = inject(RecommendationGuidesState);

    private readonly modelAPI = environment.baseApiUrl + 'recomendation-guides';
    private readonly defaultOptions = {
        headers: new HttpHeaders({})
    };

    constructor() {
        effect(() => {
            this.defaultOptions.headers = this.defaultOptions.headers.set(
                'Authorization',
                `Bearer ${ this.authState.token() }`
            );
        })

        if (this.authState.isAuthenticated()) {
            this.defaultOptions.headers = this.defaultOptions.headers.set(
                'Authorization',
                `Bearer ${ this.authState.token() }`
            );
        }
    }

    async getAll(payload) {
        try {
            let url = new URL(this.modelAPI);

            if (payload.pageSize) {
                url.searchParams.set('per_page', payload.pageSize);
                url.searchParams.set('page', payload.pageNumber);
            }

            if (payload.filter) {
                let filters = this.primeFilteringService.build(payload.filter);
                Object.entries(filters).forEach(([ key, value ]) => url.searchParams.set(key, value as string));
            }

            if (payload.order) {
                url.searchParams.set('sort', payload.order.direction + payload.order.field);
            }

            let list = await lastValueFrom(
                this.httpClient.get<RecommendationGuidePaginator>(url.toString(), this.defaultOptions)
            );

            this.state.list.setData(list);
        } catch (error) {
            this.notificationsService.error(this.translateService.instant('recommendation-guide.messages.show_error'));
        }
    }


    async post(guide: RecommendationGuide) {
        try {
            await lastValueFrom(
                this.httpClient.post<RecommendationGuidePaginator>(this.modelAPI, guide, this.defaultOptions)
            );
            this.notificationsService.success(this.translateService.instant('recommendation-guide.messages.recommendation_guide_created'));
        } catch (error) {
            this.notificationsService.error(this.translateService.instant('recommendation-guide.messages.create_error'));
        }
    }

    async update(guide: RecommendationGuide) {
        try {
            let updatedGuide = await lastValueFrom(
                this.httpClient.put<RecommendationGuide>(`${ this.modelAPI }/${ guide.id }`, guide, this.defaultOptions)
            );
            this.notificationsService.success(this.translateService.instant('recommendation-guide.messages.recommendation_guide_updated'));
            this.state.list.update(updatedGuide);
        } catch (error) {
            this.notificationsService.error(this.translateService.instant('recommendation-guide.messages.update_error'));
        }
    }

    async delete(guide: RecommendationGuide) {
        try {
            await lastValueFrom(
                this.httpClient.delete<RecommendationGuide>(`${ this.modelAPI }/${ guide.id }`, this.defaultOptions)
            );
            this.notificationsService.success(this.translateService.instant('recommendation-guide.messages.recommendation_guide_deleted'));
            this.state.list.remove(guide);
        } catch (error) {
            this.notificationsService.error(this.translateService.instant('recommendation-guide.messages.delete_error'));
        }
    }

    async clone(guide: RecommendationGuide, newVersion: string) {
        try {
            await lastValueFrom(
                this.httpClient.post<RecommendationGuidePaginator>(`${ this.modelAPI }/${ guide.id }/clone`, { version: newVersion }, this.defaultOptions)
            );
            this.notificationsService.success(this.translateService.instant('recommendation-guide.messages.recommendation_guide_cloned'));
        } catch (error) {
            this.notificationsService.error(this.translateService.instant('recommendation-guide.messages.clone_error'));
        }
    }

    async publish(guide: RecommendationGuide) {
        try {
            let updatedGuide = await lastValueFrom(
                this.httpClient.patch<RecommendationGuide>(
                    `${ this.modelAPI }/${ guide.id }`,
                    { command: 'publish' },
                    this.defaultOptions
                )
            );
            this.notificationsService.success(this.translateService.instant('recommendation-guide.messages.recommendation_guide_published'));
            this.state.list.update(updatedGuide);
            this.state.edit.setEntity(updatedGuide);
        } catch (error) {
            this.notificationsService.error(this.translateService.instant('recommendation-guide.messages.publishing_error'));
        }
    }

    async unpublish(guide: RecommendationGuide) {
        try {
            let updatedGuide = await lastValueFrom(
                this.httpClient.patch<RecommendationGuide>(
                    `${ this.modelAPI }/${ guide.id }`,
                    { command: 'unpublish' },
                    this.defaultOptions
                )
            );
            this.notificationsService.success(this.translateService.instant('recommendation-guide.messages.recommendation_guide_unpublished'));
            this.state.list.update(updatedGuide);
            this.state.edit.setEntity(updatedGuide);
        } catch (error) {
            this.notificationsService.error(this.translateService.instant('recommendation-guide.messages.unpublishing_error'));
        }
    }

    async archive(guide: RecommendationGuide) {
        try {
            let updatedGuide = await lastValueFrom(
                this.httpClient.patch<RecommendationGuide>(
                    `${ this.modelAPI }/${ guide.id }`,
                    { command: 'archive' },
                    this.defaultOptions
                )
            );
            this.notificationsService.success(this.translateService.instant('recommendation-guide.messages.recommendation_guide_archived'));
            this.state.list.update(updatedGuide);
            this.state.edit.setEntity(updatedGuide);
        } catch (error) {
            this.notificationsService.error(this.translateService.instant('recommendation-guide.messages.archiving_error'));
        }
    }

    async unarchive(guide: RecommendationGuide) {
        try {
            let updatedGuide = await lastValueFrom(
                this.httpClient.patch<RecommendationGuide>(
                    `${ this.modelAPI }/${ guide.id }`,
                    { command: 'unarchive' },
                    this.defaultOptions
                )
            );
            this.notificationsService.success(this.translateService.instant('recommendation-guide.messages.recommendation_guide_unarchived'));
            this.state.list.update(updatedGuide);
            this.state.edit.setEntity(updatedGuide);
        } catch (error) {
            this.notificationsService.error(this.translateService.instant('recommendation-guide.messages.unarchiving_error'));
        }
    }

}