import { AfterViewChecked, AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Annotorious } from '@recogito/annotorious';

const RemoveButtonFormatter = (annotorious, removeCallback) => annotation => {

  const bodies = Array.isArray(annotation.body) ?
    annotation.body : [ annotation.body ];

  const firstLabel = bodies.find(b => b.purpose == 'label');


  if (firstLabel) {
    const foreignObject = document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject');

    foreignObject.setAttribute('width', '1px');
    foreignObject.setAttribute('height', '1px');



    const wrapper = document.createElement('div');
    wrapper.classList.add('a9s-shape-label-wrapper');

    const label = document.createElement('div');
    label.classList.add('a9s-shape-label');
    label.innerHTML = firstLabel.value
    wrapper.appendChild(label);

    if (!annotorious.readOnly) {
      const removeButton = document.createElement('button');
      removeButton.addEventListener('mouseup', (event) => removeCallback(event, annotation));
      removeButton.innerHTML = '×';
      wrapper.appendChild(removeButton);
    }

    foreignObject.appendChild(wrapper);

    return {
      element: foreignObject,
      className: firstLabel.value
    };
  }

  return undefined;
};

const AnnotoriousRemoveButtonPlugin = (annotorious, removeCallback) => {
  annotorious.formatters = [ ...annotorious.formatters, RemoveButtonFormatter(annotorious, removeCallback) ]
};

@Component({
  selector: 'app-image-annotation',
  templateUrl: './image-annotation.component.html',
  styleUrls: [ './image-annotation.component.scss' ]
})
export class ImageAnnotationComponent implements AfterViewInit, AfterViewChecked {

  translateService = inject(TranslateService);

  loaded: boolean = false;
  annotorious: Annotorious;

  @ViewChild("image", { static: false })
  image: ElementRef;

  @Input()
  showMessage: boolean = true;

  @Input()
  src: string;

  @Input()
  type: string;

  @Input()
  readOnly: boolean = false;

  @Input()
  annotations: object[] = [];

  @Output()
  annotationsChange = new EventEmitter<object[]>();


  ngAfterViewChecked(): void {
    if (!this.loaded && document.querySelector('app-image-annotation') != null) {
      setTimeout(() => {
        this.loaded = true;
        this.refresh();
      }, 500);
    } else {
      this.loaded = false;
    }
  }

  ngAfterViewInit(): void {
    if (this.image) {
      this.annotorious = new Annotorious({
        image: this.image.nativeElement,
        disableEditor: true,
        disableSelect: true,
        readOnly: this.readOnly,
      });

      AnnotoriousRemoveButtonPlugin(this.annotorious, this.onRemoveAnnotation.bind(this));

      this.annotorious.on('createAnnotation', this.onCreateAnnotation.bind(this));
      this.annotorious.on('createSelection', this.onCreateSelection.bind(this));
    }
  }

  refresh() {
    this.annotorious.clearAnnotations();
    this.annotations.forEach(annotation => { this.annotorious.addAnnotation(annotation[ 'data' ]) });
  }

  async onCreateSelection(selection) {

    const [ x, y, w, h ] = selection.target.selector.value
      .split(':')[ 1 ]
      .split(',')
      .map(str => parseFloat(str));

    // Prevent annotation creation on missclick event
    if (w != 1 && h != 1) {
      selection.body = [
        {
          type: 'TextualBody',
          purpose: 'label',
          value: this.type
        },
      ];

      await this.annotorious.updateSelected(selection);
      this.annotorious.saveSelected();
    }
  }

  onCreateAnnotation(data) {
    data[ '@id' ] = data.id;
    delete data.id;
    this.annotations.push({ data: data });
    this.annotationsChange.emit(this.annotations);
  }

  onRemoveAnnotation(event: Event, annotation) {
    this.annotorious.removeAnnotation(annotation.underlying[ '@id' ]);
    this.annotations = this.annotations.filter(_annotation => _annotation[ 'data' ]?.[ '@id' ] != annotation.underlying[ '@id' ]);
    this.refresh();
    this.annotationsChange.emit(this.annotations);
  }
}
