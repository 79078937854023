<div class="grid">
    <div class="col-12">
        <div class="card">
            <h5>{{ 'recommendation-guide.table.title' | translate }}</h5>
            <p-table #dataTable [value]="state.list.data()" [scrollable]="true" scrollHeight="calc(100vh - 380px)"
                [rowHover]="true" styleClass="p-datatable-sm" [(selection)]="selectedEntities" responsiveLayout="scroll"
                lazy="true" (onLazyLoad)="onLazyLoad($event)" sortField="created_at" [loading]="state.list.loading()"
                paginator="true" [rows]="state.list.per_page()" [totalRecords]="state.list.total()"
                [rowsPerPageOptions]="[10, 20, 30, 50, 100]" [showCurrentPageReport]="true"
                [currentPageReportTemplate]="translateService.instant('table.paginator.template')">
                <ng-template pTemplate="caption">
                    <div class="flex justify-content-between align-items-center">
                        <div>
                            <button pButton pRipple icon="pi pi-plus"
                                class="p-button-outlined p-button-rounded p-button-success mr-2"
                                (click)="showCreateDialog()">
                                <span class="hidden md:block ml-2"><b>{{ translateService.instant('app.new')
                                        }}</b></span>
                            </button>
                            <button pButton pRipple icon="pi pi-trash"
                                class="p-button-outlined p-button-rounded p-button-danger mr-2"
                                (click)="deleteSelectedEntities()"
                                [disabled]="!selectedEntities || !selectedEntities.length">
                                <span class="hidden md:block ml-2"><b>{{ translateService.instant('app.delete')
                                        }}</b></span>
                            </button>
                            <button pButton pRipple icon="pi pi-file-excel"
                                class="p-button-outlined p-button-rounded p-button-success mr-2"
                                (click)="exportExcel(dataTable.filters, dataTable.sortField, dataTable.sortOrder)">
                                <span class="hidden md:block ml-2"><b>{{ translateService.instant('app.export')
                                        }}</b></span>
                            </button>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th style="width: 3rem">
                            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                        </th>
                        <th>
                            <div class="flex justify-content-between align-items-center">
                                <div>
                                    {{ 'recommendation-guide.table.part_numbers' | translate }}
                                </div>
                                <p-columnFilter type="text" field="part_numbers" display="menu"
                                    placeholder="{{ 'table.filter.textPlaceholder' | translate }}"
                                    [matchModeOptions]="partNumbersMatchModeOptions"></p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="version">
                            <div class="flex justify-content-between align-items-center">
                                <div>
                                    {{ 'recommendation-guide.table.version' | translate }}
                                    <p-sortIcon field="version"></p-sortIcon>
                                </div>
                                <p-columnFilter type="text" field="version" display="menu"
                                    placeholder="{{ 'table.filter.textPlaceholder' | translate }}"></p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="state">
                            <div class="flex justify-content-between align-items-center">
                                <div>
                                    {{ 'recommendation-guide.table.state' | translate }}
                                    <p-sortIcon field="state"></p-sortIcon>
                                </div>
                                <p-columnFilter field="state" matchMode="equals" display="menu"
                                    [showMatchModes]="false">
                                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                        <p-dropdown [ngModel]="value" [options]="statuses"
                                            (onChange)="filter($event.value)" placeholder="Any">
                                            <ng-template let-option pTemplate="item">
                                                <p-tag [value]="option.label"
                                                    [severity]="getSeverity(option.label)"></p-tag>
                                            </ng-template>
                                        </p-dropdown>
                                    </ng-template>
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="created_at">
                            <div class="flex justify-content-between align-items-center">
                                <div>
                                    {{ 'recommendation-guide.table.created_at' | translate }}
                                    <p-sortIcon field="created_at"></p-sortIcon>
                                </div>
                                <p-columnFilter type="date" field="created_at" display="menu"
                                    placeholder="{{ 'table.filter.textPlaceholder' | translate }}"></p-columnFilter>
                            </div>
                        </th>
                        <th></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-entity>
                    <tr>
                        <td>
                            <p-tableCheckbox [value]="entity"></p-tableCheckbox>
                        </td>
                        <td>
                            <div class="flex align-items-center gap-2 flex-wrap">
                                <p-chip *ngFor="let pn of entity.part_numbers" [label]="pn"></p-chip>
                            </div>
                        </td>
                        <td>
                            {{entity.version}}
                        </td>
                        <td>
                            <p-tag [value]="'app.state.' + entity.state | translate"
                                [severity]="getSeverity(entity.state)"></p-tag>
                        </td>
                        <td>
                            {{ entity.created_at || '' | date: 'MM/dd/yyyy HH:mm:ss'}}
                        </td>
                        <td>
                            <div class="flex justify-content-end">
                                <button pButton pRipple icon="pi pi-clone" title="{{ 'app.buttonTitle.clone' | translate }}"
                                    class="p-button-outlined p-button-rounded p-button-info mr-2"
                                    (click)="showCloneDialog(entity)"></button>
                                <button pButton pRipple icon="pi pi-pencil" title="{{ 'app.buttonTitle.edit' | translate }}"
                                    class="p-button-outlined p-button-rounded p-button-success mr-2"
                                    (click)="showEditDialog(entity)"></button>
                                <button pButton pRipple icon="pi pi-trash" title="{{ 'app.buttonTitle.delete' | translate }}"
                                    class="p-button-outlined p-button-rounded p-button-warning"
                                    (click)="deleteEntity(entity)"></button>
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>

<p-confirmDialog></p-confirmDialog>

<!-- EDIT DIALOG -->
<p-dialog *ngIf="state.edit.entity()" [header]="translateService.instant('recommendation-guide.details.header')"
    [(visible)]="editDialogVisible" [breakpoints]="{ '960px': '75vw' }" class="details-dialog" [draggable]="false"
    [resizable]="false">
    <p-tabView>
        <p-tabPanel [header]="translateService.instant('recommendation-guide.details.tab_details')">
            <div class="mt-5 mb-5">
                <p-toolbar *ngIf="state.edit.loading()">
                    <div class="p-toolbar-group-start">
                        <div class="flex flex-column">
                            <div class="flex gap-1">
                                <p-skeleton width="3rem" height="1.5rem"></p-skeleton>
                                <p-skeleton width="8rem" height="1.5rem"></p-skeleton>
                            </div>
                            <div class="flex mt-2">
                                <p-skeleton size="2rem" styleClass="mr-2"></p-skeleton>
                                <p-skeleton width="10rem" class="flex align-items-center"></p-skeleton>
                            </div>
                        </div>
                    </div>
                    <div class="p-toolbar-group-end">
                        <div class="flex gap-2">
                            <p-skeleton width="7rem" height="2.5rem"></p-skeleton>
                        </div>
                    </div>
                </p-toolbar>
                <p-toolbar *ngIf="!state.edit.loading()">
                    <div class="p-toolbar-group-start">
                        <div class="flex flex-column">
                            <div class="flex gap-1">
                                <p-tag [value]="'app.state.' + state.edit.entity().state | translate"
                                    [severity]="getSeverity(state.edit.entity().state)">
                                </p-tag>
                                <p-tag value="{{state.edit.entity().updated_at | date: 'MM/dd/yyyy HH:mm:ss'}}"
                                    [severity]="getSeverity(state.edit.entity().state)">
                                </p-tag>
                            </div>
                            <div class="flex align-items-center mt-2">
                                <p-avatar [label]="state.edit.entity().user.name[0].toUpperCase()" styleClass="mr-2"
                                    shape="circle"></p-avatar>
                                <span>{{state.edit.entity().user.name}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="p-toolbar-group-end">
                        <div class="flex gap-2">
                            <p-button label="Publish" icon="pi pi-thumbs-up" severity="success"
                                *ngIf="state.edit.entity().state == 'draft'"
                                (click)="publish(state.edit.entity())"></p-button>
                            <p-button label="Unpublish" icon="pi pi-undo" severity="secondary"
                                *ngIf="state.edit.entity().state == 'published'"
                                (click)="unpublish(state.edit.entity())"></p-button>
                            <p-button label="Archive" icon="pi pi-inbox" severity="warning"
                                *ngIf="state.edit.entity().state == 'published'"
                                (click)="archive(state.edit.entity())"></p-button>
                            <p-button label="Unarchive" icon="pi pi-undo" severity="secondary"
                                *ngIf="state.edit.entity().state == 'archived'"
                                (click)="unarchive(state.edit.entity())"></p-button>
                        </div>
                    </div>
                </p-toolbar>
            </div>

            <form [formGroup]="editForm">
                <div class="grid p-fluid">
                    <div class="col-12 md:col-6">
                        <div class="field flex flex-column gap-2">
                            <label class="mb-0" [ngClass]="{'text-red-500': isInvalid(editForm, 'part_numbers') || incorrectPatternValue}"
                                for="part_numbers">{{
                                'recommendation-guide.table.part_numbers' | translate }} *</label>
                            <div class="flex flex-row flex-wrap gap-2" *ngIf="editForm.get('part_numbers').value?.length>0">
                                <p-chip *ngFor="let part_number of editForm.get('part_numbers').value; index as i"
                                    (onRemove)="onRemovePartNumber(editForm, i)" [label]="part_number"
                                    [removable]="state.edit.entity().state == 'draft'"></p-chip>
                            </div>
                            <div *ngIf="state.edit.entity().state == 'draft'"
                                class="flex flex-row justify-content-center align-items-center gap-2">
                                <input #editionPartNumberInput type="text" pInputText id="part_numbers"
                                    [ngClass]="{'ng-invalid ng-dirty' : isInvalid(editForm, 'part_numbers') || incorrectPatternValue}"
                                    placeholder="CLIENT#REFUID#XXXXXX"
                                    (keyup.enter)="onAddPartNumber(editForm, editionPartNumberInput)"/>
                                <p-button icon="pi pi-plus" severity="success"
                                    (click)="onAddPartNumber(editForm, editionPartNumberInput)"></p-button>
                            </div>
                            <small class="ng-dirty ng-invalid" *ngIf="isInvalid(editForm, 'part_numbers') && editForm.get('part_numbers').hasError('required')">{{
                                translateService.instant('app.isRequired').replace('{field}',
                                translateService.instant('recommendation-guide.table.part_numbers')) }}</small>
                            <small class="ng-dirty ng-invalid" *ngIf="isInvalid(createForm, 'part_numbers') || incorrectPatternValue">
                                {{ translateService.instant('app.incorrectPattern').replace('{field}',
                                translateService.instant('recommendation-guide.table.part_numbers')).replace('{pattern}', "CLIENT#REFUID#XXXXXX") }}</small>
                        </div>
                    </div>
                    <div class="col-12 md:col-6">
                        <div class="field">
                            <label [ngClass]="{'text-red-500': isInvalid(editForm, 'version')}" for="version">{{
                                'recommendation-guide.table.version' | translate }}</label>
                            <input type="text" pInputText id="version"
                                [ngClass]="{'ng-invalid ng-dirty' : isInvalid(editForm, 'version')}"
                                formControlName="version" readonly />
                            <small class="ng-dirty ng-invalid" *ngIf="isInvalid(editForm, 'version')">{{
                                translateService.instant('app.isRequired').replace('{field}',
                                translateService.instant('recommendation-guide.table.version')) }}</small>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="field">
                            <label>{{'recommendation-guide.table.content' | translate }} *</label>
                            <p-editor id="content" formControlName="content" class="w-full" [readonly]="editorReadonly"
                                [ngClass]="{'ng-invalid ng-dirty' : isInvalid(editForm, 'content')}">
                            </p-editor>
                        </div>
                    </div>
                </div>
            </form>
            <ng-template pTemplate="footer">
                <div class="flex justify-content-end align-items-center" *ngIf="state.edit.entity().state == 'draft'">
                    <div class="flex gap-2">
                        <button pButton pRipple [label]="translateService.instant('app.cancel')" icon="pi pi-times"
                            class="p-button-text" (click)="this.editDialogVisible = false"></button>
                        <button pButton pRipple [label]="translateService.instant('app.save')"
                            [loading]="state.edit.loading()" icon="pi pi-check" class="p-button-text"
                            (click)="editEntity()"></button>
                    </div>
                </div>
            </ng-template>
        </p-tabPanel>
    </p-tabView>
</p-dialog>

<!-- CREATE DIALOG -->
<p-dialog *ngIf="state.create.entity()"
    [header]="translateService.instant('recommendation-guide.details.header').replace('{guide.code}', state.create.entity().part_numbers ?? '')"
    [(visible)]="createDialogVisible" [breakpoints]="{ '960px': '75vw' }" class="details-dialog" [draggable]="false"
    [resizable]="false">
    <p-tabView>
        <p-tabPanel [header]="translateService.instant('recommendation-guide.details.tab_details')">
            <form [formGroup]="createForm">
                <div class="grid p-fluid">
                    <div class="col-12 md:col-6">
                        <div class="field flex flex-column gap-2">
                            <label class="mb-0" [ngClass]="{'text-red-500': isInvalid(createForm, 'part_numbers') || incorrectPatternValue}"
                                for="part_numbers">{{
                                'recommendation-guide.table.part_numbers' | translate }} *</label>
                            <div class="flex flex-row flex-wrap gap-2" *ngIf="createForm.get('part_numbers').value?.length>0">
                                <p-chip *ngFor="let part_number of createForm.get('part_numbers').value; index as i"
                                    (onRemove)="onRemovePartNumber(createForm, i)" [label]="part_number"
                                    [removable]="true"></p-chip>
                            </div>
                            <div class="flex flex-row justify-content-center align-items-center gap-2">
                                <input #creationPartNumberInput type="text" pInputText id="part_numbers"
                                    [ngClass]="{'ng-invalid ng-dirty' : isInvalid(createForm, 'part_numbers') || incorrectPatternValue}"
                                    placeholder="CLIENT#REFUID#XXXXXX"
                                    (keyup.enter)="onAddPartNumber(createForm, creationPartNumberInput)"/>
                                <p-button icon="pi pi-plus" severity="success"
                                    (click)="onAddPartNumber(createForm, creationPartNumberInput)"></p-button>
                            </div>
                            <small class="ng-dirty ng-invalid" *ngIf="isInvalid(createForm, 'part_numbers') && createForm.get('part_numbers').hasError('required')">{{
                                translateService.instant('app.isRequired').replace('{field}',
                                translateService.instant('recommendation-guide.table.part_numbers')) }}</small>
                            <small class="ng-dirty ng-invalid" *ngIf="incorrectPatternValue">
                                {{ translateService.instant('app.incorrectPattern').replace('{field}',
                                translateService.instant('recommendation-guide.table.part_numbers')).replace('{pattern}', "CLIENT#REFUID#XXXXXX") }}</small>
                        </div>
                    </div>
                    <div class="col-12 md:col-6">
                        <div class="field">
                            <label [ngClass]="{'text-red-500': isInvalid(createForm, 'version')}" for="version">{{
                                'recommendation-guide.table.version' | translate }} *</label>
                            <input type="text" pInputText id="version"
                                [ngClass]="{'ng-invalid ng-dirty' : isInvalid(createForm, 'version')}"
                                formControlName="version" />
                            <small class="ng-dirty ng-invalid" *ngIf="isInvalid(createForm, 'version')">{{
                                translateService.instant('app.isRequired').replace('{field}',
                                translateService.instant('recommendation-guide.table.version')) }}</small>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="field">
                            <label [ngClass]="{'text-red-500': isInvalid(createForm, 'content')}"
                                for="content">{{'recommendation-guide.table.content' | translate }} *</label>
                            <p-editor id="content" formControlName="content" class="w-full"
                                [ngClass]="{'ng-invalid ng-dirty' : isInvalid(createForm, 'content')}">
                            </p-editor>
                            <small class="ng-dirty ng-invalid" *ngIf="isInvalid(createForm, 'content')">{{
                                translateService.instant('app.isRequired').replace('{field}',
                                translateService.instant('recommendation-guide.table.content')) }}</small>
                        </div>
                    </div>
                </div>
            </form>
            <ng-template pTemplate="footer">
                <div class="flex justify-content-end align-items-center">
                    <div class="flex gap-2">
                        <button pButton pRipple [label]="translateService.instant('app.cancel')" icon="pi pi-times"
                            class="p-button-text" (click)="this.createDialogVisible = false;"></button>
                        <button pButton pRipple [label]="translateService.instant('app.save')"
                            [loading]="state.create.loading()" icon="pi pi-check" class="p-button-text"
                            (click)="createEntity()"></button>
                    </div>
                </div>
            </ng-template>
        </p-tabPanel>
    </p-tabView>
</p-dialog>

<!-- CLONE DIALOG -->
<p-dialog *ngIf="state.create.entity()" [header]="this.translateService.instant('app.cloneDialog.header')"
    [(visible)]="cloneEntityDialogVisible" [modal]="true" [draggable]="false" [resizable]="false"
    [breakpoints]="{ '960px': '75vw' }">
    <div class="flex flex-column ">
        <label for="version">{{ this.translateService.instant('app.cloneDialog.label').replace('{field}',
            translateService.instant('recommendation-guide.table.version')) }}</label>
        <input type="text" pInputText id="version" [(ngModel)]="newVersion" />
    </div>
    <ng-template pTemplate="footer">
        <div class="flex justify-content-end align-items-center">
            <div>
                <button pButton pRipple [label]="translateService.instant('app.save')" icon="pi pi-check"
                    [disabled]="newVersion === ''" class="p-button-text" (click)="cloneEntity()"></button>
            </div>
        </div>
    </ng-template>
</p-dialog>