import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class PrimeFilteringService {
    public build(filters: Object) {
        return this.buildFilters(filters);
    }

    private buildFilters(filters: object) {
        let params = {};
        for (let field in filters) {
            for (let filter in filters[ field ]) {
                let { value, matchMode } = filters[ field ][ filter ];
                if (value === null) {
                    continue;
                }

                if (value instanceof Date) {
                    value = value.toISOString();
                }

                let _value = params[ `filter[${ field }__${ matchMode }]` ];
                params[ `filter[${ field }__${ matchMode }]` ] = _value ? `${ _value },${ value }` : value;
            }
        }

        return params;
    }
}