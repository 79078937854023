import { Component } from '@angular/core';
import { LayoutService } from "./service/app.layout.service";
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-footer',
    templateUrl: './app.footer.component.html'
})
export class AppFooterComponent {
    constructor(
        public layoutService: LayoutService,
        public translateService: TranslateService) { }
}
