import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, effect, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthState } from 'src/app/shared/auth/auth.state';
import { NotificationsService } from 'src/app/shared/services/notifications.service';
import { PrimeFilteringService } from 'src/app/shared/services/prime-filtering.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class ReportTemplateService {
    private readonly modelAPI = environment.baseApiUrl + 'report-templates';

    private authState = inject(AuthState);
    private notificationsService = inject(NotificationsService);
    private translateService = inject(TranslateService);
    private primeFilteringService = inject(PrimeFilteringService);


    private readonly defaultOptions = {
        headers: new HttpHeaders({})
    };

    constructor(private httpClient: HttpClient) {
        effect(() => {
            this.defaultOptions.headers = this.defaultOptions.headers.set(
                'Authorization',
                `Bearer ${ this.authState.token() }`
            );
        })

        if (this.authState.isAuthenticated()) {
            this.defaultOptions.headers = this.defaultOptions.headers.set(
                'Authorization',
                `Bearer ${ this.authState.token() }`
            );
        }
    }

    getAll(payload):  Observable<object[]> {
        let url = new URL(this.modelAPI);

        if (payload.pageSize) {
            url.searchParams.set('per_page', payload.pageSize);
            url.searchParams.set('page', payload.pageNumber);
        }

        if (payload.filter) {
            let filters = this.primeFilteringService.build(payload.filter);
            Object.entries(filters).forEach(([ key, value ]) => url.searchParams.set(key, value as string));
        }

        if (payload.order) {
            url.searchParams.set('sort', payload.order.direction + payload.order.field);
        }

        return this.httpClient.get<object[]>(url.toString(),this.defaultOptions)
        .pipe(
            catchError(() => {
            this.notificationsService.error(this.translateService.instant('report-template.messages.show_error'));
            return of();
            })
        );
    }

    create(newTemplateData: object):  Observable<object> {
        return this.httpClient.post<object>(`${this.modelAPI}`, newTemplateData, this.defaultOptions)
        .pipe(
            catchError(() => {
            this.notificationsService.error(this.translateService.instant('report-template.messages.create_error'));
            return of();
            })
        );
    }

    delete(templateId: number): Observable<any> {
        return this.httpClient.delete<object>(`${this.modelAPI}/${templateId}`,this.defaultOptions)
        .pipe(
            catchError(() => {
            this.notificationsService.error(this.translateService.instant('report-template.messages.delete_error'));
            return of();
            })
        );
    }

    update(templateId: number, updatedTemplate: object): Observable<object> {
        return this.httpClient.put<object>(`${this.modelAPI}/${templateId}`, updatedTemplate, this.defaultOptions).pipe(
            catchError(() => {
              this.notificationsService.error(this.translateService.instant('report-template.messages.update_error'));
              return of();
            })
        );
    }

    clone(templateId: number, newVersion: string): Observable<object> {
        return this.httpClient.post<object>(`${this.modelAPI}/${templateId}/clone`, {version: newVersion}, this.defaultOptions).pipe(
            catchError(() => {
              this.notificationsService.error(this.translateService.instant('report-template.messages.clone_error'));
              return of();
            })
        );
    }

    publish(template): Observable<object> {
      return this.httpClient.patch<object>(`${this.modelAPI}/${template.id}`, {command: 'publish'}, this.defaultOptions).pipe(
        catchError(() => {
          this.notificationsService.error(this.translateService.instant('report-template.messages.change_state_error'));
          return of();
        })
      );
    }
  
    unpublish(template): Observable<object> {
      return this.httpClient.patch<object>(`${this.modelAPI}/${template.id}`, {command: 'unpublish'}, this.defaultOptions).pipe(
        catchError(() => {
          this.notificationsService.error(this.translateService.instant('report-template.messages.change_state_error'));
          return of();
        })
      );
    }
  
    archive(template): Observable<object> {
      return this.httpClient.patch<object>(`${this.modelAPI}/${template.id}`, {command: 'archive'}, this.defaultOptions).pipe(
        catchError(() => {
          this.notificationsService.error(this.translateService.instant('report-template.messages.change_state_error'));
          return of();
        })
      );
    }
  
    unarchive(template): Observable<object> {
      return this.httpClient.patch<object>(`${this.modelAPI}/${template.id}`, {command: 'unarchive'}, this.defaultOptions).pipe(
        catchError(() => {
          this.notificationsService.error(this.translateService.instant('report-template.messages.change_state_error'));
          return of();
        })
      );
    }
}