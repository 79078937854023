import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Injectable, effect, inject } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Observable, catchError, of, throwError } from "rxjs";
import { AuthState } from "src/app/shared/auth/auth.state";
import { NotificationsService } from "src/app/shared/services/notifications.service";
import { PrimeFilteringService } from "src/app/shared/services/prime-filtering.service";
import { environment } from "src/environments/environment";


@Injectable({ providedIn: 'root' })
export class UsersService {

  private authState = inject(AuthState);
  private notificationsService = inject(NotificationsService);
  private translateService = inject(TranslateService);
  private httpClient = inject(HttpClient);
  private primeFilteringService = inject(PrimeFilteringService);

  private readonly modelAPI = environment.baseApiUrl + 'users';
  protected defaultOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accepts': 'application/json'
    })
  };

  constructor() {
      effect(() => {
          this.defaultOptions.headers = this.defaultOptions.headers.set(
              'Authorization',
              `Bearer ${ this.authState.token() }`
          );
      })
  
      if (this.authState.isAuthenticated()) {
          this.defaultOptions.headers = this.defaultOptions.headers.set(
              'Authorization',
              `Bearer ${ this.authState.token() }`
          );
      }
  }

  getAll(payload): Observable<object[]> {
    let url = new URL(this.modelAPI);

    if (payload.pageSize) {
        url.searchParams.set('per_page', payload.pageSize);
        url.searchParams.set('page', payload.pageNumber);
    }

    if (payload.filter) {
        let filters = this.primeFilteringService.build(payload.filter);
        Object.entries(filters).forEach(([ key, value ]) => url.searchParams.set(key, value as string));
    }

    if (payload.order) {
        url.searchParams.set('sort', payload.order.direction + payload.order.field);
    }

    return this.httpClient.get<object[]>(url.toString(), this.defaultOptions)
      .pipe(
        catchError(() => {
          this.notificationsService.error(this.translateService.instant('admin.users.messages.show_error'));
          return of();
        })
      );
  }

  post(entity): Observable<object> {
    return this.httpClient.post<object>(`${this.modelAPI}`, entity, this.defaultOptions)
      .pipe(
        catchError(() => {
          this.notificationsService.error(this.translateService.instant('admin.users.messages.create_error'));
          return of();
        })
      );
  }

  update(entity): Observable<object> {
    return this.httpClient.put<object>(`${this.modelAPI}/${entity['id']}`, entity, this.defaultOptions).pipe(
        catchError(() => {
          this.notificationsService.error(this.translateService.instant('admin.users.messages.update_error'));
          return of();
        })
    );
  }

  delete(entity): Observable<object> {
    return this.httpClient.delete<object>(`${this.modelAPI}/${entity['id']}`,this.defaultOptions)
      .pipe(
        catchError(() => {
          this.notificationsService.error(this.translateService.instant('admin.users.messages.delete_error'));
          return of();
        })
      );
  }
}