import { AuthState } from "../auth/auth.state";
import { Injectable, effect, inject } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";


@Injectable({
    providedIn: 'root'
})
export class BaseService {
    protected endpoint: string;
    protected authState = inject(AuthState);

    protected readonly defaultOptions = {
        headers: new HttpHeaders({})
    };

    constructor(protected httpClient: HttpClient) {
        effect(() => {
            this.defaultOptions.headers = this.defaultOptions.headers.set(
                'Authorization',
                `Bearer ${ this.authState.token() }`
            );
        })

        if (this.authState.isAuthenticated()) {
            this.defaultOptions.headers = this.defaultOptions.headers.set(
                'Authorization',
                `Bearer ${ this.authState.token() }`
            );
        }
    }

    protected buildEndpoint(options: object): URL | null {
        if (!this.endpoint) {
            return null;
        }

        let endpoint = this.endpoint;
        Object.entries(options).forEach(([ key, value ]) => {
            endpoint = endpoint.replace(`{${ key }}`, value);
        })
        return new URL(endpoint);
    }
}