<div class="grid">
    <div class="col-12">
        <div class="card">
            <h5>{{ 'admin.users.table.title' | translate }}</h5>
            <p-table #dataTable [value]="entities()" [scrollable]="true" scrollHeight="calc(100vh - 380px)" [rowHover]="true"
                styleClass="p-datatable-sm" [(selection)]="selectedEntities" responsiveLayout="scroll"
                lazy="true" [lazyLoadOnInit]="lazyLoadOnInit" (onLazyLoad)="onLazyLoad($event)" 
                sortField="name" [loading]="loading"
                paginator="true" [rows]="rows" [first]="first" [totalRecords]="entitiesCount"
                [rowsPerPageOptions]="[10, 20, 30, 50, 100]" [showCurrentPageReport]="true"
                [currentPageReportTemplate]="translateService.instant('table.paginator.template')">
                <ng-template pTemplate="caption">
                    <div class="flex justify-content-between flex-column sm:flex-row">
                        <div class="flex justify-content-between align-items-center">
                            <div>
                                <button pButton pRipple icon="pi pi-plus"
                                    class="p-button-outlined p-button-rounded p-button-success mr-2" (click)="newEntity()">
                                    <span class="hidden md:block ml-2"><b>{{ translateService.instant('app.new') }}</b></span>
                                </button>
                                <button pButton pRipple icon="pi pi-trash"
                                    class="p-button-outlined p-button-rounded p-button-danger mr-2"
                                    (click)="deleteSelectedEntities()"
                                    [disabled]="!selectedEntities || !selectedEntities.length">
                                    <span class="hidden md:block ml-2"><b>{{ translateService.instant('app.delete') }}</b></span>
                                </button>
                                <button pButton pRipple icon="pi pi-file-excel"
                                    class="p-button-outlined p-button-rounded p-button-success mr-2"
                                    (click)="exportExcel(dataTable.filters, dataTable.sortField, dataTable.sortOrder)">
                                    <span class="hidden md:block ml-2"><b>{{ translateService.instant('app.export') }}</b></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th style="width: 3rem">
                            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                        </th>
                        <th pSortableColumn="name">
                            <div class="flex justify-content-between align-items-center">
                                <div>
                                    {{ 'admin.users.table.name' | translate }}
                                    <p-sortIcon field="name"></p-sortIcon>
                                </div>
                                <p-columnFilter type="text" field="name" display="menu"
                                    placeholder="{{ 'table.filter.textPlaceholder' | translate }}"></p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="email">
                            <div class="flex justify-content-between align-items-center">
                                <div>
                                    {{ 'admin.users.table.email' | translate }}
                                    <p-sortIcon field="email"></p-sortIcon>
                                </div>
                                <p-columnFilter type="text" field="email" display="menu"
                                    placeholder="{{ 'table.filter.textPlaceholder' | translate }}"></p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="roles.name">
                            <div class="flex justify-content-between align-items-center">
                                <div>
                                    {{ 'admin.users.table.role' | translate }}
                                    <p-sortIcon field="roles.name"></p-sortIcon>
                                </div>
                                <p-columnFilter field="roles.name" matchMode="equals" display="menu"
                                    [showMatchModes]="false">
                                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                        <p-dropdown [ngModel]="value" [options]="roles" 
                                            optionValue="label" optionLabel="label"
                                            (onChange)="filter($event.value)" placeholder="Any">
                                        </p-dropdown>
                                    </ng-template>
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="logged_at">
                            <div class="flex justify-content-between align-items-center">
                                <div>
                                    {{ 'admin.users.table.login_date' | translate }}
                                    <p-sortIcon field="logged_at"></p-sortIcon>
                                </div>
                                <p-columnFilter type="date" field="logged_at" display="menu"
                                    placeholder="{{ 'table.filter.textPlaceholder' | translate }}"></p-columnFilter>
                            </div>
                        </th>
                        <th></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-entity>
                    <tr>
                        <td>
                            <p-tableCheckbox [value]="entity"></p-tableCheckbox>
                        </td>
                        <td>
                            <div class="flex align-items-center gap-2 flex-wrap">
                                <p-avatar *ngIf="entity.image_256; else standardAvatar"
                                    image="data:image/png;base64, {{entity.image_256}}" styleClass="mr-2"
                                    shape="circle"></p-avatar>
                                <ng-template #standardAvatar>
                                    <p-avatar [label]="entity.name[0].toUpperCase()" styleClass="mr-2"
                                        shape="circle"></p-avatar>
                                </ng-template>
                                {{entity.name}}
                            </div>
                        </td>
                        <td>
                            {{entity.email}}
                        </td>
                        <td>
                            {{entity.roles[0].name}}
                        </td>
                        <td>
                            {{ entity.logged_at | date: 'MM/dd/yyyy HH:mm:ss'}}
                        </td>
                        <td>
                            <div class="flex justify-content-end">
                                <button pButton pRipple icon="pi pi-pencil" title="{{ 'app.buttonTitle.edit' | translate }}"
                                    class="p-button-outlined p-button-rounded p-button-success mr-2"
                                    (click)="editEntity(entity)"></button>
                                <button pButton pRipple icon="pi pi-trash" title="{{ 'app.buttonTitle.delete' | translate }}"
                                    class="p-button-outlined p-button-rounded p-button-warning"
                                    (click)="deleteEntity(entity, $event)"></button>
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>

<p-confirmDialog></p-confirmDialog>

<!-- USER DIALOG -->
<p-dialog *ngIf="selectedEntity"
    [header]="translateService.instant('admin.users.details.header').replace('{user.name}', selectedEntity.name ?? '')"
    [(visible)]="entityDialogVisible" [breakpoints]="{ '960px': '75vw' }" class="details-dialog" [draggable]="false"
    [resizable]="false">
    <p-tabView>
        <p-tabPanel [header]="translateService.instant('admin.users.details.tab_details')">
            <form [formGroup]="entityForm">
                <div class="grid p-fluid">
                    <div class="col-12 md:col-6">
                        <div class="field">
                            <label [ngClass]="{'text-red-500': isInvalid(entityForm, 'name')}" for="name">{{
                                'admin.users.table.name' | translate }} *</label>
                            <div class="p-inputgroup align-items-center">
                                <input type="text" pInputText id="name"
                                    [ngClass]="{'ng-invalid ng-dirty' : isInvalid(entityForm, 'name')}"
                                    formControlName="name" />
                                <p-avatar *ngIf="selectedEntity.image_256; else standardAvatar"
                                    image="data:image/png;base64, {{selectedEntity.image_256}}" styleClass="ml-2"
                                    shape="circle"></p-avatar>
                                <ng-template #standardAvatar>
                                    <p-avatar
                                        [label]="selectedEntity && selectedEntity.name ? selectedEntity.name[0].toUpperCase() : ''"
                                        styleClass="ml-2" shape="circle"></p-avatar>
                                </ng-template>
                            </div>
                            <small class="ng-dirty ng-invalid" *ngIf="isInvalid(entityForm, 'name')">{{
                                translateService.instant('app.isRequired').replace('{field}',
                                translateService.instant('admin.users.table.name')) }}</small>
                        </div>
                        <div class="field">
                            <label [ngClass]="{'text-red-500': isInvalid(entityForm, 'email')}" for="email">{{
                                'admin.users.table.email' | translate }} *</label>
                            <input type="email" pInputText id="email"
                                [ngClass]="{'ng-invalid ng-dirty' : isInvalid(entityForm, 'email')}"
                                formControlName="email" />
                            <small class="ng-dirty ng-invalid" *ngIf="isInvalid(entityForm, 'email')">{{
                                translateService.instant('app.isRequired').replace('{field}',
                                translateService.instant('admin.users.table.email')) }}</small>
                        </div>
                    </div>
                    <div class="col-12 md:col-6">
                        <div class="field" *ngIf="!selectedEntity['id'] || passwordEnabled">
                            <label [ngClass]="{'text-red-500': isInvalid(entityForm, 'password')}" for="password">{{
                                'admin.users.table.password' | translate }} *</label>
                            <p-password type="password" id="password"
                                [ngClass]="{'ng-invalid ng-dirty' : isInvalid(entityForm, 'password')}"
                                formControlName="password" [toggleMask]="true"></p-password>
                            <small class="ng-dirty ng-invalid" *ngIf="isInvalid(entityForm, 'password')">{{
                                translateService.instant('app.isRequired').replace('{field}',
                                translateService.instant('admin.users.table.password')) }}</small>
                        </div>
                        <div class="field">
                            <label for="roles">{{ 'admin.users.table.role' | translate }}</label>
                            <p-dropdown id="roles"
                                [options]="roles" 
                                formControlName="role"
                                optionLabel="label"
								optionValue="label" 
                                [showClear]="true" 
                                placeholder="Select a role">
                            </p-dropdown>
                        </div>
                    </div>
                </div>
            </form>
            <ng-template pTemplate="footer">
                <div class="flex justify-content-between align-items-center">
                    <div>
                        <button #hidePassword *ngIf="selectedEntity['id'] && passwordEnabled" pButton pRipple icon="pi pi-lock" title="{{ 'app.buttonTitle.hidePassword' | translate }}"
                            class="p-button-text p-button-warning" (click)="showOrHidePassword()"></button>
                        <button #showPassword *ngIf="selectedEntity['id'] && !passwordEnabled" pButton pRipple icon="pi pi-unlock" title="{{ 'app.buttonTitle.showPassword' | translate }}"
                            class="p-button-text p-button-warning" (click)="showOrHidePassword()"></button>
                    </div>
                    <div>
                        <button pButton pRipple [label]="translateService.instant('app.cancel')" icon="pi pi-times"
                            class="p-button-text" (click)="entityDialogVisible = false"></button>
                        <button pButton pRipple [label]="translateService.instant('app.save')" icon="pi pi-check"
                            class="p-button-text" (click)="saveEntity()"></button>
                    </div>
                </div>
            </ng-template>
        </p-tabPanel>
    </p-tabView>
</p-dialog>