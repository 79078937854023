import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { AppLayoutComponent } from "./layout/app.layout.component";
import { UsersListComponent } from './components/users-list/users-list.component';
import { LoginComponent } from './components/login/login.component';
import { SessionsListComponent } from './components/sessions-list/sessions-list.component';
import { MeasurementGuideListComponent } from './components/measurement-guide-list/measurement-guide-list.component';
import { DeviceListComponent } from './components/device-list/device-list.component';
import { AuthorizationGuard } from './shared/auth/auth.guard';
import { RecommendationGuideListComponent } from './components/recommentation-guide-list/recommendation-guide-list.component';
import { ReportTemplateListComponent } from './components/report-template-list/report-template-list.component';

@NgModule({
    imports: [
        RouterModule.forRoot([
            {
                path: '',
                component: AppLayoutComponent,
                canActivate: [ AuthorizationGuard ],
                children: [
                    { path: '', redirectTo: 'sessions', pathMatch: 'full' },
                    { path: 'sessions', component: SessionsListComponent },
                    { path: 'measurement-guides', component: MeasurementGuideListComponent },
                    { path: 'recommendation-guides', component: RecommendationGuideListComponent },
                    { path: 'users', component: UsersListComponent },
                    { path: 'devices', component: DeviceListComponent },
                    { path: 'report-templates', component: ReportTemplateListComponent },
                ]
            },
            { path: 'login', component: LoginComponent },
            { path: '**', redirectTo: '/notfound' },
        ], { scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled', onSameUrlNavigation: 'reload' })
    ],
    exports: [ RouterModule ]
})
export class AppRoutingModule {
}
