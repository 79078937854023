
export function getSeverity(status: string) {
    switch (status.toLowerCase()) {
        case 'draft':
        case 'pending':
        case 'waiting':
        case 'ready':
        case 'open':
            return 'info';
        case 'confirmed':
        case 'progress':
        case 'published':
            return 'primary';
        case 'to_close':
        case 'archived':
            return 'warning';
        case 'done':
        case 'closed':
            return 'success';
        case 'cancel':
            return 'danger';
        default:
            return null;
    }
}