import { Injectable, inject } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Observable, catchError, of } from "rxjs";
import { BaseService } from "src/app/shared/services/base.service";
import { NotificationsService } from "src/app/shared/services/notifications.service";
import { PrimeFilteringService } from "src/app/shared/services/prime-filtering.service";
import { environment } from "src/environments/environment";


@Injectable({ providedIn: 'root' })
export class DefectsService extends BaseService {
  protected override endpoint = environment.baseApiUrl + 'sessions/{id}/defects';

  private notificationsService = inject(NotificationsService);
  private translateService = inject(TranslateService);
  private primeFilteringService = inject(PrimeFilteringService);

  getAll(session_id: string, payload): Observable<object[]> {
    let url = this.buildEndpoint({ id: session_id });

    if (payload.pageSize) {
      url.searchParams.set('per_page', payload.pageSize);
      url.searchParams.set('page', payload.pageNumber);
    }

    if (payload.filter) {
      let filters = this.primeFilteringService.build(payload.filter);
      Object.entries(filters).forEach(([ key, value ]) => url.searchParams.set(key, value as string));
    }

    if (payload.order) {
      url.searchParams.set('sort', payload.order.direction + payload.order.field);
    }

    return this.httpClient.get<object[]>(url.toString(), this.defaultOptions)
      .pipe(
        catchError(() => {
          this.notificationsService.error(this.translateService.instant('sessions.defects.messages.show_error'));
          return of();
        })
      );
  }
}