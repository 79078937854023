import { Injectable, inject } from '@angular/core';
import {
  Route,
  Router,
  UrlSegment,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthState } from './auth.state';

@Injectable({ providedIn: 'root' })
export class AuthorizationGuard {
  private router = inject(Router);
  private authState = inject(AuthState);

  canMatch(route: Route, segments: UrlSegment[]): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return true
  }

  canActivate(): boolean {
    if (!this.authState.isAuthenticated()) {
      this.router.navigate([ '/login' ]);
    }
    return true;
  }
}