import { Component, ElementRef, Input, ViewChild, inject, signal } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';
import { Dialog } from 'primeng/dialog';
import { Table } from 'primeng/table';
import { getSeverity } from 'src/app/shared/functions';
import { FileSaverService } from 'src/app/shared/services/file-saver.service';
import { NotificationsService } from 'src/app/shared/services/notifications.service';
import { environment } from 'src/environments/environment';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { ColladaLoader } from 'three/examples/jsm/loaders/ColladaLoader.js';
import { DefectsService } from './services/defects.service';
import { MeasurementsService } from './services/measurements.service';
import { SessionService } from './services/sessions-list.service';
import * as saveAs from 'file-saver';

THREE.Object3D.DEFAULT_UP.set(0.0, 0.0, 1.0);

@Component({
  selector: 'app-sessions-list',
  templateUrl: './sessions-list.component.html',
  styleUrls: [ './sessions-list.component.scss' ],
  providers: [ ConfirmationService ]
})
export class SessionsListComponent {

  @ViewChild("dataTable") dataTable: Table;
  @ViewChild("stepsDataTable") stepsDataTable: Table;
  @ViewChild("measurementsDataTable") measurementsDataTable: Table;
  @ViewChild("defectsDataTable") defectsDataTable: Table;
  @ViewChild("container", { static: false }) containerRef: ElementRef;
  @ViewChild("dialog", { static: false }) dialogElement: Dialog;
  @Input() lazyLoadOnInit: boolean = true;

  private camera!: THREE.PerspectiveCamera;
  private renderer!: THREE.WebGLRenderer;
  private scene!: THREE.Scene;

  private INTERSECTED;
  private raycaster;
  private mouse;
  private mixer;
  private controls;
  private loader;
  private grid;
  private options: { [ key: string ]: boolean } = {};

  notificationsService = inject(NotificationsService);

  entities = signal([]);
  measurements = signal([]);
  defects = signal([]);
  skills: object[];
  groups: object[];
  entitiesCount: number = 0;
  stepsCount: number = 0;
  measurementsCount: number = 0;
  defectsCount: number = 0;
  selectedEntities: object[] = [];
  selectedEntity: object;
  selectedStep: object;
  first: number = 0;
  firstMeasurements: number = 0;
  firstDefects: number = 0;
  rows: number = environment.defaultPageSize;
  rowsMeasurements: number = environment.defaultPageSize;
  rowsDefects: number = environment.defaultPageSize;
  entityDialogVisible: boolean = false;
  stepDialogVisible: boolean = false;
  activeTab: number = 0;

  selectedPoint: THREE.Mesh;
  allTypesChecked: boolean = true;
  sidebarVisible: boolean = false;
  selectedPointDialogVisible: boolean = false;

  typeColors = [];
  private scale = { x: 1, y: 1, z: 1 };

  statuses = [
    { label: 'Open', value: 'open' },
    { label: 'Closed', value: 'closed' },
  ];

  entityForm = new FormGroup({
    code: new FormControl({ value: '', disabled: true }, [ Validators.required ]),
    part_number: new FormControl({ value: '', disabled: true }, [ Validators.required ]),
    state: new FormControl({ value: '', disabled: true }, []),
    created_at: new FormControl({ value: '', disabled: true }, []),
    device: new FormControl({ value: '', disabled: true }, []),
    recomendation_guide_read: new FormControl<boolean>({ value: false, disabled: true }, []),
    measuring_guide_read: new FormControl<boolean>({ value: false, disabled: true }, [])
  });

  stepForm = new FormGroup({
    step_code: new FormControl('', [ Validators.required ]),
    instrument_id: new FormControl('', [ Validators.required ]),
    reference_point: new FormControl('', [ Validators.required ]),
    created_at: new FormControl({ value: '', disabled: true }, []),
    instructions: new FormControl('', [ Validators.required ]),
  });

  constructor(
    public translateService: TranslateService,
    public sessionService: SessionService,
    public measurementsService: MeasurementsService,
    public defectsService: DefectsService,
    public fileService: FileSaverService,
    private confirmationService: ConfirmationService
  ) { }

  get modelLocations() {
    return this.selectedEntity[ 'locations' ].map((location) => {
      let _location = { ...location };
      _location.coordinate_x /= 1000;
      _location.coordinate_y /= 1000;
      _location.coordinate_z /= 1000;
      return _location;
    });
  }

  onLazyLoad(event) {
    this.first = event.first;
    this.rows = event.rows;
    this.fetchEntities(event.filters, event.sortField, event.sortOrder, (this.first / this.rows) + 1, this.rows);
  }

  onLazyLoadDefects(event) {
    this.firstDefects = event.first;
    this.rowsDefects = event.rows;
    this.fetchDefects(event.filters, event.sortField, event.sortOrder, (this.firstDefects / this.rowsDefects) + 1, this.rowsDefects);
  }

  onLazyLoadMeasurements(event) {
    this.firstMeasurements = event.first;
    this.rowsMeasurements = event.rows;
    this.fetchMeasurements(event.filters, event.sortField, event.sortOrder, (this.firstMeasurements / this.rowsMeasurements) + 1, this.rowsMeasurements);
  }

  private fetchEntities(filters: object, sortField: string, sortOrder: number, pageNumber: number, pageSize: number) {
    this.sessionService.getAll({
      filter: { ...filters },
      order: {
        field: sortField,
        direction: sortOrder !== 1 ? "-" : ""
      },
      pageNumber: pageNumber,
      pageSize: pageSize
    }).subscribe((response) => {
      this.entities.set(response[ 'data' ]);
      this.entitiesCount = response[ 'total' ];
      this.selectedEntities = [];
    });
  }

  private fetchDefects(filters: object, sortField: string, sortOrder: number, pageNumber: number, pageSize: number) {
    this.defectsService.getAll(this.selectedEntity[ 'id' ], {
      filter: { ...filters },
      order: {
        field: sortField,
        direction: sortOrder !== 1 ? "-" : ""
      },
      pageNumber: pageNumber,
      pageSize: pageSize
    }).subscribe((response) => {
      this.defects.set(response[ 'data' ]);
      this.defectsCount = response[ 'total' ];
    });
  }

  private fetchMeasurements(filters: object, sortField: string, sortOrder: number, pageNumber: number, pageSize: number) {
    this.measurementsService.getAll(this.selectedEntity[ 'id' ], {
      filter: { ...filters },
      order: {
        field: sortField,
        direction: sortOrder !== 1 ? "-" : ""
      },
      pageNumber: pageNumber,
      pageSize: pageSize
    }).subscribe((response) => {
      this.measurements.set(response[ 'data' ]);
      this.measurementsCount = response[ 'total' ];
    });
  }

  private hydrateEntityForm(entity: object) {
    this.entityForm.get('code').setValue(entity[ 'code' ]);
    this.entityForm.get('part_number').setValue(entity[ 'part_number' ]);
    this.entityForm.get('state').setValue(entity[ 'state' ]);
    this.entityForm.get('created_at').setValue(this.formatDate(entity[ 'created_at' ]));
    this.entityForm.get('device').setValue(entity[ 'device_setting' ][ 'name' ]);
    this.entityForm.get('recomendation_guide_read').setValue(entity[ 'recomendation_guide_read' ]);
    this.entityForm.get('measuring_guide_read').setValue(entity[ 'measuring_guide_read' ])
  }

  editEntity(entity: object) {
    this.activeTab = 0;
    this.selectedEntity = { ...entity };
    this.hydrateEntityForm(this.selectedEntity);
    this.generateTypeColors();
    this.entityDialogVisible = true;
    this.rowsDefects = environment.defaultPageSize;
    this.rowsMeasurements = environment.defaultPageSize;
    this.firstDefects = 0;
    this.firstMeasurements = 0;
    this.fetchMeasurements({}, "step.step_code", 1, 1, environment.defaultPageSize);
    this.fetchDefects({}, "type", 1, 1, environment.defaultPageSize);
  }

  async close(entity) {
    this.confirmationService.confirm({
      header: this.translateService.instant('app.closeSessionDialog.header'),
      message: this.translateService.instant('app.closeSessionDialog.message'),
      icon: 'pi pi-exclamation-triangle',
      accept: async () => {
        this.selectedEntity = await this.sessionService.close(entity);
        this.fetchEntities(this.dataTable.filters, this.dataTable.sortField, this.dataTable.sortOrder,
          (this.dataTable.first / this.dataTable.rows) + 1, this.dataTable.rows);
      }
    });
  }

  exportExcel(filters: object, sortField: string, sortOrder: number) {
    if (!this.selectedEntities || this.selectedEntities.length == 0) {
      this.notificationsService.error(this.translateService.instant('app.messages.no_entities_selected'));
    } else {
      this.saveExcel(this.selectedEntities);
      this.selectedEntities = [];
    }
  }

  saveExcel(entities: object[]) {
    let data = [];
    for (let device of entities) {
      let item = { ...device }
      data.push(item);
    }
    this.fileService.saveExcel(data, 'sessions');
  }

  isInvalid(form: FormGroup, field: string): boolean {
    return form && form.get(field).invalid && (form.get(field).dirty || form.get(field).touched);
  }

  getSeverity(status: string) {
    return getSeverity(status);
  }

  clearDataTable() {
    this.dataTable.clear();
  }

  formatDate(date): string {
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric', month: '2-digit', day: '2-digit',
      hour: '2-digit', minute: '2-digit', second: '2-digit',
      hour12: false
    };

    const dateFormatter = new Intl.DateTimeFormat('en-US', options);
    return dateFormatter.format(new Date(date));
  }

  formatPoint(step): string {
    let x = step.coordinate_x * 1000;
    let y = step.coordinate_y * 1000;
    let z = step.coordinate_z * 1000;
    return `(x: ${ x }, y: ${ y }, z: ${ z })`;
  }

  private get container(): HTMLDivElement {
    return this.containerRef.nativeElement;
  }

  onChangeTabEvent(event) {
    if (event.index !== 3) {
      while (this.container.firstChild) {
        this.container.removeChild(this.container.firstChild);
      }
      this.selectedPoint = undefined;
      window.removeEventListener('resize', this.onWindowResize);
    } else {
      this.createScene();
      this.animate();
      this.allTypesChecked = true;
      this.sidebarVisible = false;
    }
  }

  onHideEditSessionDialog() {
    while (this.container.firstChild) {
      this.container.removeChild(this.container.firstChild);
    }
    this.selectedPoint = undefined;
    window.removeEventListener('resize', this.onWindowResize);
  }

  private generateTypeColors() {
    this.typeColors = [];
    this.options = {};
    this.modelLocations.forEach((measurement) => {
      if (!Object.keys(this.typeColors).some(type => type === measurement.type)) {
        let color = new THREE.Color(Math.random(), Math.random(), Math.random());
        this.typeColors[ measurement.type ] = color;
        this.options[ measurement.type ] = true;
      }
    });
  }

  private calculateScale() {
    let locations = this.modelLocations;

    if (locations.length > 0) {
      let xCoords = locations.map(location => location.coordinate_x)
      let yCoords = locations.map(location => location.coordinate_y)
      let zCoords = locations.map(location => location.coordinate_z)

      let [ maxX, minX ] = [ Math.max(...xCoords), Math.min(...xCoords) ];
      let [ maxY, minY ] = [ Math.max(...yCoords), Math.min(...yCoords) ];
      let [ maxZ, minZ ] = [ Math.max(...zCoords), Math.min(...zCoords) ];

      this.scale.x = Math.abs(maxX - minX) || 1;
      this.scale.y = Math.abs(maxY - minY) || 1;
      this.scale.z = Math.abs(maxZ - minZ) || 1;
    }
  }

  private createScene() {
    this.calculateScale();
    let maxSize = Math.max(this.scale.x, this.scale.y, this.scale.z);

    this.camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 20000);
    this.camera.position.set(maxSize, maxSize, maxSize);
    this.scene = new THREE.Scene();
    this.scene.background = new THREE.Color(0xffffff);
    this.mouse = new THREE.Vector2();
    this.raycaster = new THREE.Raycaster();

    // this.grid = new THREE.GridHelper(maxSize * 4, 20, 0xc1c1c1, 0x8d8d8d);
    // this.grid = new THREE.AxesHelper(20);
    // this.scene.add(this.grid);

    this.load3DModel();

    const ambientLight = new THREE.AmbientLight(0xffffff, 0.2);
    this.scene.add(ambientLight);
    const pointLight = new THREE.PointLight(0xffffff, 0.8);
    this.scene.add(this.camera);
    this.camera.add(pointLight);

    this.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
    this.renderer.setClearColor(0xfbfbfb, 1);
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(window.innerWidth, this.dialogElement.container.offsetHeight);

    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.controls.screenSpacePanning = true;
    this.controls.minDistance = 0.2 * maxSize;
    this.controls.maxDistance = 4 * maxSize;
    this.controls.target.set(0, 0, 0);
    this.controls.update();

    this.show3DPoints();

    this.container.appendChild(this.renderer.domElement);
    this.container.querySelector('canvas').style.width = '100%';
    this.container.querySelector('canvas').style.height = '100%';

    this.renderer.domElement.addEventListener('mousemove', this.onPointerMove);
    this.renderer.domElement.addEventListener('click', this.onPointerClick);
    window.addEventListener('resize', this.onWindowResize);
  }

  private load3DModel() {
    this.loader = new ColladaLoader();
    this.loader.load(this.selectedEntity[ 'measuring_guide' ][ 'model_url' ], (collada) => {
      const model = collada.scene;
      model.position.set(0, 0, 0);
      this.mixer = new THREE.AnimationMixer(model);

      model.rotation.x = 0;
      model.rotation.y = 0;
      model.rotation.z = 0;
      this.scene.add(model);
    });
  }

  private show3DPoints() {
    this.modelLocations.forEach((measurement) => {
      this.scene.add(this.create3DObject(measurement));
    })
  }

  private create3DObject(measurement) {
    let geometry = new THREE.SphereGeometry(Math.sqrt(Math.pow(this.scale.x, 2) + Math.pow(this.scale.y, 2) + Math.pow(this.scale.z, 2)) * 0.01);
    let material;
    let object;

    material = new THREE.MeshLambertMaterial({ color: this.getMeasurementColor(measurement) });

    object = new THREE.Mesh(geometry, material);
    object.position.x = measurement.coordinate_x;
    object.position.y = measurement.coordinate_y;
    object.position.z = measurement.coordinate_z;

    object.userData[ "type" ] = measurement.type;
    object.userData[ "coordinate_x" ] = measurement.coordinate_x;
    object.userData[ "coordinate_y" ] = measurement.coordinate_y;
    object.userData[ "coordinate_z" ] = measurement.coordinate_z;
    object.userData[ "created_at" ] = measurement.created_at;
    object.userData[ "image" ] = measurement.images.at(0) ?? null;

    return object;
  }

  private getMeasurementColor(measurement) {
    return this.typeColors[ measurement.type ];
  }

  private onWindowResize = () => {
    this.camera.aspect = window.innerWidth / this.dialogElement.container.offsetHeight;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize(window.innerWidth, this.dialogElement.container.offsetHeight);
    this.container.querySelector('canvas').style.width = '100%';
    this.container.querySelector('canvas').style.height = '100%';
  }

  private onPointerMove = (event) => {
    const rect = this.container.getBoundingClientRect();

    this.mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
    this.mouse.y = - ((event.clientY - rect.top) / rect.height) * 2 + 1;
  }

  private showSelectedPointDialog = () => {
    this.selectedPointDialogVisible = true;
  }

  private onPointerClick = (event) => {
    this.selectedPoint = this.INTERSECTED;
    this.sidebarVisible = this.selectedPoint ? true : false;
  }

  private onClickMeasurementTypeFilter = (type) => {
    this.options[ type ] = !this.options[ type ];

    this.scene.traverse((object) => {
      if (object instanceof THREE.Mesh && object.geometry instanceof THREE.SphereGeometry) {
        object.visible = this.options[ object.userData[ 'type' ] ];
      }
    });
  }

  private selectAllEvent = (event) => {
    this.selectedEntity[ 'locations' ].forEach((measurement) => {
      this.options[ measurement.type ] = event.checked;
    });

    this.scene.traverse((object) => {
      if (object instanceof THREE.Mesh && object.geometry instanceof THREE.SphereGeometry) {
        object.visible = this.options[ object.userData[ 'type' ] ];
      }
    });
  }

  private animate() {
    const animateFn = () => {
      requestAnimationFrame(animateFn);
      this.render();
    };

    animateFn();
  }

  private isMesh = (object) => object instanceof THREE.Mesh;

  private render() {
    this.raycaster.setFromCamera(this.mouse, this.camera);

    const intersects = this.raycaster.intersectObjects(this.scene.children, false);

    if (intersects.length > 0 && intersects.some(intersect => this.isMesh(intersect.object))) {
      const intersect = intersects.find(intersect => this.isMesh(intersect.object));

      if (this.INTERSECTED != intersect.object && intersect.object.visible) {
        const object = intersect.object;

        if (object instanceof THREE.Mesh) {
          if (this.INTERSECTED != null) this.INTERSECTED.material.emissive.setHex(this.INTERSECTED.currentHex);

          this.INTERSECTED = object;
          this.INTERSECTED.currentHex = this.INTERSECTED.material.emissive.getHex();
          this.INTERSECTED.material.emissive.setHex(0xcc0f19);
        }
      }
    } else {
      if (this.INTERSECTED != null) this.INTERSECTED.material.emissive.setHex(this.INTERSECTED.currentHex);
      this.INTERSECTED = null;
    }

    this.renderer.render(this.scene, this.camera);
  }

  downloadHTMLTemplate() {
    this.sessionService.getHTMLReportTemplate(this.selectedEntity).then(response => {
      const blob = new Blob([ response ], { type: 'application/zip' });
      saveAs(blob, 'report-' + this.selectedEntity[ 'code' ] + '.zip');
    });
  }
}