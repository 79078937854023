import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Injectable, effect, inject } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Observable, catchError, of, throwError } from "rxjs";
import { AuthState } from "src/app/shared/auth/auth.state";
import { NotificationsService } from "src/app/shared/services/notifications.service";
import { PrimeFilteringService } from "src/app/shared/services/prime-filtering.service";
import { environment } from "src/environments/environment";
@Injectable({ providedIn: 'root' })
export class MeasurementGuidesService {
  private readonly modelAPI = environment.baseApiUrl + 'measuring-guides';
  private readonly defaultOptions = {
    headers: new HttpHeaders({}) 
  };

  private authState = inject(AuthState);
  private notificationsService = inject(NotificationsService);
  private translateService = inject(TranslateService);
  private primeFilteringService = inject(PrimeFilteringService);


  constructor(private httpClient: HttpClient) {
    effect(() => {
        this.defaultOptions.headers = this.defaultOptions.headers.set(
            'Authorization',
            `Bearer ${ this.authState.token() }`
        );
    })

    if (this.authState.isAuthenticated()) {
        this.defaultOptions.headers = this.defaultOptions.headers.set(
            'Authorization',
            `Bearer ${ this.authState.token() }`
        );
    }
  }

  getAll(payload): Observable<object[]> {
    let url = new URL(this.modelAPI);

    if (payload.pageSize) {
        url.searchParams.set('per_page', payload.pageSize);
        url.searchParams.set('page', payload.pageNumber);
    }

    if (payload.filter) {
        let filters = this.primeFilteringService.build(payload.filter);
        Object.entries(filters).forEach(([ key, value ]) => url.searchParams.set(key, value as string));
    }

    if (payload.order) {
        url.searchParams.set('sort', payload.order.direction + payload.order.field);
    }

    return this.httpClient.get<object[]>(url.toString(),this.defaultOptions)
    .pipe(
        catchError(() => {
          this.notificationsService.error(this.translateService.instant('measurement-guide.messages.show_error'));
          return of();
        })
    );
  }

  create(entity): Observable<object> {
    return this.httpClient.post<object>(`${this.modelAPI}`, entity, this.defaultOptions)
    .pipe(
        catchError(() => {
          this.notificationsService.error(this.translateService.instant('measurement-guide.messages.create_error'));
          return of();
        })
    );
  }

  update(entityId, entity): Observable<object> {
    return this.httpClient.put<object>(`${this.modelAPI}/${entityId}`, entity, this.defaultOptions).pipe(
      catchError(() => {
        this.notificationsService.error(this.translateService.instant('measurement-guide.messages.update_error'));
        return of();
      })
    );
  }

  delete(entity): Observable<object> {
    return this.httpClient.delete<object>(`${this.modelAPI}/${entity['id']}`,this.defaultOptions)
        .pipe(
            catchError(() => {
              this.notificationsService.error(this.translateService.instant('measurement-guide.messages.delete_error'));
              return of();
            })
        );
  }

  clone(entityId: number, newVersion: string): Observable<object> {
    return this.httpClient.post<object>(`${this.modelAPI}/${entityId}/clone`, {version: newVersion}, this.defaultOptions).pipe(
        catchError(() => {
          this.notificationsService.error(this.translateService.instant('measurement-guide.messages.clone_error'));
          return of();
        })
    );
  }

  publish(guide): Observable<object> {
    return this.httpClient.patch<object>(`${this.modelAPI}/${guide.id}`, {command: 'publish'}, this.defaultOptions).pipe(
      catchError(() => {
        this.notificationsService.error(this.translateService.instant('measurement-guide.messages.change_state_error'));
        return of();
      })
    );
  }

  unpublish(guide): Observable<object> {
    return this.httpClient.patch<object>(`${this.modelAPI}/${guide.id}`, {command: 'unpublish'}, this.defaultOptions).pipe(
      catchError(() => {
        this.notificationsService.error(this.translateService.instant('measurement-guide.messages.change_state_error'));
        return of();
      })
    );
  }

  archive(guide): Observable<object> {
    return this.httpClient.patch<object>(`${this.modelAPI}/${guide.id}`, {command: 'archive'}, this.defaultOptions).pipe(
      catchError(() => {
        this.notificationsService.error(this.translateService.instant('measurement-guide.messages.change_state_error'));
        return of();
      })
    );
  }

  unarchive(guide): Observable<object> {
    return this.httpClient.patch<object>(`${this.modelAPI}/${guide.id}`, {command: 'unarchive'}, this.defaultOptions).pipe(
      catchError(() => {
        this.notificationsService.error(this.translateService.instant('measurement-guide.messages.change_state_error'));
        return of();
      })
    );
  }

}