import { Injectable } from "@angular/core";
import * as FileSaver from 'file-saver';

@Injectable({ providedIn: 'root' })
export class FileSaverService {

    saveExcel(orders: object[], fileNamePrefix: string): void {
        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(orders);
            const workbook = { Sheets: { data: worksheet }, SheetNames: [ 'data' ] };
            const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
            let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
            let EXCEL_EXTENSION = '.xlsx';
            const data: Blob = new Blob([ excelBuffer ], {
                type: EXCEL_TYPE
            });
            FileSaver.saveAs(data, fileNamePrefix + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
        });
    }

    saveFile(base64Data: any, fileName: string, contentType: string): void {
        FileSaver.saveAs(this.base64toBlob(base64Data, contentType), fileName);
    }

    private base64toBlob(base64Data, contentType): Blob {
        contentType = contentType || '';
        const sliceSize = 1024;
        const byteCharacters = atob(base64Data);
        const bytesLength = byteCharacters.length;
        const slicesCount = Math.ceil(bytesLength / sliceSize);
        const byteArrays = new Array(slicesCount);

        for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
            const begin = sliceIndex * sliceSize;
            const end = Math.min(begin + sliceSize, bytesLength);

            const bytes = new Array(end - begin);
            for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
                bytes[ i ] = byteCharacters[ offset ].charCodeAt(0);
            }
            byteArrays[ sliceIndex ] = new Uint8Array(bytes);
        }
        return new Blob(byteArrays, { type: contentType });
    }
}