import { Injectable, inject } from '@angular/core';
import { Message, MessageService } from 'primeng/api';

@Injectable({ providedIn: 'root' })
export class NotificationsService {
  private messageService = inject(MessageService);

  private showNotification(message: Partial<Message>) {
    this.messageService.add({
      ...message,
      summary: message.severity ?? message.severity.charAt(0).toUpperCase() + message.severity.slice(1),
    })
  }

  success(message: string) {
    this.showNotification({ detail: message, severity: 'success' });
  }

  info(message: string) {
    this.showNotification({ detail: message, severity: 'info' });
  }

  warning(message: string) {
    this.showNotification({ detail: message, severity: 'warning' });
  }

  error(message: string) {
    this.showNotification({ detail: message, severity: 'error' });
  }
}