import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, effect, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, firstValueFrom, lastValueFrom, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthState } from 'src/app/shared/auth/auth.state';
import { NotificationsService } from 'src/app/shared/services/notifications.service';
import { PrimeFilteringService } from 'src/app/shared/services/prime-filtering.service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class SessionService {
    private modelAPI = environment.baseApiUrl + 'sessions';

    private authState = inject(AuthState);
    private notificationsService = inject(NotificationsService);
    private translateService = inject(TranslateService);
    private primeFilteringService = inject(PrimeFilteringService);

    private readonly defaultOptions = {
        headers: new HttpHeaders({})
    };

    constructor(private httpClient: HttpClient) {
        effect(() => {
            this.defaultOptions.headers = this.defaultOptions.headers.set(
                'Authorization',
                `Bearer ${ this.authState.token() }`
            );
        })

        if (this.authState.isAuthenticated()) {
            this.defaultOptions.headers = this.defaultOptions.headers.set(
                'Authorization',
                `Bearer ${ this.authState.token() }`
            );
        }
    }

    getAll(payload): Observable<object[]> {
        let url = new URL(this.modelAPI);

        if (payload.pageSize) {
            url.searchParams.set('per_page', payload.pageSize);
            url.searchParams.set('page', payload.pageNumber);
        }

        if (payload.filter) {
            let filters = this.primeFilteringService.build(payload.filter);
            Object.entries(filters).forEach(([ key, value ]) => url.searchParams.set(key, value as string));
        }

        if (payload.order) {
            url.searchParams.set('sort', payload.order.direction + payload.order.field);
        }

        return this.httpClient.get<object[]>(url.toString(), this.defaultOptions)
            .pipe(
                catchError(() => {
                    this.notificationsService.error(this.translateService.instant('sessions.messages.show_error'));
                    return of();
                })
            );
    }

    async getHTMLReportTemplate(session) {
        let url = new URL(`${ this.modelAPI }/${ session.id }/template`);

        let response = await firstValueFrom(
            this.httpClient.patch(
                url.toString(),
                {},
                {
                    ...this.defaultOptions,
                    ...{ responseType: "arraybuffer" }
                }
            )
        );

        return response;
    }

    async close(session) {
        try {
            return await lastValueFrom(
                this.httpClient.patch(
                    `${ this.modelAPI }/${ session.id }`,
                    { command: 'close' },
                    this.defaultOptions
                )
            );
        } catch (error) {
            this.notificationsService.error(
                this.translateService.instant('sessions.actions.close.error')
            );
            return null;
        }
    }
}