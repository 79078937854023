<div class="layout-topbar">
    <a class="layout-topbar-logo" routerLink="">
        <img src="assets/layout/images/{{layoutService.config.colorScheme === 'dark' ? 'logo-trigo-dark' : 'logo-trigo-white'}}.png" alt="logo">
        <span></span>
    </a>

    <button #menubutton class="p-link layout-menu-button layout-topbar-button" (click)="layoutService.onMenuToggle()">
        <i class="pi pi-bars"></i>
    </button>

    <button #topbarmenubutton class="p-link layout-topbar-menu-button layout-topbar-button" (click)="layoutService.showProfileSidebar()">
        <i class="pi pi-ellipsis-v"></i>
    </button>

    <div #topbarmenu class="layout-topbar-menu" [ngClass]="{'layout-topbar-menu-mobile-active': layoutService.state.profileSidebarVisible}">
        <p-menu #profilemenu [model]="profileMenuItems" [popup]="true" appendTo="body"></p-menu>
        <button (click)="profilemenu.toggle($event)" class="p-link layout-topbar-button">
            <i class="pi pi-user"></i>
            <span>Profile</span>
        </button>
    </div>
</div>