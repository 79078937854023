import { Component, Input, ViewChild, inject, signal } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { Table } from 'primeng/table';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { getSeverity } from 'src/app/shared/functions';
import { ReportTemplateService } from './report-template-list.service';
import { FileSaverService } from 'src/app/shared/services/file-saver.service';
import { NotificationsService } from 'src/app/shared/services/notifications.service';

@Component({
  selector: 'app-report-template-list',
  templateUrl: './report-template-list.component.html',
  styleUrls: ['./report-template-list.component.scss'],
  providers: [ ConfirmationService ]
})
export class ReportTemplateListComponent {
  @ViewChild("dataTable") dataTable: Table;
  @Input() lazyLoadOnInit: boolean = true;

  notificationsService = inject(NotificationsService);

  entities = signal([]);
  skills: object[];
  groups: object[];
  entitiesCount: number = 0;
  selectedEntities: object[] = [];
  selectedEntity: object;
  first: number = 0;
  rows: number = environment.defaultPageSize;
  entityDialogVisible: boolean = false;
  editorOptions: any = {language: 'handlebars', minimap:{enabled: false}, readOnly: false};
  cloneEntityDialogVisible: boolean = false;
  infoDialogVisible: boolean = false;
  newVersion: string = '';

  statuses = [
    { label: 'Draft', value: 'draft' },
    { label: 'Published', value: 'published' },
    { label: 'Archived', value: 'archived' },
  ];

  entityForm = new FormGroup({
    part_number: new FormControl('', [ Validators.required, Validators.pattern(/^[\w-]+#[\w-]+#[\w-]+$/) ]),
    version: new FormControl('', [ Validators.required ]),
    content: new FormControl('', [ Validators.required ]),
  });

  constructor(
    private confirmationService: ConfirmationService,
    public translateService: TranslateService,
    public templateService: ReportTemplateService,
    public fileService: FileSaverService
  ) { }

  onLazyLoad(event) {
    this.first = event.first;
    this.rows = event.rows;
    this.fetchEntities(event.filters, event.sortField, event.sortOrder, (this.first / this.rows) + 1, this.rows);
  }

  private fetchEntities(filters: object, sortField: string, sortOrder: number, pageNumber: number, pageSize: number) {
    this.templateService.getAll({
        filter: { ...filters },
        order: {
          field: sortField,
          direction: sortOrder !== 1 ? "-" : ""
        },
        pageNumber: pageNumber,
        pageSize: pageSize
    }).subscribe((response) => {
          this.entities.set(response['data']);
          this.entitiesCount = response['total'];
    });
    this.selectedEntities = [];
  }

  private hydrateEntityForm(entity: object) {
    this.entityForm.get('part_number').setValue(entity[ 'part_number' ]);
    this.entityForm.get('version').setValue(entity[ 'version' ]);
    this.entityForm.get('content').setValue(entity[ 'content' ]);
  }

  editEntity(entity: object) {
    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 100);
    this.selectedEntity = { ...entity };

    if(this.selectedEntity['state'] !== "draft"){
      this.entityForm.controls['part_number'].disable();
      this.entityForm.controls['version'].disable();
      this.editorOptions = { ...this.editorOptions, readOnly: true};
    } else {
      this.entityForm.controls['part_number'].enable();
      this.entityForm.controls['version'].enable();
      this.editorOptions = { ...this.editorOptions, readOnly: false};
    }

    this.entityDialogVisible = true;
    this.hydrateEntityForm(this.selectedEntity);
  }

  deleteEntity(entity: object) {
    this.confirmationService.confirm({
      header: this.translateService.instant('app.deleteDialog.header'),
      message: this.translateService.instant('app.deleteDialog.message'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.templateService.delete(entity['id']).subscribe(
          () => {
            this.fetchEntities(this.dataTable.filters, this.dataTable.sortField, this.dataTable.sortOrder,
              (this.dataTable.first / this.dataTable.rows) + 1, this.dataTable.rows);
            this.notificationsService.success(this.translateService.instant('report-template.messages.template_deleted'));
          }
        );
      }
    });
  }

  showCloneDialog(entity: object){
    this.selectedEntity = { ...entity };
    this.newVersion = '';  
    this.cloneEntityDialogVisible = true;
  }

  cloneEntity(){
    this.templateService.clone(this.selectedEntity['id'], this.newVersion).subscribe(
      () => {
        this.fetchEntities(this.dataTable.filters, this.dataTable.sortField, this.dataTable.sortOrder,
          (this.dataTable.first / this.dataTable.rows) + 1, this.dataTable.rows);
        this.notificationsService.success(this.translateService.instant('report-template.messages.template_cloned'));
      }
    );
    this.cloneEntityDialogVisible = false;
    this.newVersion = '';
  }

  deleteSelectedEntities()
  {
    if (this.selectedEntities.length === 0) {
      this.notificationsService.error(this.translateService.instant('app.messages.no_entities_selected'));
      return;
    }

    this.confirmationService.confirm({
    header: this.translateService.instant('app.deleteSelectedDialog.header'),
    message: this.translateService.instant('app.deleteSelectedDialog.message'),
    icon: 'pi pi-exclamation-triangle',
    accept: () => {
      for (var key in this.selectedEntities) {
        this.templateService.delete(this.selectedEntities[key]['id']).subscribe();
      }
      this.fetchEntities(this.dataTable.filters, this.dataTable.sortField, this.dataTable.sortOrder,
        (this.dataTable.first / this.dataTable.rows) + 1, this.dataTable.rows);
      this.notificationsService.success(this.translateService.instant('report-template.messages.templates_deleted'));
      this.selectedEntities = [];
    }
    });
  }

  newEntity()
  {
    this.resetFormValidation(this.entityForm);

    setTimeout(() => {
      window.dispatchEvent(new Event('resize'));
    }, 100);
    
    this.selectedEntity = {};
    this.entityDialogVisible = true;
    this.editorOptions = { ...this.editorOptions, readOnly: false};
  }

  saveEntity()
  {
    if(this.checkFormValidity(this.entityForm)){
      const templateId = this.selectedEntity['id'];
      const templateData = this.entityForm.value;
      
      if(templateId){
        this.templateService.update(templateId, templateData).subscribe(
          () => {
            this.fetchEntities(this.dataTable.filters, this.dataTable.sortField, this.dataTable.sortOrder,(this.dataTable.first / this.dataTable.rows) + 1, this.dataTable.rows);
            this.notificationsService.success(this.translateService.instant('report-template.messages.template_updated'));
            this.entityDialogVisible = false  
          }
        );
      }else{
        this.templateService.create(templateData).subscribe(
          () => {
            this.fetchEntities(this.dataTable.filters, this.dataTable.sortField, this.dataTable.sortOrder,
              (this.dataTable.first / this.dataTable.rows) + 1, this.dataTable.rows);
            this.notificationsService.success(this.translateService.instant('report-template.messages.template_created'));
            this.entityDialogVisible = false;
          }
        );
      }
    } 
  }

  exportExcel(filters: object, sortField: string, sortOrder: number) {
    if (!this.selectedEntities || this.selectedEntities.length == 0) {
      this.notificationsService.error(this.translateService.instant('app.messages.no_entities_selected'));
    } else {
      this.saveExcel(this.selectedEntities);
    }
    this.selectedEntities = [];
  }
  
  saveExcel(entities: object[]) {
    let data = [];
    for (let device of entities) {
      let item = { ...device }
      data.push(item);
    }
    this.fileService.saveExcel(data, 'reportTemplates');
  }

  isInvalid(form: FormGroup, field: string): boolean {
    return form && form.get(field).invalid && (form.get(field).dirty || form.get(field).touched);
  }

  checkFormValidity(form: FormGroup): boolean {
    form.markAllAsTouched();
    return form.valid;
  }

  resetFormValidation(form: FormGroup) {
    form.reset();
  }

  getSeverity(status: string) {
    return getSeverity(status);
  }

  formatDate(date): string {
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric', month: '2-digit', day: '2-digit',
      hour: '2-digit', minute: '2-digit', second: '2-digit',
      hour12: false
    };
    const dateFormatter = new Intl.DateTimeFormat('en-US', options);
    return dateFormatter.format(new Date(date));
  }

  onHideEditEntityDialog(){
    this.fetchEntities(this.dataTable.filters, this.dataTable.sortField, this.dataTable.sortOrder,
      (this.dataTable.first / this.dataTable.rows) + 1, this.dataTable.rows);
  }

  publish(entity) {
    if (this.checkFormValidity(this.entityForm)) {
      const guideId = this.selectedEntity[ 'id' ];
      const guideData = this.entityForm.value;
      this.templateService.update(guideId, guideData).subscribe(
        (reportTemplateUpdated) => {
          this.templateService.publish(reportTemplateUpdated).subscribe(
            (reportTemplatePublished) => {
              this.notificationsService.success(this.translateService.instant('report-template.messages.state_changed'));
              this.selectedEntity = reportTemplatePublished;
              this.hydrateEntityForm(this.selectedEntity);

              if (this.selectedEntity['published_at']) {
                this.entityForm.controls['part_number'].disable();
                this.entityForm.controls['version'].disable();
                this.editorOptions = { ...this.editorOptions, readOnly: true};
              }
            }
          );
          this.notificationsService.success(this.translateService.instant('report-template.messages.template_updated'));
        }
      );
    } else {
      this.templateService.publish(entity).subscribe(
        (reportTemplatePublished) => {
          this.notificationsService.success(this.translateService.instant('report-template.messages.state_changed'));
          this.selectedEntity = reportTemplatePublished;
          this.hydrateEntityForm(this.selectedEntity);

          if (this.selectedEntity['published_at']) {
            this.entityForm.controls['part_number'].disable();
            this.entityForm.controls['version'].disable();
            this.editorOptions = { ...this.editorOptions, readOnly: true};
          }
        }
      );
    }
  }

  unpublish(entity) {
    this.templateService.unpublish(entity).subscribe(
      (response) => {
        this.notificationsService.success(this.translateService.instant('report-template.messages.state_changed'));
        this.selectedEntity = response;
        this.hydrateEntityForm(this.selectedEntity);
        
        if (!this.selectedEntity['published_at']) {
          this.entityForm.controls['part_number'].enable();
          this.entityForm.controls['version'].enable();
          this.editorOptions = { ...this.editorOptions, readOnly: false};
        }
      }
    );
  }

  archive(entity) {
    this.templateService.archive(entity).subscribe(
      (response) => {
        this.notificationsService.success(this.translateService.instant('report-template.messages.state_changed'));
        this.selectedEntity = response;
        this.hydrateEntityForm(this.selectedEntity);
        
        if (this.selectedEntity['published_at']) {
          this.entityForm.controls['part_number'].disable();
          this.entityForm.controls['version'].disable();
          this.editorOptions = { ...this.editorOptions, readOnly: true};
        }
      }
    );
  }

  unarchive(entity) {
    this.templateService.unarchive(entity).subscribe(
      (response) => {
        this.notificationsService.success(this.translateService.instant('report-template.messages.state_changed'));
        this.selectedEntity = response;
        this.hydrateEntityForm(this.selectedEntity);
        
        if (!this.selectedEntity['published_at']) {
          this.entityForm.controls['part_number'].enable();
          this.entityForm.controls['version'].enable();
          this.editorOptions = { ...this.editorOptions, readOnly: false};
        }
      }
    );
  }
}
