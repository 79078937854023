import { Component, Input, ViewChild, inject, signal } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { Table } from 'primeng/table';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { DeviceService } from 'src/app/components/device-list/device.service'
import { FileSaverService } from 'src/app/shared/services/file-saver.service';
import { NotificationsService } from 'src/app/shared/services/notifications.service';

@Component({
  selector: 'app-device-list',
  templateUrl: './device-list.component.html',
  styleUrls: [ './device-list.component.scss' ],
  providers: [ ConfirmationService ]
})
export class DeviceListComponent {

  @ViewChild("dataTable") dataTable: Table;
  @Input() lazyLoadOnInit: boolean = true;

  notificationsService = inject(NotificationsService);

  entities = signal([]);
  skills: object[];
  groups: object[];
  entitiesCount: number = 0;
  selectedEntities: object[] = [];
  selectedEntity: object;
  first: number = 0;
  rows: number = environment.defaultPageSize;
  entityDialogVisible: boolean = false;
  cloneEntityDialogVisible: boolean = false;
  newName: string = '';

  entityForm = new FormGroup({
    name: new FormControl('', [ Validators.required ]),
    distance: new FormControl('', [ Validators.required ])
  });

  constructor(
    private confirmationService: ConfirmationService,
    public translateService: TranslateService,
    public deviceService: DeviceService,
    public fileService: FileSaverService,
  ) { }

  onLazyLoad(event) {
    this.first = event.first;
    this.rows = event.rows;
    this.fetchEntities(event.filters, event.sortField, event.sortOrder, (this.first / this.rows) + 1, this.rows);
  }

  private fetchEntities(filters: object, sortField: string, sortOrder: number, pageNumber: number, pageSize: number) {
    this.deviceService.getAll({
        filter: { ...filters },
        order: {
          field: sortField,
          direction: sortOrder !== 1 ? "-" : ""
        },
        pageNumber: pageNumber,
        pageSize: pageSize
    }).subscribe((response) => {
          this.entities.set(response['data']);
          this.entitiesCount = response['total'];
    });
    this.selectedEntities = [];
  }

  private hydrateEntityForm(entity: object) 
  {    
    this.entityForm.get('name').setValue(entity[ 'name' ]);
    this.entityForm.get('distance').setValue(entity[ 'distance' ]);
  }

  editEntity(entity: object) 
  {
    this.selectedEntity = { ...entity };
    this.entityDialogVisible = true;
    this.hydrateEntityForm(this.selectedEntity);
  }

  deleteEntity(entity: object) 
  {
    this.confirmationService.confirm({
      header: this.translateService.instant('app.deleteDialog.header'),
      message: this.translateService.instant('app.deleteDialog.message'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.deviceService.delete(entity['id']).subscribe(
          () => {
            this.fetchEntities(this.dataTable.filters, this.dataTable.sortField, this.dataTable.sortOrder,
              (this.dataTable.first / this.dataTable.rows) + 1, this.dataTable.rows);
            this.notificationsService.success(this.translateService.instant('device-list.messages.device_settings_deleted'));
          }
        );
      }
    });
  }

  deleteSelectedEntities()
  {
    if (this.selectedEntities.length === 0) {
      this.notificationsService.error(this.translateService.instant('app.messages.no_entities_selected'));
      return;
    }

    this.confirmationService.confirm({
    header: this.translateService.instant('app.deleteSelectedDialog.header'),
    message: this.translateService.instant('app.deleteSelectedDialog.message'),
    icon: 'pi pi-exclamation-triangle',
    accept: () => {
        for (var key in this.selectedEntities) {
          this.deviceService.delete(this.selectedEntities[key]['id']).subscribe();
        }
        this.fetchEntities(this.dataTable.filters, this.dataTable.sortField, this.dataTable.sortOrder,
          (this.dataTable.first / this.dataTable.rows) + 1, this.dataTable.rows);
        this.notificationsService.success(this.translateService.instant('device-list.messages.device_settings_deleted'));
        this.selectedEntities = [];
      }
    });
  }

  showCloneDialog(entity: object){
    this.selectedEntity = { ...entity };
    this.newName = '';  
    this.cloneEntityDialogVisible = true;
  }

  cloneEntity(){
    this.deviceService.clone(this.selectedEntity['id'], this.newName).subscribe(
      () => {
        this.fetchEntities(this.dataTable.filters, this.dataTable.sortField, this.dataTable.sortOrder,
          (this.dataTable.first / this.dataTable.rows) + 1, this.dataTable.rows);
        this.notificationsService.success(this.translateService.instant('device-list.messages.device_settings_cloned'));
      }
    );
    this.cloneEntityDialogVisible = false;
    this.newName = '';
  }

  newEntity()
  {
    this.selectedEntity = {};
    this.resetFormValidation(this.entityForm);  
    this.entityDialogVisible = true;
  }

  saveEntity()
  {    
    if(this.checkFormValidity(this.entityForm)){  
      const deviceId = this.selectedEntity['id'];
      const deviceData = this.entityForm.value;

      if(deviceId){
        this.deviceService.update(deviceId, deviceData).subscribe(
          () => {
            this.fetchEntities(this.dataTable.filters, this.dataTable.sortField, this.dataTable.sortOrder,(this.dataTable.first / this.dataTable.rows) + 1, this.dataTable.rows);
            this.notificationsService.success(this.translateService.instant('device-list.messages.device_settings_updated'));
            this.entityDialogVisible = false;
          }
        );
      }else{
        this.deviceService.create(deviceData).subscribe(
          () => {
            this.fetchEntities(this.dataTable.filters, this.dataTable.sortField, this.dataTable.sortOrder,
              (this.dataTable.first / this.dataTable.rows) + 1, this.dataTable.rows);
            this.notificationsService.success(this.translateService.instant('device-list.messages.device_settings_created'));
            this.entityDialogVisible = false;
          }
        );
      }
    }
  }

  exportExcel(filters: object, sortField: string, sortOrder: number) {
    if (!this.selectedEntities || this.selectedEntities.length == 0) {
      this.notificationsService.error(this.translateService.instant('app.messages.no_entities_selected'));
    } else {
      this.saveExcel(this.selectedEntities);
      this.selectedEntities = [];
    }
  }

  saveExcel(entities: object[]) {
    let data = [];
    for (let device of entities) {
      let item = { ...device }
      data.push(item);
    }
    this.fileService.saveExcel(data, 'deviceSettings');
  }

  isInvalid(form: FormGroup, field: string): boolean {
    return form && form.get(field).invalid && (form.get(field).dirty || form.get(field).touched);
  }

  checkFormValidity(form: FormGroup): boolean {
    form.markAllAsTouched();
    return form.valid;
  }

  resetFormValidation(form: FormGroup) {
    form.reset();
  }

  formatDate(date): string {
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric', month: '2-digit', day: '2-digit',
      hour: '2-digit', minute: '2-digit', second: '2-digit',
      hour12: false
    };

    const dateFormatter = new Intl.DateTimeFormat('en-US', options);
    return dateFormatter.format(new Date(date));
  }
}