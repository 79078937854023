import { Component, OnInit, effect, inject } from '@angular/core';
import { LayoutService } from 'src/app/layout/service/app.layout.service';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Validators } from '@angular/forms';
import { AuthService } from '../../shared/auth/auth.service';
import { LoginState } from './login.state';
import { AuthState } from 'src/app/shared/auth/auth.state';

@Component({
    selector: 'polaris-login',
    templateUrl: './login.component.html',
    styleUrls: [ './login.component.scss' ]
})
export class LoginComponent implements OnInit {
    layoutService = inject(LayoutService);
    translateService = inject(TranslateService);
    authService = inject(AuthService);

    loginState = inject(LoginState);
    authState = inject(AuthState);

    loginForm = new FormGroup({
        email: new FormControl('', [ Validators.required, Validators.email ]),
        password: new FormControl('', Validators.required),
    });

    ngOnInit(): void {
        this.loginForm.valueChanges.subscribe(user => {
            this.loginState.setUser(user)
        });
    }

    get email() {
        return this.loginForm.get('email');
    }

    get password() {
        return this.loginForm.get('password');
    }

    checkLoginFormValidity(): boolean {
        this.loginForm.markAllAsTouched();
        return this.loginForm.valid;
    }

    resetLoginFormValidation() {
        this.loginForm.markAsPristine();
        this.loginForm.markAsUntouched();
    }

    async login() {
        this.loginState.setLoading(true);
        this.loginForm.markAllAsTouched();
        if (this.checkLoginFormValidity()) {
            await this.authService.login(this.loginState.user())
        }
        this.loginState.setLoading(false);
    }
}