import { Component, Input, ViewChild, inject, signal } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { Filters } from 'src/app/shared/models/table.model';
import { Table } from 'primeng/table';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { UsersService } from './users.service';
import { FileSaverService } from 'src/app/shared/services/file-saver.service';
import { NotificationsService } from 'src/app/shared/services/notifications.service';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss'],
  providers: [ConfirmationService]
})
export class UsersListComponent {

  @ViewChild("dataTable") dataTable: Table;
  @Input() lazyLoadOnInit: boolean = true;

  notificationsService = inject(NotificationsService);

  entities = signal([]);
  skills: object[];
  entitiesCount: number = 0;
  loading: boolean = false;
  selectedEntities: object[] = [];
  selectedEntity: object;
  first: number = 0;
  rows: number = environment.defaultPageSize;
  entityDialogVisible: boolean = false;
  invalidPassword: boolean = false;
  passwordEnabled: boolean = false;

  loadingSubmit: boolean = false;
  entityForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required, Validators.minLength(8)]),
    role: new FormControl(''),
  });

  roles: object[] = [
    {value:1, label:'administrator'},
    {value:2, label:'operator'}
  ]

  constructor(
    private confirmationService: ConfirmationService,
    public translateService: TranslateService,
    private usersService: UsersService,
    private fileSaverService: FileSaverService
  ) 
  {}

  onLazyLoad(event) {
    this.first = event.first;
    this.rows = event.rows;
    this.fetchEntities(event.filters, event.sortField, event.sortOrder, (this.first / this.rows) + 1, this.rows);
  }

  private fetchEntities(filters: object, sortField: string, sortOrder: number, pageNumber: number, pageSize: number) {
    this.loading = true;
    this.usersService.getAll({
        filter: { ...filters },
        order: {
          field: sortField,
          direction: sortOrder !== 1 ? "-" : ""
        },
        pageNumber: pageNumber,
        pageSize: pageSize
      }).subscribe((response) => {
          this.entities.set(response['data']);
          this.entitiesCount = response['total'];
          this.loading = false;
    });
  }

  private hydrateEntityForm(entity: object) {
    this.entityForm.get('name').setValue(entity['name']);
    this.entityForm.get('email').setValue(entity['email']);
    this.entityForm.get('password').setValue(entity['password']);
    this.entityForm.get('role').setValue(entity['roles'][0]['name']);
  }

  newEntity() {
    this.selectedEntity = {};    
    this.resetFormValidation(this.entityForm);   
    this.entityDialogVisible = true;
    this.invalidPassword = false;
  }

  editEntity(entity: object) {
    this.selectedEntity = { ...entity };
    this.passwordEnabled = false;
    this.entityDialogVisible = true;
    this.hydrateEntityForm(this.selectedEntity);
  }

  saveEntity() {
    this.loadingSubmit = true;
    if (this.selectedEntity['id'] && !this.entityForm.get('password').value) {
      this.entityForm.get('password').clearValidators();
      this.entityForm.get('password').updateValueAndValidity();
    }

    if (this.checkFormValidity(this.entityForm)) {
      let entity = {
        'name': this.entityForm.get('name').value,
        'email': this.entityForm.get('email').value,
        'password': this.entityForm.get('password').value,
        'role': this.entityForm.get('role').value ?? ''
      }

      if (this.selectedEntity['id']) {
        entity['id'] = this.selectedEntity['id'];
      }

      // Quitamos específicamente el caso de password vacío
      // que ocurre cuando el campo no se muestra en el formulario
      if (entity['password'] == '') {
        delete entity['password'];
      }

      if(this.selectedEntity['email'] === entity['email']){
        delete entity['email'];
      }

      if(entity['id']){
        this.usersService.update(entity).subscribe(
          () => {
            this.fetchEntities(this.dataTable.filters, this.dataTable.sortField, this.dataTable.sortOrder,
               (this.dataTable.first / this.dataTable.rows) + 1, this.dataTable.rows);
            this.notificationsService.success(this.translateService.instant('admin.users.messages.user_updated'));
          }
        );
      }else{
        this.usersService.post(entity).subscribe(
          () => {
            this.fetchEntities(this.dataTable.filters, this.dataTable.sortField, this.dataTable.sortOrder,
              (this.dataTable.first / this.dataTable.rows) + 1, this.dataTable.rows);
            this.notificationsService.success(this.translateService.instant('admin.users.messages.user_created'));
          }
        );
      }
      
      this.entityDialogVisible = false;
    }
  }

  deleteEntity(entity: object) {
    this.confirmationService.confirm({
      header: this.translateService.instant('app.deleteDialog.header'),
      message: this.translateService.instant('app.deleteDialog.message'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.usersService.delete(entity).subscribe(
          () => {
            this.fetchEntities(this.dataTable.filters, this.dataTable.sortField, this.dataTable.sortOrder,
              (this.dataTable.first / this.dataTable.rows) + 1, this.dataTable.rows);
            this.notificationsService.success(this.translateService.instant('admin.users.messages.user_deleted'));
          }
        );
      },
    });
  }

  deleteSelectedEntities() {
    // No se puede eliminar al usuario actual
    // const usersToDelete = this.selectedEntities.filter(e => e['id'] !== this.loggedUser['id'])
    this.confirmationService.confirm({
      header: this.translateService.instant('app.deleteSelectedDialog.header'),
      message: this.translateService.instant('app.deleteSelectedDialog.message'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        for (var key in this.selectedEntities) {
          this.usersService.delete(this.selectedEntities[key]).subscribe();
        }
        this.fetchEntities(this.dataTable.filters, this.dataTable.sortField, this.dataTable.sortOrder,
          (this.dataTable.first / this.dataTable.rows) + 1, this.dataTable.rows);
        this.notificationsService.success(this.translateService.instant('admin.users.messages.users_deleted'));
        this.selectedEntities = [];
      }
    });
  }

  isInvalid(form: FormGroup, field: string): boolean {
    return form && form.get(field).invalid && (form.get(field).dirty || form.get(field).touched);
  }

  checkFormValidity(form: FormGroup): boolean {
    form.markAllAsTouched();
    return form.valid;
  }

  resetFormValidation(form: FormGroup) {
    form.reset();
    this.loadingSubmit = false;
  }

  showOrHidePassword() {
    this.passwordEnabled = !this.passwordEnabled;
  }

  exportExcel(filters: object, sortField: string, sortOrder: number) {
    if (!this.selectedEntities || this.selectedEntities.length == 0) {
      console.log('No entities selected');
    } else {
      this.saveExcel(this.selectedEntities);
    }
  }

  saveExcel(entities: object[]) {
    let data = [];
    for (let device of entities) {
      let item = { ...device }
      data.push(item);
    }
    this.fileSaverService.saveExcel(data, 'users');
  }

  clearDataTable() {
    this.dataTable.clear();
  }
}