import { Component, ElementRef, Input, OnInit, ViewChild, inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService, FilterMatchMode } from 'primeng/api';
import { Table } from 'primeng/table';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { getSeverity } from 'src/app/shared/functions';
import { RecommendationGuidesService } from './recommendation-guides.service';
import { FileSaverService } from 'src/app/shared/services/file-saver.service';
import { RecommendationGuidesState } from './recommendation-guides.state';
import { RecommendationGuide } from './recomendation-guide.model';
import { NotificationsService } from 'src/app/shared/services/notifications.service';

@Component({
  selector: 'app-recommendation-guide-list',
  templateUrl: './recommendation-guide-list.component.html',
  providers: [ ConfirmationService ]
})
export class RecommendationGuideListComponent implements OnInit {

  @ViewChild("dataTable") dataTable: Table;
  @ViewChild("editionPartNumberInput") editionPartNumberInput: HTMLInputElement;
  @ViewChild("creationPartNumberInput") creationPartNumberInput: ElementRef;
  @Input() lazyLoadOnInit: boolean = true;

  getSeverity = getSeverity;

  separatorExp: RegExp = /,| /;

  partNumbersMatchModeOptions = [
    { label: 'Contains', value: FilterMatchMode.CONTAINS },
    { label: 'Not contains', value: FilterMatchMode.NOT_CONTAINS }
  ];

  notificationsService = inject(NotificationsService);
  fileSaverService = inject(FileSaverService);
  confirmationService = inject(ConfirmationService);
  translateService = inject(TranslateService);
  recommendationGuidesService = inject(RecommendationGuidesService);

  statuses = [
    { label: 'Draft', value: 'draft' },
    { label: 'Published', value: 'published' },
    { label: 'Archived', value: 'archived' },
  ];

  state = inject(RecommendationGuidesState);

  selectedEntities: RecommendationGuide[] = [];
  first: number = 0;
  rows: number = environment.defaultPageSize;

  editDialogVisible: boolean = false;
  editorReadonly: boolean = false;
  createDialogVisible: boolean = false;
  cloneEntityDialogVisible: boolean = false;
  newVersion: string = '';
  incorrectPatternValue: string = '';
  referencePattern: RegExp = /^[\w-]+#[\w-]+#[\w-]+$/;

  editForm = new FormGroup({
    version: new FormControl(''),
    part_numbers: new FormControl([], [ Validators.required ]),
    content: new FormControl('', [ Validators.required ]),
  });

  createForm = new FormGroup({
    version: new FormControl('', [ Validators.required ]),
    part_numbers: new FormControl([], [ Validators.required ]),
    content: new FormControl('', [ Validators.required ]),
  });

  roles: object[] = [
    { value: 1, label: 'administrator' },
    { value: 2, label: 'operator' }
  ]

  ngOnInit(): void {
    this.createForm.valueChanges.subscribe(entity => {
      this.state.create.setEntity(entity as RecommendationGuide)
    });
    this.editForm.valueChanges.subscribe(partial => {
      this.state.edit.setEntity({
        ...this.state.edit.entity(),
        ...partial
      } as RecommendationGuide)
    });
  }

  async onLazyLoad(event) {
    this.first = event.first;
    this.rows = event.rows;
    this.fetchEntities(event.filters, event.sortField, event.sortOrder, (this.first / this.rows) + 1, this.rows);
  }

  private async fetchEntities(filters: object, sortField: string, sortOrder: number, pageNumber: number, pageSize: number) {
    await this.recommendationGuidesService.getAll({
      filter: { ...filters },
      order: {
        field: sortField,
        direction: sortOrder !== 1 ? "-" : ""
      },
      pageNumber: pageNumber,
      pageSize: pageSize
    });
    this.selectedEntities = [];
  }

  private hydrateForm(form: FormGroup, entity: RecommendationGuide) {

    form.get('version').setValue(entity.version);
    form.get('part_numbers').setValue(entity.part_numbers);
    form.get('content').setValue(entity.content);
  }

  showCreateDialog() {
    this.incorrectPatternValue = '';
    if (this.creationPartNumberInput) this.creationPartNumberInput.nativeElement.value = '';
    this.state.create.setEntity({} as RecommendationGuide);
    this.resetFormValidation(this.createForm);
    this.createDialogVisible = true;
  }

  showEditDialog(entity: RecommendationGuide) {
    this.incorrectPatternValue = '';
    this.resetFormValidation(this.editForm);
    this.state.edit.setEntity(entity);

    if ([ 'published', 'archived' ].includes(entity.state)) {
      this.editForm.disable({ emitEvent: false });
    } else {
      this.editForm.enable({ emitEvent: false });
    }

    this.editorReadonly = this.state.edit.entity().state !== "draft";

    this.editDialogVisible = true;

    this.hydrateForm(this.editForm, this.state.edit.entity());
  }

  showCloneDialog(entity: RecommendationGuide) {
    this.state.create.setEntity(entity);
    this.newVersion = '';
    this.cloneEntityDialogVisible = true;
  }

  async editEntity() {
    this.state.edit.setLoading(true);

    if (this.checkFormValidity(this.editForm)) {
      await this.recommendationGuidesService.update(this.state.edit.entity());
      this.editDialogVisible = false;
    }

    this.state.edit.setLoading(false);
  }

  async createEntity() {
    this.state.create.setLoading(true);

    if (this.checkFormValidity(this.createForm)) {
      await this.recommendationGuidesService.post(this.state.create.entity());
      await this.fetchEntities(this.dataTable.filters, this.dataTable.sortField, this.dataTable.sortOrder,
        (this.dataTable.first / this.dataTable.rows) + 1, this.dataTable.rows);

      this.createDialogVisible = false;
      this.state.create.clear();
      this.clearForm(this.createForm);
      this.resetFormValidation(this.createForm);
    }

    this.state.create.setLoading(false);
  }

  async deleteEntity(entity: RecommendationGuide) {
    this.confirmationService.confirm({
      header: this.translateService.instant('app.deleteDialog.header'),
      message: this.translateService.instant('app.deleteDialog.message'),
      icon: 'pi pi-exclamation-triangle',
      accept: async () => {
        await this.recommendationGuidesService.delete(entity);
        await this.fetchEntities(this.dataTable.filters, this.dataTable.sortField, this.dataTable.sortOrder,
          (this.dataTable.first / this.dataTable.rows) + 1, this.dataTable.rows);
      },
    });
  }

  deleteSelectedEntities() {
    this.confirmationService.confirm({
      header: this.translateService.instant('app.deleteSelectedDialog.header'),
      message: this.translateService.instant('app.deleteSelectedDialog.message'),
      icon: 'pi pi-exclamation-triangle',
      accept: async () => {
        for (const entity of this.selectedEntities) {
          await this.recommendationGuidesService.delete(entity);
        }
        await this.fetchEntities(this.dataTable.filters, this.dataTable.sortField, this.dataTable.sortOrder,
          (this.dataTable.first / this.dataTable.rows) + 1, this.dataTable.rows);  
        this.selectedEntities = [];
      }
    });
  }

  async cloneEntity() {
    await this.recommendationGuidesService.clone(this.state.create.entity(), this.newVersion);
    await this.fetchEntities(this.dataTable.filters, this.dataTable.sortField, this.dataTable.sortOrder,
      (this.dataTable.first / this.dataTable.rows) + 1, this.dataTable.rows);

    this.cloneEntityDialogVisible = false;
    this.newVersion = '';
  }

  isInvalid(form: FormGroup, field: string): boolean {
    return form && form.get(field).invalid && (form.get(field).dirty || form.get(field).touched);
  }

  checkFormValidity(form: FormGroup): boolean {
    form.markAllAsTouched();
    return form.valid;
  }

  resetFormValidation(form: FormGroup) {
    form.reset();
  }

  clearForm(form: FormGroup) {
    form.get('version').setValue('');
    form.get('part_numbers').setValue('');
    form.get('content').setValue('');
  }

  exportExcel(filters: object, sortField: string, sortOrder: number) {
    if (!this.selectedEntities || this.selectedEntities.length == 0) {
      this.notificationsService.error(this.translateService.instant('app.messages.no_entities_selected'));
    } else {
      this.saveExcel(this.selectedEntities);
    }
    this.selectedEntities = [];
  }

  saveExcel(entities: RecommendationGuide[]) {
    let data = [];
    for (let guide of entities) {
      data.push({
        id: guide.id,
        part_numbers: guide.part_numbers,
        version: guide.version,
        state: guide.state,
        content: guide.content,
        published_at: guide.published_at,
        created_at: guide.created_at,
        updated_at: guide.updated_at,
        user_id: guide.user.id,
        user_name: guide.user.name,
        user_email: guide.user.email,
      });
    }
    this.fileSaverService.saveExcel(data, 'recommendation-guides');
  }

  clearDataTable() {
    this.dataTable.clear();
  }

  async publish(entity: RecommendationGuide) {
    this.state.edit.setLoading(true);
    if (this.checkFormValidity(this.editForm)) {
      await this.recommendationGuidesService.update(this.state.edit.entity());
    }
    await this.recommendationGuidesService.publish(entity);
    this.state.edit.setLoading(false);

    if (this.state.edit.entity().published_at) {
      this.editForm.disable({ emitEvent: false });
      this.editorReadonly = true;
    }
  }

  async unpublish(entity: RecommendationGuide) {
    this.state.edit.setLoading(true);
    await this.recommendationGuidesService.unpublish(entity);
    this.state.edit.setLoading(false);

    if (!this.state.edit.entity().published_at) {
      this.editForm.enable({ emitEvent: false });
      this.editorReadonly = false;
    }
  }

  async archive(entity: RecommendationGuide) {
    this.state.edit.setLoading(true);
    await this.recommendationGuidesService.archive(entity);
    this.state.edit.setLoading(false);

    if (!this.state.edit.entity().published_at) {
      this.editForm.disable({ emitEvent: false });
      this.editorReadonly = true;
    }
  }

  async unarchive(entity: RecommendationGuide) {
    this.state.edit.setLoading(true);
    await this.recommendationGuidesService.unarchive(entity);
    this.state.edit.setLoading(false);

    if (!this.state.edit.entity().published_at) {
      this.editForm.enable({ emitEvent: false });
      this.editorReadonly = false;
    }
  }

  onRemovePartNumber(form: FormGroup, index: number) {
    let items = [ ...form.get('part_numbers').value ];
    items.splice(index, 1);
    form.get('part_numbers').setValue(items);
  }

  onAddPartNumber(form: FormGroup, input: any) {
    this.incorrectPatternValue = '';
    if (!input.value) {
      return;
    }

    let items = form.get('part_numbers').value;

    if (!items) {
      form.get('part_numbers').setValue([]);
      items = [ ...form.get('part_numbers').value ];
    }

    const index = items.findIndex((p) => p == input.value);
    if (index == -1) {
      items.push(input.value);
    }
    
    form.get('part_numbers').setValue(items);
    
    form.get('part_numbers').markAsTouched();
    form.get('part_numbers').valid;
    if(!this.referencePattern.test(input.value)) {
      this.incorrectPatternValue = input.value;
      items.pop();
      form.get('part_numbers').setValue(items);
    }
  }
}