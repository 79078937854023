<div class="surface-ground flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
    <div class="flex flex-column align-items-center justify-content-center">
        <img src="assets/layout/images/{{layoutService.config.colorScheme === 'light' ? 'logo-trigo-dark' : 'logo-trigo-white'}}.png"
            alt="Trigo logo" class="mb-5 w-15rem flex-shrink-0">
        <div class="login-gradient">
            <div class="w-full surface-card py-6 px-5 sm:px-8 login-radius">
                <div>
                    <form [formGroup]="loginForm" (ngSubmit)="login()">
                        <div>
                            <label for="email" class="block text-900 text-xl font-medium mb-2"
                                [ngClass]="{'text-red-500': email.invalid && (email.dirty || email.touched)}">{{
                                'login.email' |
                                translate
                                }}</label>
                            <input id="email" type="text"
                                [ngClass]="{'ng-dirty ng-invalid': email.invalid && (email.dirty || email.touched)}"
                                [placeholder]="translateService.instant('login.email_placeholder')" pInputText
                                class="w-full md:w-30rem mb-5" style="padding:1rem" formControlName="email" />
                        </div>
                        <div>
                            <label for="password" class="block text-900 font-medium text-xl mb-2"
                                [ngClass]="{'text-red-500': password.invalid && (password.dirty || password.touched)}">{{
                                'login.password' |
                                translate }}</label>
                            <p-password id="password"
                                [ngClass]="{'ng-dirty ng-invalid': password.invalid && (password.dirty || password.touched)}"
                                [placeholder]=" translateService.instant('login.password_placeholder')"
                                formControlName="password" [toggleMask]="true" styleClass="mb-5" [feedback]="false"
                                inputStyleClass="w-full p-3 md:w-30rem"></p-password>
                        </div>

                        <div class="flex align-items-center justify-content-between mt-2">
                            <p-button type="submit" [label]="translateService.instant('login.signin')" class="w-full"
                                severity="success" [loading]="loginState.loading()" [styleClass]="'w-full'" size="large"
                                [routerLink]="['/sessions']"></p-button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
<p-toast></p-toast>