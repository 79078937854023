<div class="grid">
    <div class="col-12">
        <div class="card">
            <h5>{{ 'report-template.table.title' | translate }}</h5>
            <p-table #dataTable [value]="entities()" [scrollable]="true" scrollHeight="calc(100vh - 380px)"
                [rowHover]="true" styleClass="p-datatable-sm" [(selection)]="selectedEntities" responsiveLayout="scroll"
                lazy="true" [lazyLoadOnInit]="lazyLoadOnInit" (onLazyLoad)="onLazyLoad($event)" sortField="part_number"
                paginator="true" [rows]="rows" [first]="first" [totalRecords]="entitiesCount"
                [rowsPerPageOptions]="[10, 20, 30, 50, 100]" [showCurrentPageReport]="true"
                [currentPageReportTemplate]="translateService.instant('table.paginator.template')">
                <ng-template pTemplate="caption">
                    <div class="flex justify-content-between align-items-center">
                        <div>
                            <button pButton pRipple icon="pi pi-plus"
                                class="p-button-outlined p-button-rounded p-button-success mr-2" (click)="newEntity()">
                                <span class="hidden md:block ml-2"><b>{{ translateService.instant('app.new')
                                        }}</b></span>
                            </button>
                            <button pButton pRipple icon="pi pi-trash"
                                class="p-button-outlined p-button-rounded p-button-danger mr-2"
                                (click)="deleteSelectedEntities()"
                                [disabled]="!selectedEntities || !selectedEntities.length">
                                <span class="hidden md:block ml-2"><b>{{ translateService.instant('app.delete')
                                        }}</b></span>
                            </button>
                            <button pButton pRipple icon="pi pi-file-excel"
                                class="p-button-outlined p-button-rounded p-button-success mr-2"
                                (click)="exportExcel(dataTable.filters, dataTable.sortField, dataTable.sortOrder)">
                                <span class="hidden md:block ml-2"><b>{{ translateService.instant('app.export')
                                        }}</b></span>
                            </button>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th style="width: 3rem">
                            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                        </th>
                        <th pSortableColumn="part_number">
                            <div class="flex justify-content-between align-items-center">
                                <div>
                                    {{ 'report-template.table.part_number' | translate }}
                                    <p-sortIcon field="part_number"></p-sortIcon>
                                </div>
                                <p-columnFilter type="text" field="part_number" display="menu"
                                    placeholder="{{ 'table.filter.textPlaceholder' | translate }}"></p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="version">
                            <div class="flex justify-content-between align-items-center">
                                <div>
                                    {{ 'report-template.table.version' | translate }}
                                    <p-sortIcon field="version"></p-sortIcon>
                                </div>
                                <p-columnFilter type="text" field="version" display="menu"
                                    placeholder="{{ 'table.filter.textPlaceholder' | translate }}"></p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="state">
                            <div class="flex justify-content-between align-items-center">
                                <div>
                                    {{ 'report-template.table.state' | translate }}
                                    <p-sortIcon field="state"></p-sortIcon>
                                </div>
                                <p-columnFilter field="state" matchMode="equals" display="menu"
                                    [showMatchModes]="false">
                                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                        <p-dropdown [ngModel]="value" [options]="statuses"
                                            (onChange)="filter($event.value)" placeholder="Any">
                                            <ng-template let-option pTemplate="item">
                                                <p-tag [value]="option.label"
                                                    [severity]="getSeverity(option.label)"></p-tag>
                                            </ng-template>
                                        </p-dropdown>
                                    </ng-template>
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="created_at">
                            <div class="flex justify-content-between align-items-center">
                                <div>
                                    {{ 'report-template.table.created_at' | translate }}
                                    <p-sortIcon field="created_at"></p-sortIcon>
                                </div>
                                <p-columnFilter type="date" field="created_at" display="menu"
                                    placeholder="{{ 'table.filter.textPlaceholder' | translate }}"></p-columnFilter>
                            </div>
                        </th>
                        <th></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-entity>
                    <tr>
                        <td>
                            <p-tableCheckbox [value]="entity"></p-tableCheckbox>
                        </td>
                        <td>
                            {{entity.part_number}}
                        </td>
                        <td>
                            {{entity.version}}
                        </td>
                        <td>
                            <p-tag [value]="'app.state.' + entity.state | translate"
                                [severity]="getSeverity(entity.state)"></p-tag>
                        </td>
                        <td>
                            {{ entity.created_at || '' | date: 'MM/dd/yyyy HH:mm:ss'}}
                        </td>
                        <td>
                            <div class="flex justify-content-end">
                                <button pButton pRipple icon="pi pi-clone" title="{{ 'app.buttonTitle.clone' | translate }}"
                                    class="p-button-outlined p-button-rounded p-button-info mr-2"
                                    (click)="showCloneDialog(entity)"></button>
                                <button pButton pRipple icon="pi pi-pencil" title="{{ 'app.buttonTitle.edit' | translate }}"
                                    class="p-button-outlined p-button-rounded p-button-success mr-2"
                                    (click)="editEntity(entity)"></button>
                                <button pButton pRipple icon="pi pi-trash" title="{{ 'app.buttonTitle.delete' | translate }}"
                                    class="p-button-outlined p-button-rounded p-button-warning"
                                    (click)="deleteEntity(entity)"></button>
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>

<p-confirmDialog></p-confirmDialog>

<!-- REPORT TEMPLATE DIALOG -->
<p-dialog *ngIf="selectedEntity"
    [header]="translateService.instant('report-template.details.header').replace('{guide.code}', selectedEntity.part_number ?? '')"
    [(visible)]="entityDialogVisible" [breakpoints]="{ '960px': '75vw' }" class="details-dialog" [draggable]="false"
    [resizable]="false" (onHide)="onHideEditEntityDialog()">
    <p-tabView>
        <p-tabPanel [header]="translateService.instant('report-template.details.tab_details')">
            <div class="mt-5 mb-5" *ngIf="selectedEntity.state">
                <p-toolbar>
                    <div class="p-toolbar-group-start">
                        <div class="flex flex-column">
                            <div class="flex gap-1">
                                <p-tag [value]="'app.state.' + selectedEntity.state | translate"
                                    [severity]="getSeverity(selectedEntity.state)">
                                </p-tag>
                                <p-tag value="{{selectedEntity.updated_at | date: 'MM/dd/yyyy HH:mm:ss'}}"
                                    [severity]="getSeverity(selectedEntity.state)">
                                </p-tag>
                            </div>
                            <div class="flex align-items-center mt-2">
                                <p-avatar [label]="selectedEntity.owner.name[0].toUpperCase()" styleClass="mr-2"
                                    shape="circle"></p-avatar>
                                <span>{{selectedEntity.owner.name}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="p-toolbar-group-end">
                        <div class="flex gap-2">
                            <p-button label="Publish" icon="pi pi-thumbs-up" severity="success"
                                *ngIf="selectedEntity.state == 'draft'" (click)="publish(selectedEntity)"></p-button>
                            <p-button label="Unpublish" icon="pi pi-undo" severity="secondary"
                                *ngIf="selectedEntity.state == 'published'"
                                (click)="unpublish(selectedEntity)"></p-button>
                            <p-button label="Archive" icon="pi pi-inbox" severity="warning"
                                *ngIf="selectedEntity.state == 'published'"
                                (click)="archive(selectedEntity)"></p-button>
                            <p-button label="Unarchive" icon="pi pi-undo" severity="secondary"
                                *ngIf="selectedEntity.state == 'archived'"
                                (click)="unarchive(selectedEntity)"></p-button>
                        </div>
                    </div>
                </p-toolbar>
            </div>

            <form [formGroup]="entityForm">
                <div class="grid p-fluid">
                    <div class="col-12 md:col-6">
                        <div class="field">
                            <label [ngClass]="{'text-red-500': isInvalid(entityForm, 'part_number')}"
                                for="part_number">{{
                                'report-template.table.part_number' | translate }} *</label>
                            <input type="text" pInputText id="part_number"
                                [ngClass]="{'ng-invalid ng-dirty' : isInvalid(entityForm, 'part_number')}"
                                formControlName="part_number" placeholder="CLIENT#REFUID#XXXXXX"/>
                            <small class="ng-dirty ng-invalid" *ngIf="isInvalid(entityForm, 'part_number') && entityForm.get('part_number').hasError('required')">{{
                                translateService.instant('app.isRequired').replace('{field}',
                                translateService.instant('measurement-guide.table.part_number')) }}</small>
                            <small class="ng-dirty ng-invalid" *ngIf="isInvalid(entityForm, 'part_number') && entityForm.get('part_number').hasError('pattern')">
                                {{ translateService.instant('app.incorrectPattern').replace('{field}',
                                translateService.instant('measurement-guide.table.part_number')).replace('{pattern}', "CLIENT#REFUID#XXXXXX") }}</small>
                        </div>
                    </div>
                    <div class="col-12 md:col-6">
                        <div class="field">
                            <label [ngClass]="{'text-red-500': isInvalid(entityForm, 'version')}" for="version">{{
                                'report-template.table.version' | translate }} *</label>
                            <input type="text" pInputText id="version"
                                [ngClass]="{'ng-invalid ng-dirty' : isInvalid(entityForm, 'version')}"
                                formControlName="version" />
                            <small class="ng-dirty ng-invalid" *ngIf="isInvalid(entityForm, 'version')">{{
                                translateService.instant('app.isRequired').replace('{field}',
                                translateService.instant('report-template.table.version')) }}</small>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="field">
                            <label [ngClass]="{'text-red-500': isInvalid(entityForm, 'content')}" for="content">
                                {{'report-template.table.content' | translate }} *
                            </label>
                            <button pButton pRipple icon="pi pi-info-circle" class="p-button-text"
                                (click)="infoDialogVisible = true"></button>
                            <ngx-monaco-editor id="content" formControlName="content"
                                [options]="editorOptions"></ngx-monaco-editor>
                            <small class="ng-dirty ng-invalid" *ngIf="isInvalid(entityForm, 'content')">{{
                                translateService.instant('app.isRequired').replace('{field}',
                                translateService.instant('report-template.table.content')) }}
                            </small>
                        </div>
                    </div>
                </div>
            </form>
            <ng-template pTemplate="footer">
                <div class="flex justify-content-end align-items-center">
                    <div *ngIf="!selectedEntity.state || selectedEntity.state === 'draft'">
                        <button pButton pRipple [label]="translateService.instant('app.cancel')" icon="pi pi-times"
                            class="p-button-text" (click)="entityDialogVisible = false"></button>
                        <button pButton pRipple [label]="translateService.instant('app.save')" icon="pi pi-check"
                            class="p-button-text" (click)="saveEntity()"></button>
                    </div>
                </div>
            </ng-template>
        </p-tabPanel>
    </p-tabView>
</p-dialog>

<!-- CLONE DIALOG -->
<p-dialog *ngIf="selectedEntity" [header]="this.translateService.instant('app.cloneDialog.header')"
    [(visible)]="cloneEntityDialogVisible" [modal]="true" [draggable]="false" [resizable]="false"
    [breakpoints]="{ '960px': '75vw' }">
    <div class="flex flex-column ">
        <label for="version">{{ this.translateService.instant('app.cloneDialog.label').replace('{field}',
            translateService.instant('report-template.table.version')) }}</label>
        <input type="text" pInputText id="version" [(ngModel)]="newVersion" />
    </div>
    <ng-template pTemplate="footer">
        <div class="flex justify-content-end align-items-center">
            <div>
                <button pButton pRipple [label]="translateService.instant('app.save')" icon="pi pi-check"
                    [disabled]="newVersion === ''" class="p-button-text" (click)="cloneEntity()"></button>
            </div>
        </div>
    </ng-template>
</p-dialog>

<!-- INFO DIALOG -->
<p-dialog *ngIf="selectedEntity" class="info-template-dialog"
    [header]="this.translateService.instant('app.infoDialog.header')" [(visible)]="infoDialogVisible" [modal]="true"
    [draggable]="false" [resizable]="false" [breakpoints]="{ '960px': '75vw' }">
    <p-messages severity="info">
        <ng-template pTemplate>
            <div>Handlebars is the templating engine we are using, that simplifies the process of rendering dynamic
                content in web applications.
                If you're unfamiliar with Handlebars, you can explore its <a
                    href="https://handlebarsjs.com/guide/#what-is-handlebars" target="_blank"
                    id="handlebarsDoc">documentation</a>.
            </div>
        </ng-template>
    </p-messages>
    <p-messages severity="warn">
        <ng-template pTemplate>
            <div>The session is the root object from which all the others start. For example, if you want to obtain the
                information of a measurement guide, you have to do it through the session.
            </div>
        </ng-template>
    </p-messages>
    <div class="flex flex-column mt-2">
        <p-accordion activeIndex="0" [multiple]="true">
            <p-accordionTab id="session-info" header="Session">
                <p-table [value]="[[]]">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>Key</th>
                            <th>Value</th>
                            <th>Information</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body">
                        <tr>
                            <td>code</td>
                            <td>string</td>
                            <td>The code of the session.</td>
                        </tr>
                        <tr>
                            <td>description</td>
                            <td>string</td>
                            <td>The description of the session.</td>
                        </tr>
                        <tr>
                            <td>device_setting</td>
                            <td><a href="#device-setting-info">Device setting object</a></td>
                            <td>The device setting used by the session.</td>
                        </tr>
                        <tr>
                            <td>measuring_guide</td>
                            <td><a href="#measuring-guide-info">Measuring guide object</a></td>
                            <td>The measuring guide related to the session. </td>
                        </tr>
                        <tr>
                            <td>measuring_guide_read</td>
                            <td>boolean</td>
                            <td>Variable that indicates when a measurement guide has measurements in all of his steps.
                            </td>
                        </tr>
                        <tr>
                            <td>part_number</td>
                            <td>string</td>
                            <td>The part number related to the session.</td>
                        </tr>
                        <tr>
                            <td>recomendation_guide_read</td>
                            <td>boolean</td>
                            <td>Variable that indicates when a recommendation guide has been read and acepted.</td>
                        </tr>
                        <tr>
                            <td>recommendation_guide</td>
                            <td><a href="#recommendation-guide-info">Recomendation guide object</a></td>
                            <td>The recomendation guide relationed with the session. </td>
                        </tr>
                        <tr>
                            <td>state</td>
                            <td>enum</td>
                            <td>The state of the session. Possible values: open, closed. </td>
                        </tr>
                    </ng-template>
                </p-table>
            </p-accordionTab>
            <p-accordionTab id="user-info" header="User">
                <p-table [value]="[[]]">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>Key</th>
                            <th>Value</th>
                            <th>Information</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body">
                        <tr>
                            <td>name</td>
                            <td>string</td>
                            <td>The name of the user.</td>
                        </tr>
                        <tr>
                            <td>email</td>
                            <td>string</td>
                            <td>The email of the user.</td>
                        </tr>
                    </ng-template>
                </p-table>
            </p-accordionTab>
            <p-accordionTab id="device-setting-info" header="Device Setting">
                <p-table [value]="[[]]">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>Key</th>
                            <th>Value</th>
                            <th>Information</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body">
                        <tr>
                            <td>distance</td>
                            <td>float</td>
                            <td>Geometry correction factor (distance) associated with the device.</td>
                        </tr>
                        <tr>
                            <td>name</td>
                            <td>string</td>
                            <td>The name of the device.</td>
                        </tr>
                    </ng-template>
                </p-table>
            </p-accordionTab>
            <p-accordionTab id="measuring-guide-info" header="Measuring Guide">
                <p-table [value]="[[]]">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>Key</th>
                            <th>Value</th>
                            <th>Information</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body">
                        <tr>
                            <td>content</td>
                            <td>string</td>
                            <td>The content of the measuring guide. </td>
                        </tr>
                        <tr>
                            <td>part_number</td>
                            <td>string</td>
                            <td>The part number related to the session.</td>
                        </tr>
                        <tr>
                            <td>published_at</td>
                            <td>date</td>
                            <td>The date the measuring guide was published.</td>
                        </tr>
                        <tr>
                            <td>state</td>
                            <td>enum</td>
                            <td>The state of the measuring guide. Possible values: draft, archived, published. </td>
                        </tr>
                        <tr>
                            <td>steps</td>
                            <td><a href="#steps-info">Measuring guide steps array object</a></td>
                            <td>The state of the measuring guide. Possible values: draft, archived, published. </td>
                        </tr>
                        <tr>
                            <td>version</td>
                            <td>string</td>
                            <td>The version of the measuring guide.</td>
                        </tr>
                    </ng-template>
                </p-table>
            </p-accordionTab>
            <p-accordionTab id="recommendation-guide-info" header="Recommendation Guide">
                <p-table [value]="[[]]">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>Key</th>
                            <th>Value</th>
                            <th>Information</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body">
                        <tr>
                            <td>content</td>
                            <td>string</td>
                            <td>The content of the recommendation guide. </td>
                        </tr>
                        <tr>
                            <td>part_number</td>
                            <td>string</td>
                            <td>The part number related to the session.</td>
                        </tr>
                        <tr>
                            <td>published_at</td>
                            <td>date</td>
                            <td>The date the recommendation guide was published. </td>
                        </tr>
                        <tr>
                            <td>state</td>
                            <td>enum</td>
                            <td>The state of the recommendation guide. Possible values: draft, archived, published.
                            </td>
                        </tr>
                        <tr>
                            <td>version</td>
                            <td>string</td>
                            <td>The version of the recommendation guide. </td>
                        </tr>
                    </ng-template>
                </p-table>
            </p-accordionTab>
            <p-accordionTab id="steps-info" header="Steps">
                <p-table [value]="[[]]">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>Key</th>
                            <th>Value</th>
                            <th>Information</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body">
                        <tr>
                            <td>images</td>
                            <td>array of images</td>
                            <td>One or more images of the step.</td>
                        </tr>
                        <tr>
                            <td>instructions</td>
                            <td>string</td>
                            <td>The instructions of each step. </td>
                        </tr>
                        <tr>
                            <td>instrument_id</td>
                            <td>string</td>
                            <td>The instrument of each step. </td>
                        </tr>
                        <tr>
                            <td>measurement</td>
                            <td><a href="#measurement-info">Measurements object</a></td>
                            <td>Measurement of the step. </td>
                        </tr>
                        <tr>
                            <td>reference_point</td>
                            <td>string</td>
                            <td>The reference point of the step. Format: (x,y,z). </td>
                        </tr>
                        <tr>
                            <td>step_code</td>
                            <td>string</td>
                            <td>The code of the step.</td>
                        </tr>
                    </ng-template>
                </p-table>
            </p-accordionTab>
            <p-accordionTab id="measurement-info" header="Measurement">
                <p-table [value]="[[]]">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>Key</th>
                            <th>Value</th>
                            <th>Information</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body">
                        <tr>
                            <td>images</td>
                            <td>Images array</td>
                            <td>The image of the measurement.</td>
                        </tr>
                        <tr>
                            <td>point</td>
                            <td>string</td>
                            <td>The measured point. Format: (x,y,z). </td>
                        </tr>
                    </ng-template>
                </p-table>
            </p-accordionTab>
        </p-accordion>
    </div>
    <ng-template pTemplate="footer">
        <div class="flex justify-content-end align-items-center">
            <div>
                <button pButton pRipple [label]="translateService.instant('app.ok')" icon="pi pi-check"
                    class="p-button-text" (click)="infoDialogVisible = false"></button>
            </div>
        </div>
    </ng-template>
</p-dialog>