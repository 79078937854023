import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Injectable, effect, inject } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Observable, catchError, of, throwError } from "rxjs";
import { AuthState } from "src/app/shared/auth/auth.state";
import { NotificationsService } from "src/app/shared/services/notifications.service";
import { PrimeFilteringService } from "src/app/shared/services/prime-filtering.service";
import { environment } from "src/environments/environment";
@Injectable({ providedIn: 'root' })
export class MeasurementsService {
  private readonly modelAPI = environment.baseApiUrl + 'sessions';
  private readonly defaultOptions = {
    headers: new HttpHeaders({}) 
  };

  private authState = inject(AuthState);
  private notificationsService = inject(NotificationsService);
  private translateService = inject(TranslateService);
  private primeFilteringService = inject(PrimeFilteringService);


  constructor(private httpClient: HttpClient) {
    effect(() => {
        this.defaultOptions.headers = this.defaultOptions.headers.set(
            'Authorization',
            `Bearer ${ this.authState.token() }`
        );
    })

    if (this.authState.isAuthenticated()) {
        this.defaultOptions.headers = this.defaultOptions.headers.set(
            'Authorization',
            `Bearer ${ this.authState.token() }`
        );
    }
  }

  getAll(session_id, payload): Observable<object[]> {    
    let url = new URL(`${ this.modelAPI }/${ session_id }/measurements`);

    if (payload.pageSize) {
        url.searchParams.set('per_page', payload.pageSize);
        url.searchParams.set('page', payload.pageNumber);
    }

    if (payload.filter) {
        let filters = this.primeFilteringService.build(payload.filter);
        Object.entries(filters).forEach(([ key, value ]) => url.searchParams.set(key, value as string));
    }

    if (payload.order) {
        url.searchParams.set('sort', payload.order.direction + payload.order.field);
    }

    return this.httpClient.get<object[]>(url.toString(),this.defaultOptions)
    .pipe(
        catchError(() => {
          this.notificationsService.error(this.translateService.instant('measurement-guide.steps.messages.show_error'));
          return of();
        })
    );
  }
}