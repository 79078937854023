import { Injectable, computed, signal } from "@angular/core";

type User = {
    email: string,
    password: string,
};

type State = {
    loading: boolean,
    user: User,
};

const InitialState = {
    loading: false,
    user: {
        email: '',
        password: '',
    }
};

@Injectable({ providedIn: 'root' })
export class LoginState {
    // state
    private state = signal<State>(InitialState);

    // reducers
    readonly loading = computed(() => this.state().loading);
    readonly user = computed(() => this.state().user);


    // Effects
    setUser(user: Partial<User>): void {
        this.state.mutate((state) => state.user = { ...state.user, ...user })
    }

    setLoading(loading: boolean): void {
        this.state.update((state) => ({ ...state, loading }))
    }
}