import { Component, Input, ViewChild, inject, signal } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { Table } from 'primeng/table';
import { FileUpload } from 'primeng/fileupload';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { getSeverity } from 'src/app/shared/functions';
import { NotificationsService } from 'src/app/shared/services/notifications.service';
import { MeasurementGuidesService } from './measurement-guides.service';
import { FileSaverService } from 'src/app/shared/services/file-saver.service';
import { MeasurementGuideStepsService } from './measurement-guide-steps.service';
import { DeviceService } from '../device-list/device.service';

@Component({
  selector: 'app-measurement-guide-list',
  templateUrl: './measurement-guide-list.component.html',
  styleUrls: [ './measurement-guide-list.component.scss' ],
  providers: [ ConfirmationService ]
})
export class MeasurementGuideListComponent {

  @ViewChild("dataTable") dataTable: Table;
  @ViewChild("stepsDataTable") stepsDataTable: Table;
  @ViewChild("imageUploader") imageUploader: FileUpload;
  @Input() lazyLoadOnInit: boolean = true;

  notificationsService = inject(NotificationsService);

  entities = signal([]);
  steps = signal([]);
  skills: object[];
  groups: object[];
  entitiesCount: number = 0;
  stepsCount: number = 0;
  selectedEntities: object[] = [];
  selectedSteps: object[] = [];
  selectedEntity: object;
  selectedStep: object;
  devices: object[] = [];
  first: number = 0;
  rows: number = environment.defaultPageSize;
  entityDialogVisible: boolean = false;
  editorReadonly: boolean = false;
  cloneEntityDialogVisible: boolean = false;
  stepDialogVisible: boolean = false;
  newVersion: string = '';
  activeTab: number = 0;
  images: any[] = [];
  galleryIndex: number = 0;
  displayGalleriaFullscreen: boolean = false;

  statuses = [
    { label: 'Draft', value: 'draft' },
    { label: 'Published', value: 'published' },
    { label: 'Archived', value: 'archived' },
  ];

  entityForm = new FormGroup({
    part_number: new FormControl('', [ Validators.required, Validators.pattern(/^[\w-]+#[\w-]+#[\w-]+$/) ]),
    version: new FormControl('', [ Validators.required ]),
    content: new FormControl('', [ Validators.required ]),
    model_url: new FormControl('', [])
  });

  stepForm = new FormGroup({
    step_code: new FormControl('', [ Validators.required ]),
    instrument_id: new FormControl('', [ Validators.required ]),
    coordinate_x: new FormControl('', [ Validators.required ]),
    coordinate_y: new FormControl('', [ Validators.required ]),
    coordinate_z: new FormControl('', [ Validators.required ]),
    created_at: new FormControl({ value: '', disabled: true }, []),
    instructions: new FormControl('', [ Validators.required ]),
  });

  constructor(
    public measurementGuideService: MeasurementGuidesService,
    public measurementGuideStepsService: MeasurementGuideStepsService,
    public deviceService: DeviceService,
    private confirmationService: ConfirmationService,
    public translateService: TranslateService,
    public fileService: FileSaverService
  ) { }

  onLazyLoad(event) {
    this.first = event.first;
    this.rows = event.rows;
    this.fetchEntities(event.filters, event.sortField, event.sortOrder, (this.first / this.rows) + 1, this.rows);
  }

  private fetchEntities(filters: object, sortField: string, sortOrder: number, pageNumber: number, pageSize: number) {
    this.measurementGuideService.getAll({
      filter: { ...filters },
      order: {
        field: sortField,
        direction: sortOrder !== 1 ? "-" : ""
      },
      pageNumber: pageNumber,
      pageSize: pageSize
    }).subscribe((response) => {
      this.entities.set(response[ 'data' ]);
      this.entitiesCount = response[ 'total' ];
      this.selectedEntities = [];
    });
  }

  onLazyLoadSteps(event) {
    this.first = event.first;
    this.rows = event.rows;
    this.fetchSteps(event.filters, event.sortField, event.sortOrder, (this.first / this.rows) + 1, this.rows);
  }

  private fetchSteps(filters: object, sortField: string, sortOrder: number, pageNumber: number, pageSize: number) {
    this.measurementGuideStepsService.getAll(this.selectedEntity, {
      filter: { ...filters },
      order: {
        field: sortField,
        direction: sortOrder !== 1 ? "-" : ""
      },
      pageNumber: pageNumber,
      pageSize: pageSize
    }).subscribe((response) => {
      this.steps.set(response[ 'data' ]);
      this.stepsCount = response[ 'total' ];
      this.selectedSteps = [];
    });
  }

  fetchDevices(filters: object, sortField: string, sortOrder: number, pageNumber: number, pageSize: number) {
    this.deviceService.getAll({
      filter: { ...filters },
      order: {
        field: sortField,
        direction: sortOrder !== 1 ? "-" : ""
      },
      pageNumber: pageNumber,
      pageSize: pageSize
    })
      .subscribe((response) => {
        this.devices = response[ 'data' ];
      });
  }

  private hydrateEntityForm(entity: object) {
    this.entityForm.get('part_number').setValue(entity[ 'part_number' ]);
    this.entityForm.get('version').setValue(entity[ 'version' ]);
    this.entityForm.get('content').setValue(entity[ 'content' ]);
    this.entityForm.get('model_url').setValue(entity[ 'model_url' ]);
  }

  private hydrateStepForm(step: object) {
    this.stepForm.get('step_code').setValue(step[ 'step_code' ]);
    this.stepForm.get('instrument_id').setValue(step[ 'instrument_id' ]);
    this.stepForm.get('created_at').setValue(this.formatDate(step[ 'created_at' ]));
    this.stepForm.get('coordinate_x').setValue(step[ 'coordinate_x' ]);
    this.stepForm.get('coordinate_y').setValue(step[ 'coordinate_y' ]);
    this.stepForm.get('coordinate_z').setValue(step[ 'coordinate_z' ]);
    this.stepForm.get('instructions').setValue(step[ 'instructions' ]);
  }

  newEntity() {
    this.activeTab = 0;
    this.entityForm.controls[ 'part_number' ].enable();
    this.entityForm.controls[ 'version' ].enable();
    this.entityForm.controls[ 'model_url' ].enable();
    this.editorReadonly = false;
    this.selectedEntity = {};
    this.resetFormValidation(this.entityForm);
    this.entityDialogVisible = true;
  }

  editEntity(entity: object) {
    this.activeTab = 0;
    this.selectedEntity = { ...entity };

    if (this.selectedEntity[ 'state' ] !== "draft") {
      this.entityForm.controls[ 'part_number' ].disable();
      this.entityForm.controls[ 'version' ].disable();
      this.entityForm.controls[ 'model_url' ].disable();
      this.editorReadonly = true;
    } else {
      this.entityForm.controls[ 'part_number' ].enable();
      this.entityForm.controls[ 'version' ].enable();
      this.entityForm.controls[ 'model_url' ].enable();
      this.editorReadonly = false;
    }

    this.entityDialogVisible = true;
    this.fetchSteps({}, "step_code", -1, 1, environment.defaultPageSize);
    this.hydrateEntityForm(this.selectedEntity);
  }

  saveEntity() {
    if (this.checkFormValidity(this.entityForm)) {
      const guideId = this.selectedEntity[ 'id' ];
      const guideData = this.entityForm.value;

      if (guideId) {
        this.measurementGuideService.update(guideId, guideData).subscribe(
          () => {
            this.fetchEntities(this.dataTable.filters, this.dataTable.sortField, this.dataTable.sortOrder, (this.dataTable.first / this.dataTable.rows) + 1, this.dataTable.rows);
            this.notificationsService.success(this.translateService.instant('measurement-guide.messages.measurement_guide_updated'));
            this.entityDialogVisible = false;
          }
        );
      } else {
        this.measurementGuideService.create(guideData).subscribe(
          () => {
            this.fetchEntities(this.dataTable.filters, this.dataTable.sortField, this.dataTable.sortOrder,
              (this.dataTable.first / this.dataTable.rows) + 1, this.dataTable.rows);
            this.notificationsService.success(this.translateService.instant('measurement-guide.messages.measurement_guide_created'));
            this.entityDialogVisible = false;
          }
        );
      }
    }
  }

  deleteEntity(entity: object) {
    this.confirmationService.confirm({
      header: this.translateService.instant('app.deleteDialog.header'),
      message: this.translateService.instant('app.deleteDialog.message'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.measurementGuideService.delete(entity).subscribe(
          () => {
            this.fetchEntities(this.dataTable.filters, this.dataTable.sortField, this.dataTable.sortOrder,
              (this.dataTable.first / this.dataTable.rows) + 1, this.dataTable.rows);
            this.notificationsService.success(this.translateService.instant('measurement-guide.messages.measurement_guide_deleted'));
          }
        );
      }
    });
  }

  deleteSelectedEntities() {
    if (this.selectedEntities.length === 0) {
      this.notificationsService.error(this.translateService.instant('app.messages.no_entities_selected'));
      return;
    }

    this.confirmationService.confirm({
      header: this.translateService.instant('app.deleteSelectedDialog.header'),
      message: this.translateService.instant('app.deleteSelectedDialog.message'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        for (var key in this.selectedEntities) {
          this.measurementGuideService.delete(this.selectedEntities[ key ]).subscribe();
        }
        this.fetchEntities(this.dataTable.filters, this.dataTable.sortField, this.dataTable.sortOrder,
          (this.dataTable.first / this.dataTable.rows) + 1, this.dataTable.rows);
        this.notificationsService.success(this.translateService.instant('measurement-guide.messages.measurement_guides_deleted'));
      }
    });
  }

  showCloneDialog(entity: object) {
    this.selectedEntity = { ...entity };
    this.newVersion = '';
    this.cloneEntityDialogVisible = true;
  }

  cloneEntity() {
    this.measurementGuideService.clone(this.selectedEntity[ 'id' ], this.newVersion).subscribe(
      () => {
        this.fetchEntities(this.dataTable.filters, this.dataTable.sortField, this.dataTable.sortOrder,
          (this.dataTable.first / this.dataTable.rows) + 1, this.dataTable.rows);
        this.notificationsService.success(this.translateService.instant('measurement-guide.messages.measurement_guide_cloned'));
      }
    );
    this.cloneEntityDialogVisible = false;
    this.newVersion = '';
  }

  newStep() {
    this.selectedStep = {};
    this.fetchDevices({}, "name", -1, 1, environment.defaultPageSize);
    this.resetFormValidation(this.stepForm);
    this.stepDialogVisible = true;
    this.fetchDevices({}, "name", -1, 1, null);
  }

  editStep(step: object) {
    this.selectedStep = { ...step };
    this.images = step[ 'media' ];
    this.stepDialogVisible = true;
    this.fetchDevices({}, "name", -1, 1, null);
    this.hydrateStepForm(this.selectedStep);
  }

  saveStep() {
    if (this.checkFormValidity(this.stepForm)) {
      if (this.selectedStep[ 'id' ]) {
        this.measurementGuideStepsService.update(this.selectedEntity, this.selectedStep, this.stepForm.value).subscribe(
          () => {
            this.fetchSteps(this.stepsDataTable.filters, this.stepsDataTable.sortField, this.stepsDataTable.sortOrder, (this.stepsDataTable.first / this.stepsDataTable.rows) + 1, this.stepsDataTable.rows);
            this.notificationsService.success(this.translateService.instant('measurement-guide.steps.messages.step_updated'));
            this.stepDialogVisible = false;
          }
        );
      } else {
        this.measurementGuideStepsService.create(this.selectedEntity, this.stepForm.value).subscribe(
          () => {
            this.fetchSteps(this.stepsDataTable.filters, this.stepsDataTable.sortField, this.stepsDataTable.sortOrder,
              (this.stepsDataTable.first / this.stepsDataTable.rows) + 1, this.stepsDataTable.rows);
            this.notificationsService.success(this.translateService.instant('measurement-guide.steps.messages.step_created'));
            this.stepDialogVisible = false;
          }
        );
      }
    }
  }

  deleteStep(step: object) {
    this.confirmationService.confirm({
      header: this.translateService.instant('app.deleteDialog.header'),
      message: this.translateService.instant('app.deleteDialog.message'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.measurementGuideStepsService.delete(this.selectedEntity, step).subscribe(
          () => {
            this.fetchSteps(this.stepsDataTable.filters, this.stepsDataTable.sortField, this.stepsDataTable.sortOrder,
              (this.stepsDataTable.first / this.stepsDataTable.rows) + 1, this.stepsDataTable.rows);
            this.notificationsService.success(this.translateService.instant('measurement-guide.steps.messages.step_deleted'));
          }
        );
      }
    });
  }

  deleteSelectedSteps() {
    if (this.selectedSteps.length === 0) {
      this.notificationsService.error(this.translateService.instant('app.messages.no_entities_selected'));
      return;
    }

    this.confirmationService.confirm({
      header: this.translateService.instant('app.deleteSelectedDialog.header'),
      message: this.translateService.instant('app.deleteSelectedDialog.message'),
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        for (var key in this.selectedSteps) {
          this.measurementGuideStepsService.delete(this.selectedEntity, this.selectedSteps[ key ]).subscribe();
        }
        this.fetchSteps(this.stepsDataTable.filters, this.stepsDataTable.sortField, this.stepsDataTable.sortOrder,
          (this.stepsDataTable.first / this.stepsDataTable.rows) + 1, this.stepsDataTable.rows);
        this.notificationsService.success(this.translateService.instant('measurement-guide.steps.messages.steps_deleted'));
      }
    });
  }

  isInvalid(form: FormGroup, field: string): boolean {
    return form && form.get(field).invalid && (form.get(field).dirty || form.get(field).touched);
  }

  checkFormValidity(form: FormGroup): boolean {
    form.markAllAsTouched();
    return form.valid;
  }

  resetFormValidation(form: FormGroup) {
    form.reset();
  }

  getSeverity(status: string) {
    return getSeverity(status);
  }

  formatDate(date): string {
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric', month: '2-digit', day: '2-digit',
      hour: '2-digit', minute: '2-digit', second: '2-digit',
      hour12: false
    };

    const dateFormatter = new Intl.DateTimeFormat('en-US', options);
    return dateFormatter.format(new Date(date));
  }

  formatPoint(step): string {
    return `(${ step.coordinate_x }, ${ step.coordinate_y }, ${ step.coordinate_z })`
  }

  exportExcel(filters: object, sortField: string, sortOrder: number, entitiesToExport: object[], type: string) {
    if (!entitiesToExport || entitiesToExport.length == 0) {
      this.notificationsService.error(this.translateService.instant('app.messages.no_entities_selected'));
    } else {
      this.saveExcel(entitiesToExport, type);
    }

    this.selectedEntities = [];
    this.selectedSteps = [];
  }

  saveExcel(entities: object[], type: string) {
    let data = [];
    for (let device of entities) {
      let item = { ...device }
      data.push(item);
    }

    this.fileService.saveExcel(data, type);
  }

  onUpload(event) {
    const formData: FormData = new FormData();
    for (let file of event.files) {
      formData.append('images[]', file, file.name);
    }

    this.measurementGuideStepsService.addImage(this.selectedEntity, this.selectedStep, formData).subscribe(
      () => {
        this.notificationsService.success(this.translateService.instant('measurement-guide.steps.messages.image_added'));
        this.measurementGuideStepsService.getOne(this.selectedEntity, this.selectedStep).subscribe(
          (response) => {
            this.images = response[ 'media' ];
          }
        )
      }
    );

    this.imageUploader.clear();
  }

  deleteImage() {
    this.measurementGuideStepsService.deleteImage(this.selectedEntity, this.selectedStep, this.images[ this.galleryIndex ]).subscribe(
      () => {
        this.notificationsService.success(this.translateService.instant('measurement-guide.steps.messages.image_deleted'));
        this.measurementGuideStepsService.getOne(this.selectedEntity, this.selectedStep).subscribe(
          (response) => {
            this.images = response[ 'media' ];
          }
        )
      }
    );
    this.galleryIndex = 0;
  }

  onHideEditStepDialog() {
    this.fetchSteps(this.stepsDataTable.filters, this.stepsDataTable.sortField, this.stepsDataTable.sortOrder,
      (this.stepsDataTable.first / this.stepsDataTable.rows) + 1, this.stepsDataTable.rows);
  }

  onHideEditEntityDialog() {
    this.fetchEntities(this.dataTable.filters, this.dataTable.sortField, this.dataTable.sortOrder,
      (this.dataTable.first / this.dataTable.rows) + 1, this.dataTable.rows);
  }

  publish(entity) {
    if (this.checkFormValidity(this.entityForm)) {
      const guideId = this.selectedEntity[ 'id' ];
      const guideData = this.entityForm.value;
      this.measurementGuideService.update(guideId, guideData).subscribe(
        (measurementGuideUpdated) => {
          this.measurementGuideService.publish(measurementGuideUpdated).subscribe(
            (measurementGuidePublished) => {
              this.notificationsService.success(this.translateService.instant('measurement-guide.messages.state_changed'));
              this.selectedEntity = measurementGuidePublished;
              this.hydrateEntityForm(this.selectedEntity);

              if (this.selectedEntity[ 'published_at' ]) {
                this.entityForm.controls[ 'part_number' ].disable();
                this.entityForm.controls[ 'version' ].disable();
                this.entityForm.controls[ 'model_url' ].disable();
                this.editorReadonly = true;
              }
            }
          );
          this.notificationsService.success(this.translateService.instant('measurement-guide.messages.measurement_guide_updated'));
        }
      );
    } else {
      this.measurementGuideService.publish(entity).subscribe(
        (measurementGuidePublished) => {
          this.notificationsService.success(this.translateService.instant('measurement-guide.messages.state_changed'));
          this.selectedEntity = measurementGuidePublished;
          this.hydrateEntityForm(this.selectedEntity);

          if (this.selectedEntity[ 'published_at' ]) {
            this.entityForm.controls[ 'part_number' ].disable();
            this.entityForm.controls[ 'version' ].disable();
            this.entityForm.controls[ 'model_url' ].disable();
            this.editorReadonly = true;
          }
        }
      );
    }
  }

  unpublish(entity) {
    this.measurementGuideService.unpublish(entity).subscribe(
      (response) => {
        this.notificationsService.success(this.translateService.instant('measurement-guide.messages.state_changed'));
        this.selectedEntity = response;
        this.hydrateEntityForm(this.selectedEntity);

        if (!this.selectedEntity[ 'published_at' ]) {
          this.entityForm.controls[ 'part_number' ].enable();
          this.entityForm.controls[ 'version' ].enable();
          this.entityForm.controls[ 'model_url' ].enable();
          this.editorReadonly = false;
        }
      }
    );
  }

  archive(entity) {
    this.measurementGuideService.archive(entity).subscribe(
      (response) => {
        this.notificationsService.success(this.translateService.instant('measurement-guide.messages.state_changed'));
        this.selectedEntity = response;
        this.hydrateEntityForm(this.selectedEntity);

        if (this.selectedEntity[ 'published_at' ]) {
          this.entityForm.controls[ 'part_number' ].disable();
          this.entityForm.controls[ 'version' ].disable();
          this.entityForm.controls[ 'model_url' ].disable();
          this.editorReadonly = true;
        }
      }
    );
  }

  unarchive(entity) {
    this.measurementGuideService.unarchive(entity).subscribe(
      (response) => {
        this.notificationsService.success(this.translateService.instant('measurement-guide.messages.state_changed'));
        this.selectedEntity = response;
        this.hydrateEntityForm(this.selectedEntity);

        if (!this.selectedEntity[ 'published_at' ]) {
          this.entityForm.controls[ 'part_number' ].enable();
          this.entityForm.controls[ 'version' ].enable();
          this.entityForm.controls[ 'model_url' ].enable();
          this.editorReadonly = false;
        }
      }
    );
  }
}