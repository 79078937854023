import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, effect, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthState } from 'src/app/shared/auth/auth.state';
import { NotificationsService } from 'src/app/shared/services/notifications.service';
import { PrimeFilteringService } from 'src/app/shared/services/prime-filtering.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {
    private readonly modelAPI = environment.baseApiUrl + 'device-settings';

    private readonly defaultOptions = {
        headers: new HttpHeaders({})
    };

    private authState = inject(AuthState);
    private notificationsService = inject(NotificationsService);
    private translateService = inject(TranslateService);
    private primeFilteringService = inject(PrimeFilteringService);


    constructor(private httpClient: HttpClient) {
        effect(() => {
            this.defaultOptions.headers = this.defaultOptions.headers.set(
                'Authorization',
                `Bearer ${ this.authState.token() }`
            );
        })

        if (this.authState.isAuthenticated()) {
            this.defaultOptions.headers = this.defaultOptions.headers.set(
                'Authorization',
                `Bearer ${ this.authState.token() }`
            );
        }
    }

    getAll(payload):  Observable<object[]> {
        let url = new URL(this.modelAPI);

        if (payload.pageSize) {
            url.searchParams.set('per_page', payload.pageSize);
            url.searchParams.set('page', payload.pageNumber);
        }

        if (payload.filter) {
            let filters = this.primeFilteringService.build(payload.filter);
            Object.entries(filters).forEach(([ key, value ]) => url.searchParams.set(key, value as string));
        }

        if (payload.order) {
            url.searchParams.set('sort', payload.order.direction + payload.order.field);
        }

        return this.httpClient.get<object[]>(url.toString(),this.defaultOptions)
        .pipe(
            catchError(() => {
              this.notificationsService.error(this.translateService.instant('device-list.messages.show_error'));
              return of();
            })
        );
    }

    create(newDeviceData: object):  Observable<object> {
        return this.httpClient.post<object>(`${this.modelAPI}`, newDeviceData, this.defaultOptions)
        .pipe(
            catchError(() => {
              this.notificationsService.error(this.translateService.instant('device-list.messages.create_error'));
              return of();
            })
        );
    }

    delete(deviceId: number): Observable<any> {
        return this.httpClient.delete<object>(`${this.modelAPI}/${deviceId}`,this.defaultOptions)
        .pipe(
            catchError(() => {
              this.notificationsService.error(this.translateService.instant('device-list.messages.delete_error'));
              return of();
            })
        );
    }

    update(deviceId: number, updatedDevice: object): Observable<object> {
        return this.httpClient.put<object>(`${this.modelAPI}/${deviceId}`, updatedDevice, this.defaultOptions).pipe(
            catchError(() => {
              this.notificationsService.error(this.translateService.instant('device-list.messages.update_error'));
              return of();
            })
        );
    }

    clone(deviceId: number, newName: string): Observable<object> {
      return this.httpClient.post<object>(`${this.modelAPI}/${deviceId}/clone`, {name: newName}, this.defaultOptions).pipe(
          catchError(() => {
            this.notificationsService.error(this.translateService.instant('device-list.messages.clone_error'));
            return of();
            })
        );
    }
}