import { Injectable, computed, inject, signal } from "@angular/core";
import { RecommendationGuide } from "./recomendation-guide.model";


type ListState = {
    loading: boolean,
    current_page: number,
    data: RecommendationGuide[],
    last_page: number,
    per_page: number,
    total: number
}


const InitialListState = {
    loading: false,
    current_page: 1,
    data: [],
    last_page: 1,
    per_page: 10,
    total: 0,
};

@Injectable({ providedIn: 'root' })
export class RecommendationGuideListState {
    private state = signal<ListState>(InitialListState);

    public loading = computed(() => this.state().loading);
    public current_page = computed(() => this.state().current_page);
    public data = computed(() => this.state().data);
    public last_page = computed(() => this.state().last_page);
    public per_page = computed(() => this.state().per_page);
    public total = computed(() => this.state().total);

    setData(state: ListState) {
        this.state.set(state);
    }

    remove(guide: RecommendationGuide) {
        this.state.mutate((state) => {
            state.data = state.data.filter((_guide) => _guide.id !== guide.id);
        })
    }

    update(guide: RecommendationGuide) {
        this.state.mutate((state) => {
            let index = state.data.findIndex((_guide) => _guide.id == guide.id)
            state.data[ index ] = { ...state.data[ index ], ...guide };
        })
    }

    clearData(): void {
        this.state.set(InitialListState);
    }

    setLoading(loading: boolean) {
        this.state.update((state) => ({ ...state, loading }));
    }
}


type EditState = {
    loading: boolean,
    visible: boolean,
    entity: RecommendationGuide | undefined,
}

const InitialEditState = {
    loading: false,
    visible: false,
    entity: undefined,
};

@Injectable({ providedIn: 'root' })
export class RecommendationGuideEditState {
    private state = signal<EditState>(InitialEditState);

    public loading = computed(() => this.state().loading);
    public visible = computed(() => this.state().visible);
    public entity = computed(() => this.state().entity);

    setEntity(entity: RecommendationGuide) {
        this.state.mutate((state) => state.entity = entity);
    }

    setLoading(loading: boolean) {
        this.state.update((state) => ({ ...state, loading }));
    }

    setVisible(visible: boolean) {
        this.state.update((state) => ({ ...state, visible }));
    }
}


type CreateState = {
    loading: boolean,
    visible: boolean,
    entity: RecommendationGuide | undefined,
}


const InitialCreateState = {
    loading: false,
    visible: false,
    entity: undefined,
};

@Injectable({ providedIn: 'root' })
export class RecommendationGuideCreateState {
    private state = signal<CreateState>(InitialCreateState);

    public loading = computed(() => this.state().loading);
    public visible = computed(() => this.state().visible);
    public entity = computed(() => this.state().entity);

    setEntity(entity: RecommendationGuide) {
        this.state.mutate((state) => state.entity = entity);
    }

    setLoading(loading: boolean) {
        this.state.update((state) => ({ ...state, loading }));
    }

    setVisible(visible: boolean) {
        this.state.update((state) => ({ ...state, visible }));
    }

    clear() {
        this.state.set(InitialCreateState);
    }
}

@Injectable({ providedIn: 'root' })
export class RecommendationGuidesState {
    public list = inject(RecommendationGuideListState);
    public edit = inject(RecommendationGuideEditState);
    public create = inject(RecommendationGuideCreateState);
}
