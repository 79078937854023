<div class="grid">
    <div class="col-12">
        <div class="card">
            <h5>{{ 'sessions.table.title' | translate }}</h5>
            <p-table #dataTable [value]="entities()" [scrollable]="true" scrollHeight="calc(100vh - 380px)"
                [rowHover]="true" styleClass="p-datatable-sm" [(selection)]="selectedEntities" responsiveLayout="scroll"
                lazy="true" [lazyLoadOnInit]="lazyLoadOnInit" (onLazyLoad)="onLazyLoad($event)" sortField="created_at"
                sortOrder="-1" paginator="true" [rows]="rows" [first]="first" [totalRecords]="entitiesCount"
                [rowsPerPageOptions]="[10, 20, 30, 50, 100]" [showCurrentPageReport]="true"
                [currentPageReportTemplate]="translateService.instant('table.paginator.template')">
                <ng-template pTemplate="caption">
                    <div class="flex justify-content-between flex-column sm:flex-row">
                        <div class="flex justify-content-between align-items-center">
                            <div>
                                <button pButton pRipple icon="pi pi-file-excel"
                                    class="p-button-outlined p-button-rounded p-button-success mr-2"
                                    (click)="exportExcel(dataTable.filters, dataTable.sortField, dataTable.sortOrder)">
                                    <span class="hidden md:block ml-2"><b>{{ translateService.instant('app.export')
                                            }}</b></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th style="width: 3rem">
                            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                        </th>
                        <th pSortableColumn="code">
                            <div class="flex justify-content-between align-items-center">
                                <div>
                                    {{ 'sessions.table.code' | translate }}
                                    <p-sortIcon field="code"></p-sortIcon>
                                </div>
                                <p-columnFilter type="text" field="code" display="menu"
                                    placeholder="{{ 'table.filter.textPlaceholder' | translate }}"></p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="part_number">
                            <div class="flex justify-content-between align-items-center">
                                <div>
                                    {{ 'sessions.table.part_number' | translate }}
                                    <p-sortIcon field="part_number"></p-sortIcon>
                                </div>
                                <p-columnFilter type="text" field="part_number" display="menu"
                                    placeholder="{{ 'table.filter.textPlaceholder' | translate }}"></p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="state">
                            <div class="flex justify-content-between align-items-center">
                                <div>
                                    {{ 'sessions.table.state' | translate }}
                                    <p-sortIcon field="state"></p-sortIcon>
                                </div>
                                <p-columnFilter field="state" matchMode="equals" display="menu"
                                    [showMatchModes]="false">
                                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                        <p-dropdown [ngModel]="value" [options]="statuses"
                                            (onChange)="filter($event.value)" placeholder="Any">
                                            <ng-template let-option pTemplate="item">
                                                <p-tag [value]="option.label"
                                                    [severity]="getSeverity(option.label)"></p-tag>
                                            </ng-template>
                                        </p-dropdown>
                                    </ng-template>
                                </p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="created_at">
                            <div class="flex justify-content-between align-items-center">
                                <div>
                                    {{ 'sessions.table.created_at' | translate }}
                                    <p-sortIcon field="created_at"></p-sortIcon>
                                </div>
                                <p-columnFilter type="date" field="created_at" display="menu"
                                    placeholder="{{ 'table.filter.textPlaceholder' | translate }}"></p-columnFilter>
                            </div>
                        </th>
                        <th></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-entity>
                    <tr>
                        <td>
                            <p-tableCheckbox [value]="entity"></p-tableCheckbox>
                        </td>
                        <td>
                            {{entity.code}}
                        </td>
                        <td>
                            {{entity.part_number}}
                        </td>
                        <td>
                            <p-tag [value]="'app.state.' + entity.state | translate"
                                [severity]="getSeverity(entity.state)"></p-tag>
                        </td>
                        <td>
                            {{ entity.created_at || '' | date: 'MM/dd/yyyy HH:mm:ss'}}
                        </td>
                        <td>
                            <div class="flex justify-content-end">
                                <button pButton pRipple icon="pi pi-info"
                                    title="{{ 'app.buttonTitle.details' | translate }}"
                                    class="p-button-outlined p-button-rounded p-button-success mr-2"
                                    (click)="editEntity(entity)"></button>
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>

<p-confirmDialog></p-confirmDialog>

<!-- SESSION DIALOG -->
<p-dialog *ngIf="selectedEntity" #dialog
    [header]="translateService.instant('sessions.details.header').replace('{session.code}', selectedEntity.code)"
    [(visible)]="entityDialogVisible" [breakpoints]="{ '960px': '75vw' }" class="details-dialog" [draggable]="false"
    contentStyleClass="overflow-hidden" [resizable]="false" (onHide)="onHideEditSessionDialog()">
    <p-tabView [(activeIndex)]="activeTab" (onChange)="onChangeTabEvent($event)">
        <p-tabPanel [header]="translateService.instant('sessions.details.tab_details')">
            <div class="mt-5 mb-5" *ngIf="selectedEntity.state">
                <p-toolbar>
                    <div class="p-toolbar-group-start">
                        <div class="flex flex-column">
                            <div class="flex gap-1">
                                <p-tag [value]="'app.state.' + selectedEntity.state | translate"
                                    [severity]="getSeverity(selectedEntity.state)">
                                </p-tag>
                                <p-tag value="{{selectedEntity.updated_at | date: 'MM/dd/yyyy HH:mm:ss'}}"
                                    [severity]="getSeverity(selectedEntity.state)">
                                </p-tag>
                            </div>
                        </div>
                    </div>
                    <div class="p-toolbar-group-end">
                        <div class="flex gap-2">
                            <p-button label="Close" icon="pi pi-times-circle" severity="danger" [outlined]="true"
                                *ngIf="selectedEntity.state == 'open'" (click)="close(selectedEntity)"></p-button>
                        </div>
                    </div>
                </p-toolbar>
            </div>

            <form [formGroup]="entityForm">
                <div class="grid p-fluid">
                    <div class="col-12 md:col-6">
                        <div class="field">
                            <label [ngClass]="{'text-red-500': isInvalid(entityForm, 'code')}" for="code">{{
                                'sessions.table.code' | translate }}</label>
                            <div class="p-inputgroup align-items-center">
                                <input type="text" pInputText id="code" autofocus
                                    [ngClass]="{'ng-invalid ng-dirty' : isInvalid(entityForm, 'code')}"
                                    formControlName="code" readonly />
                            </div>
                            <small class="ng-dirty ng-invalid" *ngIf="isInvalid(entityForm, 'code')">{{
                                translateService.instant('app.isRequired').replace('{field}',
                                translateService.instant('sessions.table.code')) }}</small>
                        </div>
                        <div class="field">
                            <label [ngClass]="{'text-red-500': isInvalid(entityForm, 'part_number')}"
                                for="part_number">{{
                                'sessions.table.part_number' | translate }}</label>
                            <input type="email" pInputText id="part_number"
                                [ngClass]="{'ng-invalid ng-dirty' : isInvalid(entityForm, 'part_number')}"
                                formControlName="part_number" readonly />
                            <small class="ng-dirty ng-invalid" *ngIf="isInvalid(entityForm, 'part_number')">{{
                                translateService.instant('app.isRequired').replace('{field}',
                                translateService.instant('sessions.table.part_number')) }}</small>
                        </div>
                    </div>
                    <div class="col-12 md:col-6">
                        <div class="field">
                            <label [ngClass]="{'text-red-500': isInvalid(entityForm, 'device')}" for="device">{{
                                'sessions.table.device' | translate }}</label>
                            <input type="text" pInputText id="device"
                                [ngClass]="{'ng-invalid ng-dirty' : isInvalid(entityForm, 'device')}"
                                formControlName="device" readonly />
                            <small class="ng-dirty ng-invalid" *ngIf="isInvalid(entityForm, 'device')">{{
                                translateService.instant('app.isRequired').replace('{field}',
                                translateService.instant('sessions.table.device')) }}</small>
                        </div>
                        <div class="flex">
                            <div class="field col-6 p-0">
                                <label>{{'sessions.table.recomendation_guide_read' | translate }}</label>
                                <div>
                                    <p-checkbox formControlName="recomendation_guide_read"
                                        inputId="recomendation_guide_read" [binary]="true" disabled></p-checkbox>
                                </div>
                            </div>
                            <div class="field col-6 p-0 pl-2">
                                <label>{{'sessions.table.measuring_guide_read' | translate }}</label>
                                <div>
                                    <p-checkbox formControlName="measuring_guide_read" inputId="measuring_guide_read"
                                        [binary]="true" disabled></p-checkbox>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <ng-template pTemplate="footer">
                <div class="flex justify-content-between align-items-center">
                    <button pButton pRipple [label]="translateService.instant('sessions.details.download_3D_report')"
                        icon="pi pi-download" class="p-button-text" (click)="downloadHTMLTemplate()"></button>
                    <button pButton pRipple [label]="translateService.instant('app.cancel')" icon="pi pi-times"
                        class="p-button-text" (click)="entityDialogVisible = false"></button>
                </div>
            </ng-template>
        </p-tabPanel>
        <p-tabPanel [header]="translateService.instant('sessions.details.tab_measurement_guide')">
            <p-table #measurementsGuideDataTable [value]="measurements()" [scrollable]="true"
                scrollHeight="calc(100vh - 380px)" [rowHover]="true" styleClass="p-datatable-sm"
                responsiveLayout="scroll" lazy="true" [lazyLoadOnInit]="lazyLoadOnInit"
                (onLazyLoad)="onLazyLoadMeasurements($event)" sortField="step.step_code" [loading]="loading"
                paginator="true" [rows]="rowsMeasurements" [first]="firstMeasurements"
                [totalRecords]="measurementsCount" [rowsPerPageOptions]="[10, 20, 30, 50, 100]"
                [showCurrentPageReport]="true"
                [currentPageReportTemplate]="translateService.instant('table.paginator.template')">
                <ng-template pTemplate="header">
                    <tr>
                        <th pSortableColumn="step.step_code">
                            <div class="flex justify-content-between align-items-center">
                                <div>
                                    {{ 'sessions.measurements_guide.table.step_code' | translate }}
                                    <p-sortIcon field="step.step_code"></p-sortIcon>
                                </div>
                                <p-columnFilter type="text" field="measuring_guide_steps.step_code" display="menu"
                                    placeholder="{{ 'table.filter.textPlaceholder' | translate }}"></p-columnFilter>
                            </div>
                        </th>
                        <th>
                            <div class="flex justify-content-between align-items-center">
                                <div>
                                    {{ 'sessions.measurements_guide.table.device' | translate }}
                                </div>
                            </div>
                        </th>
                        <th>
                            {{ 'sessions.measurements_guide.table.ref_point' | translate }}
                        </th>
                        <th>
                            {{ 'sessions.measurements_guide.table.point' | translate }}
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-measurement>
                    <tr>
                        <td>
                            {{ measurement.step.step_code }}
                        </td>
                        <td>
                            {{ selectedEntity.device_setting.name }}
                        </td>
                        <td>
                            {{ formatPoint(measurement.step) }}
                        </td>
                        <td>
                            {{ formatPoint(measurement) }}
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="8">{{ 'table.empty' | translate }}</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="loadingbody">
                    <tr>
                        <td colspan="8">{{ 'table.loading' | translate }}</td>
                    </tr>
                </ng-template>
            </p-table>
        </p-tabPanel>
        <p-tabPanel [header]="translateService.instant('sessions.details.tab_defects')">
            <p-table #defectsGuideDataTable [value]="defects()" [scrollable]="true" scrollHeight="calc(100vh - 380px)"
                [rowHover]="true" styleClass="p-datatable-sm" responsiveLayout="scroll" lazy="true"
                [lazyLoadOnInit]="lazyLoadOnInit" (onLazyLoad)="onLazyLoadDefects($event)" sortField="created_at"
                [loading]="loading" paginator="true" [rows]="rowsDefects" [first]="firstDefects"
                [totalRecords]="defectsCount" [rowsPerPageOptions]="[10, 20, 30, 50, 100]"
                [showCurrentPageReport]="true"
                [currentPageReportTemplate]="translateService.instant('table.paginator.template')">
                <ng-template pTemplate="header">
                    <tr>
                        <th>
                            {{ 'sessions.defects.table.device' | translate }}
                        </th>
                        <th pSortableColumn="type">
                            <div class="flex justify-content-between align-items-center">
                                <div>
                                    {{ 'sessions.defects.table.type' | translate }}
                                    <p-sortIcon field="type"></p-sortIcon>
                                </div>
                                <p-columnFilter type="text" field="measuring_guide_steps.type" display="menu"
                                    placeholder="{{ 'table.filter.textPlaceholder' | translate }}"></p-columnFilter>
                            </div>
                        </th>
                        <th>
                            {{ 'sessions.defects.table.point' | translate }}
                        </th>
                        <th>
                        </th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-defect>
                    <tr>
                        <td>
                            {{ selectedEntity.device_setting.name }}
                        </td>
                        <td>
                            {{ defect.type }}
                        </td>
                        <td>
                            {{ formatPoint(defect) }}
                        </td>
                        <td>
                            <ng-container *ngIf="defect.images.length">
                                <p-button icon="pi pi-image" (click)="defect.showAnnotationDialog = true"
                                    [outlined]="true" severity="success" [rounded]="true" />
                                <p-dialog
                                    [header]="translateService.instant('session.defects.images.annotations.title')"
                                    [(visible)]="defect.showAnnotationDialog" [breakpoints]="{ '960px': '75vw' }"
                                    class="annotation-dialog" [draggable]="false" [resizable]="false">
                                    <div
                                        class="w-full h-full flex flex-column justify-content-center align-items-center p-3">
                                        <p-galleria [(value)]="defect.images" [responsiveOptions]="responsiveOptions"
                                            [(visible)]="defect.showAnnotationDialog" [showItemNavigators]="true"
                                            [showThumbnails]="false" [fullScreen]="true">
                                            <ng-template pTemplate="item" let-item>
                                                <div
                                                    class="flex flex-column justify-content-center align-items-center gap-5">
                                                    <app-image-annotation [src]="item.url" [type]="defect.type"
                                                        [annotations]="item.annotations" [showMessage]="false"
                                                        [readOnly]="true"
                                                        (annotationsChange)="updateAnnotations($event, defect, item)" />
                                                </div>
                                            </ng-template>
                                        </p-galleria>
                                    </div>
                                    <ng-template pTemplate="footer">
                                        <div class="flex justify-content-end">
                                            <button pButton pRipple [label]="translateService.instant('app.close')"
                                                icon="pi pi-times" class="p-button-text p-button-danger"
                                                (click)="defect.showAnnotationDialog = false"></button>
                                        </div>
                                    </ng-template>
                                </p-dialog>
                            </ng-container>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="8">{{ 'table.empty' | translate }}</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="loadingbody">
                    <tr>
                        <td colspan="8">{{ 'table.loading' | translate }}</td>
                    </tr>
                </ng-template>
            </p-table>
        </p-tabPanel>
        <p-tabPanel [header]="translateService.instant('sessions.details.tab_3d')">
            <div #wrapper id="wrapper"
                class="flex flex-row p-0 shadow-none border-1 surface-border surface-card border-round overflow-hidden relative h-full w-full">
                <p-button type="button" class="absolute top-0 right-0 m-3" (click)="sidebarVisible = true"
                    icon="pi pi-bars"></p-button>
                <div #container class="w-full h-full"></div>
                <p-sidebar [(visible)]="sidebarVisible" position="right" [appendTo]="wrapper" [modal]="false"
                    styleClass="absolute">
                    <ng-template pTemplate="headless">
                        <div class="overflow-y-auto flex flex-column gap-2">
                            <div pRipple pStyleClass="@next" enterClass="hidden" enterActiveClass="slidedown"
                                leaveToClass="hidden" leaveActiveClass="slideup"
                                class="p-3 flex align-items-center justify-content-between text-600 cursor-pointer p-ripple">
                                <span class="font-medium">Filter</span>
                                <i class="pi pi-chevron-down"></i>
                            </div>
                            <div class="overflow-hidden">
                                <div class="flex flex-row gap-1">
                                    <p-checkbox [(ngModel)]="allTypesChecked" [binary]="true" inputId="selectAll"
                                        (onChange)="selectAllEvent($event)">
                                    </p-checkbox>
                                    <label for="selectAll">{{ translateService.instant('session.3d_model.check_all')
                                        }}</label>
                                </div>
                                <ul class="color-list mt-3">
                                    <li *ngFor="let type of typeColors | keyvalue" class="color-list-item"
                                        style="cursor: pointer;" (click)="onClickMeasurementTypeFilter(type.key)">
                                        <div class="color-box" [style.background-color]="'#'+type.value.getHexString()">
                                        </div>
                                        <p [ngClass]="{'line-through': !options[type.key]}">
                                            {{type.key}}
                                        </p>
                                    </li>
                                </ul>
                            </div>
                            <p-card *ngIf="selectedPoint"
                                styleClass="p-0 shadow-none border-1 surface-border surface-card border-round">
                                <ng-template pTemplate="header">
                                    <div class="flex align-items-center justify-content-between pt-3 px-3">
                                        <div class="p-card-title">
                                            {{
                                            translateService.instant('session.3d_model.selected_measurement_point')
                                            }}
                                        </div>
                                    </div>
                                </ng-template>
                                <div *ngIf="selectedPoint" class="flex flex-column align-items-center gap-3">
                                    <div class="flex flex-row align-items-baseline">
                                        <h5 class="flex align-items-center justify-content-center m-0">
                                            {{ formatPoint(selectedPoint.userData) }}
                                        </h5>
                                    </div>
                                    <p-tag severity="info">
                                        <span>Emitted at <b>{{ formatDate(selectedPoint.userData['created_at'])
                                                }}</b></span>
                                    </p-tag>
                                    <div class="flex flex-row align-items-baseline">
                                        <h5 class="flex align-items-center justify-content-center m-0">
                                            {{selectedPoint.userData['type']}}
                                        </h5>
                                    </div>
                                    <div *ngIf="selectedPoint.userData['image']"
                                        class="w-full h-full flex flex-column justify-content-start align-items-center p-3 cursor-pointer"
                                        (click)="showSelectedPointDialog()">
                                        <app-image-annotation [src]="selectedPoint.userData['image'].url"
                                            [type]="selectedPoint.userData['type']" [readOnly]="true"
                                            [showMessage]="false"
                                            [annotations]="selectedPoint.userData['image'].annotations" />
                                    </div>
                                    <div *ngIf="!selectedPoint.userData['image']"
                                        class="flex flex-row align-items-baseline">
                                        <h5 class="flex align-items-center justify-content-center m-0">
                                            No image to show
                                        </h5>
                                    </div>
                                    <p-dialog *ngIf="selectedPoint.userData['image']"
                                        [(visible)]="selectedPointDialogVisible" [modal]="true" [draggable]="false"
                                        [header]="translateService.instant('session.defects.images.annotations.title')"
                                        [resizable]="false" class="annotation-dialog"
                                        [breakpoints]="{ '960px': '75vw' }">
                                        <div
                                            class="w-full h-full flex flex-column justify-content-center align-items-center p-3">
                                            <app-image-annotation [src]="selectedPoint.userData['image'].url"
                                                [type]="selectedPoint.userData['type']" [readOnly]="true"
                                                [showMessage]="false"
                                                [annotations]="selectedPoint.userData['image'].annotations" />
                                        </div>
                                    </p-dialog>
                                </div>
                            </p-card>
                        </div>
                    </ng-template>
                </p-sidebar>
            </div>
        </p-tabPanel>
    </p-tabView>
</p-dialog>

<!-- STEP DIALOG -->
<p-dialog *ngIf="selectedStep"
    [header]="translateService.instant('measurement-guide.steps.details.header').replace('{step_code}', selectedStep.step_code ?? '')"
    [(visible)]="stepDialogVisible" [breakpoints]="{ '960px': '75vw' }" class="details-dialog" [draggable]="false"
    [resizable]="false" (onHide)="onHideEditStepDialog()">
    <form [formGroup]="stepForm">
        <div class="grid p-fluid">
            <div class="col-12 md:col-6">
                <div class="field">
                    <label [ngClass]="{'text-red-500': isInvalid(stepForm, 'step_code')}" for="step_code">{{
                        'measurement-guide.steps.table.step_code' | translate }} *</label>
                    <input type="text" pInputText id="step_code"
                        [ngClass]="{'ng-invalid ng-dirty' : isInvalid(stepForm, 'step_code')}"
                        formControlName="step_code" />
                    <small class="ng-dirty ng-invalid" *ngIf="isInvalid(stepForm, 'step_code')">{{
                        translateService.instant('app.isRequired').replace('{field}',
                        translateService.instant('measurement-guide.steps.table.step_code')) }}</small>
                </div>
                <div class="field">
                    <label [ngClass]="{'text-red-500': isInvalid(stepForm, 'reference_point')}" for="reference_point">{{
                        'measurement-guide.steps.table.reference_point' | translate }} *</label>
                    <input type="text" pInputText id="reference_point"
                        [ngClass]="{'ng-invalid ng-dirty' : isInvalid(stepForm, 'reference_point')}"
                        formControlName="reference_point" />
                    <small class="ng-dirty ng-invalid" *ngIf="isInvalid(stepForm, 'reference_point')">{{
                        translateService.instant('app.isRequired').replace('{field}',
                        translateService.instant('measurement-guide.steps.table.reference_point')) }}</small>
                </div>
            </div>
            <div class="col-12 md:col-6">
                <div class="field">
                    <label [ngClass]="{'text-red-500': isInvalid(stepForm, 'instrument_id')}" for="instrument_id">
                        {{ 'measurement-guide.steps.table.instrument_id' | translate }} *</label>
                    <input type="text" pInputText id="instrument_id"
                        [ngClass]="{'ng-invalid ng-dirty' : isInvalid(stepForm, 'instrument_id')}"
                        formControlName="instrument_id" />
                    <small class="ng-dirty ng-invalid" *ngIf="isInvalid(stepForm, 'instrument_id')">{{
                        translateService.instant('app.isRequired').replace('{field}',
                        translateService.instant('measurement-guide.steps.table.instrument_id')) }}</small>
                </div>
                <div *ngIf="selectedStep.created_at" class="field">
                    <label for="created_at">{{ 'measurement-guide.steps.table.created_at' | translate }}</label>
                    <input type="text" pInputText id="created_at" formControlName="created_at" />
                </div>
            </div>
            <div class="col-12">
                <div class="field">
                    <label [ngClass]="{'text-red-500': isInvalid(stepForm, 'instructions')}" for="instructions">
                        {{'measurement-guide.steps.table.instructions' | translate }} *</label>
                    <p-editor id="instructions" formControlName="instructions" class="w-full" />
                    <small class="ng-dirty ng-invalid" *ngIf="isInvalid(stepForm, 'instructions')">{{
                        translateService.instant('app.isRequired').replace('{field}',
                        translateService.instant('measurement-guide.steps.table.instructions')) }}</small>
                </div>
            </div>
        </div>
    </form>
    <div *ngIf="selectedStep.media">
        <div class="flex flex-row gap-2 mb-2">
            <p-fileUpload #imageUploader mode="basic"
                [chooseLabel]="translateService.instant('measurement-guide.steps.details.add_image')" accept="image/*"
                maxFileSize="1000000" (uploadHandler)="onUpload($event)" customUpload="true" [auto]="true">
            </p-fileUpload>
            <button *ngIf="images.length" pButton pRipple [label]="translateService.instant('app.delete')"
                icon="pi pi-trash" class="p-button p-button-danger" (click)="deleteImage()">
            </button>
        </div>
        <p-galleria *ngIf="images.length" [(value)]="images" [responsiveOptions]="responsiveOptions"
            [containerStyle]="{ 'max-width': '640px' }" [circular]="true" [(activeIndex)]="galleryIndex">
            <ng-template pTemplate="item" let-item>
                <img [src]="item.original_url" style="display: block; width: 100%;" />
            </ng-template>
        </p-galleria>
    </div>
    <ng-template pTemplate="footer">
        <div class="flex justify-content-end align-items-center">
            <div>
                <button pButton pRipple [label]="translateService.instant('app.cancel')" icon="pi pi-times"
                    class="p-button-text" (click)="stepDialogVisible = false"></button>
                <button pButton pRipple [label]="translateService.instant('app.save')" icon="pi pi-check"
                    class="p-button-text" (click)="saveStep()"></button>
            </div>
        </div>
    </ng-template>
</p-dialog>