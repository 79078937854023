<div class="grid">
    <div class="col-12">
        <div class="card">
            <h5>{{ 'measurement-guide.table.title' | translate }}</h5>
            <p-table #dataTable [value]="entities()" [scrollable]="true" scrollHeight="calc(100vh - 380px)"
                [rowHover]="true" styleClass="p-datatable-sm" [(selection)]="selectedEntities" responsiveLayout="scroll"
                lazy="true" [lazyLoadOnInit]="lazyLoadOnInit" (onLazyLoad)="onLazyLoad($event)" sortField="part_number"
                paginator="true" [rows]="rows" [first]="first" [totalRecords]="entitiesCount"
                [rowsPerPageOptions]="[10, 20, 30, 50, 100]" [showCurrentPageReport]="true"
                [currentPageReportTemplate]="translateService.instant('table.paginator.template')">
                <ng-template pTemplate="caption">
                    <div class="flex justify-content-between align-items-center">
                        <div>
                            <button pButton pRipple icon="pi pi-plus"
                                class="p-button-outlined p-button-rounded p-button-success mr-2" (click)="newEntity()">
                                <span class="hidden md:block ml-2"><b>{{ translateService.instant('app.new')
                                        }}</b></span>
                            </button>
                            <button pButton pRipple icon="pi pi-trash"
                                class="p-button-outlined p-button-rounded p-button-danger mr-2"
                                (click)="deleteSelectedEntities()"
                                [disabled]="!selectedEntities || !selectedEntities.length">
                                <span class="hidden md:block ml-2"><b>{{ translateService.instant('app.delete')
                                        }}</b></span>
                            </button>
                            <button pButton pRipple icon="pi pi-file-excel"
                                class="p-button-outlined p-button-rounded p-button-success mr-2"
                                (click)="exportExcel(dataTable.filters, dataTable.sortField, dataTable.sortOrder, selectedEntities, 'measurementGuides')">
                                <span class="hidden md:block ml-2"><b>{{ translateService.instant('app.export')
                                        }}</b></span>
                            </button>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th style="width: 3rem">
                            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                        </th>
                        <th pSortableColumn="part_number">
                            <div class="flex justify-content-between align-items-center">
                                <div>
                                    {{ 'measurement-guide.table.part_number' | translate }}
                                    <p-sortIcon field="part_number"></p-sortIcon>
                                </div>
                                <p-columnFilter type="text" field="part_number" display="menu"
                                    placeholder="{{ 'table.filter.textPlaceholder' | translate }}"></p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="version">
                            <div class="flex justify-content-between align-items-center">
                                <div>
                                    {{ 'measurement-guide.table.version' | translate }}
                                    <p-sortIcon field="version"></p-sortIcon>
                                </div>
                                <p-columnFilter type="text" field="version" display="menu"
                                    placeholder="{{ 'table.filter.textPlaceholder' | translate }}"></p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="created_at">
                            <div class="flex justify-content-between align-items-center">
                                <div>
                                    {{ 'measurement-guide.table.created_at' | translate }}
                                    <p-sortIcon field="created_at"></p-sortIcon>
                                </div>
                                <p-columnFilter type="date" field="created_at" display="menu"
                                    placeholder="{{ 'table.filter.textPlaceholder' | translate }}"></p-columnFilter>
                            </div>
                        </th>
                        <th pSortableColumn="state">
                            <div class="flex justify-content-between align-items-center">
                                <div>
                                    {{ 'measurement-guide.table.state' | translate }}
                                    <p-sortIcon field="state"></p-sortIcon>
                                </div>
                                <p-columnFilter field="state" matchMode="equals" display="menu"
                                    [showMatchModes]="false">
                                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                        <p-dropdown [ngModel]="value" [options]="statuses"
                                            (onChange)="filter($event.value)" placeholder="Any">
                                            <ng-template let-option pTemplate="item">
                                                <p-tag [value]="option.label"
                                                    [severity]="getSeverity(option.label)"></p-tag>
                                            </ng-template>
                                        </p-dropdown>
                                    </ng-template>
                                </p-columnFilter>
                            </div>
                        </th>
                        <th></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-entity>
                    <tr>
                        <td>
                            <p-tableCheckbox [value]="entity"></p-tableCheckbox>
                        </td>
                        <td>
                            {{entity.part_number}}
                        </td>
                        <td>
                            {{entity.version}}
                        </td>
                        <td>
                            {{ entity.created_at || '' | date: 'MM/dd/yyyy HH:mm:ss'}}
                        </td>
                        <td>
                            <p-tag [value]="'app.state.' + entity.state | translate"
                                [severity]="getSeverity(entity.state)"></p-tag>
                        </td>
                        <td>
                            <div class="flex justify-content-end">
                                <button pButton pRipple icon="pi pi-clone" title="{{ 'app.buttonTitle.clone' | translate }}"
                                    class="p-button-outlined p-button-rounded p-button-info mr-2"
                                    (click)="showCloneDialog(entity)"></button>
                                <button pButton pRipple icon="pi pi-pencil" title="{{ 'app.buttonTitle.edit' | translate }}"
                                    class="p-button-outlined p-button-rounded p-button-success mr-2"
                                    (click)="editEntity(entity)"></button>
                                <button pButton pRipple icon="pi pi-trash" title="{{ 'app.buttonTitle.delete' | translate }}"
                                    class="p-button-outlined p-button-rounded p-button-warning"
                                    (click)="deleteEntity(entity)"></button>
                            </div>
                        </td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>
</div>

<p-confirmDialog></p-confirmDialog>

<!-- GUIDE DIALOG -->
<p-dialog *ngIf="selectedEntity"
    [header]="translateService.instant('measurement-guide.details.header').replace('{guide.code}', selectedEntity.part_number ?? '')"
    [(visible)]="entityDialogVisible" [breakpoints]="{ '960px': '75vw' }" class="details-dialog" [draggable]="false"
    [resizable]="false" (onHide)="onHideEditEntityDialog()">
    <p-tabView [(activeIndex)]="activeTab">
        <p-tabPanel [header]="translateService.instant('measurement-guide.details.tab_details')">
            <div class="mt-5 mb-5" *ngIf="selectedEntity.state">
                <p-toolbar>
                    <div class="p-toolbar-group-start">
                        <div class="flex flex-column">
                            <div class="flex gap-1">
                                <p-tag [value]="'app.state.' + selectedEntity.state | translate"
                                    [severity]="getSeverity(selectedEntity.state)">
                                </p-tag>
                                <p-tag value="{{selectedEntity.updated_at | date: 'MM/dd/yyyy HH:mm:ss'}}"
                                    [severity]="getSeverity(selectedEntity.state)">
                                </p-tag>
                            </div>
                            <div class="flex align-items-center mt-2">
                                <p-avatar [label]="selectedEntity.owner.name[0].toUpperCase()" styleClass="mr-2"
                                    shape="circle"></p-avatar>
                                <span>{{selectedEntity.owner.name}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="p-toolbar-group-end">
                        <div class="flex gap-2">
                            <p-button label="Publish" icon="pi pi-thumbs-up" severity="success"
                                *ngIf="selectedEntity.state == 'draft'" (click)="publish(selectedEntity)"></p-button>
                            <p-button label="Unpublish" icon="pi pi-undo" severity="secondary"
                                *ngIf="selectedEntity.state == 'published'"
                                (click)="unpublish(selectedEntity)"></p-button>
                            <p-button label="Archive" icon="pi pi-inbox" severity="warning"
                                *ngIf="selectedEntity.state == 'published'"
                                (click)="archive(selectedEntity)"></p-button>
                            <p-button label="Unarchive" icon="pi pi-undo" severity="secondary"
                                *ngIf="selectedEntity.state == 'archived'"
                                (click)="unarchive(selectedEntity)"></p-button>
                        </div>
                    </div>
                </p-toolbar>
            </div>

            <form [formGroup]="entityForm">
                <div class="grid p-fluid">
                    <div class="col-12 md:col-6">
                        <div class="field">
                            <label [ngClass]="{'text-red-500': isInvalid(entityForm, 'part_number')}"
                                for="part_number">{{
                                'measurement-guide.table.part_number' | translate }} *</label>
                            <input type="text" pInputText id="part_number"
                                [ngClass]="{'ng-invalid ng-dirty' : isInvalid(entityForm, 'part_number')}"
                                formControlName="part_number" placeholder="CLIENT#REFUID#XXXXXX"/>
                            <small class="ng-dirty ng-invalid" *ngIf="isInvalid(entityForm, 'part_number') && entityForm.get('part_number').hasError('required')">{{
                                translateService.instant('app.isRequired').replace('{field}',
                                translateService.instant('measurement-guide.table.part_number')) }}</small>
                            <small class="ng-dirty ng-invalid" *ngIf="isInvalid(entityForm, 'part_number') && entityForm.get('part_number').hasError('pattern')">
                                {{ translateService.instant('app.incorrectPattern').replace('{field}',
                                translateService.instant('measurement-guide.table.part_number')).replace('{pattern}', "CLIENT#REFUID#XXXXXX") }}</small>
                        </div>
                    </div>
                    <div class="col-12 md:col-6">
                        <div class="field">
                            <label [ngClass]="{'text-red-500': isInvalid(entityForm, 'version')}" for="version">{{
                                'measurement-guide.table.version' | translate }} *</label>
                            <input type="text" pInputText id="version"
                                [ngClass]="{'ng-invalid ng-dirty' : isInvalid(entityForm, 'version')}"
                                formControlName="version" />
                            <small class="ng-dirty ng-invalid" *ngIf="isInvalid(entityForm, 'version')">{{
                                translateService.instant('app.isRequired').replace('{field}',
                                translateService.instant('measurement-guide.table.version')) }}</small>
                        </div>
                    </div>
                    <div class="col-12 md:col-6">
                        <div class="field">
                            <label for="model_url">{{
                                'measurement-guide.table.model_url' | translate }} </label>
                            <input type="text" pInputText id="model_url"
                                formControlName="model_url" />
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="field">
                            <label [ngClass]="{'text-red-500': isInvalid(entityForm, 'content')}"
                                for="content">{{'measurement-guide.table.content' | translate }} *</label>
                            <p-editor id="content" formControlName="content" class="w-full"
                                [readonly]="editorReadonly" />
                            <small class="ng-dirty ng-invalid" *ngIf="isInvalid(entityForm, 'content')">{{
                                translateService.instant('app.isRequired').replace('{field}',
                                translateService.instant('measurement-guide.table.content')) }}</small>
                        </div>
                    </div>
                </div>
            </form>
            <ng-template pTemplate="footer">
                <div class="flex justify-content-end align-items-center">
                    <div *ngIf="!selectedEntity.state || selectedEntity.state === 'draft'">
                        <button pButton pRipple [label]="translateService.instant('app.cancel')" icon="pi pi-times"
                            class="p-button-text" (click)="entityDialogVisible = false"></button>
                        <button pButton pRipple [label]="translateService.instant('app.save')" icon="pi pi-check"
                            class="p-button-text" (click)="saveEntity()"></button>
                    </div>
                </div>
            </ng-template>
        </p-tabPanel>
        <p-tabPanel *ngIf="selectedEntity.steps"
            [header]="translateService.instant('measurement-guide.details.tab_steps')">
            <p-table #stepsDataTable [value]="steps()" [scrollable]="true" scrollHeight="calc(100vh - 380px)"
                [rowHover]="true" styleClass="p-datatable-sm" responsiveLayout="scroll" lazy="true"
                [(selection)]="selectedSteps" [lazyLoadOnInit]="lazyLoadOnInit" (onLazyLoad)="onLazyLoadSteps($event)"
                sortField="step_code" paginator="true" [rows]="rows" [first]="first" [totalRecords]="stepsCount"
                [rowsPerPageOptions]="[10, 20, 30, 50, 100]" [showCurrentPageReport]="true"
                [currentPageReportTemplate]="translateService.instant('table.paginator.template')">
                <ng-template pTemplate="caption">
                    <div class="flex justify-content-between align-items-center">
                        <div>
                            <button pButton pRipple icon="pi pi-plus"
                                class="p-button-outlined p-button-rounded p-button-success mr-2" (click)="newStep()">
                                <span class="hidden md:block ml-2"><b>{{ translateService.instant('app.new')
                                        }}</b></span>
                            </button>
                            <button pButton pRipple icon="pi pi-trash"
                                class="p-button-outlined p-button-rounded p-button-danger mr-2"
                                (click)="deleteSelectedSteps()" [disabled]="!selectedSteps || !selectedSteps.length">
                                <span class="hidden md:block ml-2"><b>{{ translateService.instant('app.delete')
                                        }}</b></span>
                            </button>
                            <button pButton pRipple icon="pi pi-file-excel"
                                class="p-button-outlined p-button-rounded p-button-success mr-2"
                                (click)="exportExcel(stepsDataTable.filters, stepsDataTable.sortField, stepsDataTable.sortOrder, selectedSteps, 'steps')">
                                <span class="hidden md:block ml-2"><b>{{ translateService.instant('app.export')
                                        }}</b></span>
                            </button>
                        </div>
                    </div>
                </ng-template>
                <ng-template pTemplate="header">
                    <tr>
                        <th style="width: 3rem">
                            <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                        </th>
                        <th pSortableColumn="step_code">
                            <div class="flex justify-content-between align-items-center">
                                <div>
                                    {{ 'measurement-guide.steps.table.step_code' | translate }}
                                    <p-sortIcon field="step_code"></p-sortIcon>
                                </div>
                                <p-columnFilter type="text" field="step_code" display="menu"
                                    placeholder="{{ 'table.filter.textPlaceholder' | translate }}"></p-columnFilter>
                            </div>
                        </th>
                        <th>
                            <div class="flex justify-content-between align-items-center">
                                <div>
                                    {{ 'measurement-guide.steps.table.reference_point' | translate }}
                                </div>
                            </div>
                        </th>
                        <th></th>
                    </tr>
                </ng-template>
                <ng-template pTemplate="body" let-step>
                    <tr>
                        <td>
                            <p-tableCheckbox [value]="step"></p-tableCheckbox>
                        </td>
                        <td>
                            <div style="min-width: 100px;">{{ step.step_code }}</div>
                        </td>
                        <td>
                            <div style="min-width: 200px;">{{ formatPoint(step) }}</div>
                        </td>
                        <td>
                            <div class="flex justify-content-end">
                                <button pButton pRipple icon="pi pi-pencil" title="{{ 'app.buttonTitle.edit' | translate }}"
                                    class="p-button-outlined p-button-rounded p-button-success mr-2"
                                    (click)="editStep(step)"></button>
                                <button pButton pRipple icon="pi pi-trash" title="{{ 'app.buttonTitle.delete' | translate }}"
                                    class="p-button-outlined p-button-rounded p-button-warning"
                                    (click)="deleteStep(step)"></button>
                            </div>
                        </td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="emptymessage">
                    <tr>
                        <td colspan="8">{{ 'table.empty' | translate }}</td>
                    </tr>
                </ng-template>
                <ng-template pTemplate="loadingbody">
                    <tr>
                        <td colspan="8">{{ 'table.loading' | translate }}</td>
                    </tr>
                </ng-template>
            </p-table>
        </p-tabPanel>
    </p-tabView>
</p-dialog>

<!-- STEP DIALOG -->
<p-dialog *ngIf="selectedStep"
    [header]="translateService.instant('measurement-guide.steps.details.header').replace('{step_code}', selectedStep.step_code ?? '')"
    [(visible)]="stepDialogVisible" [breakpoints]="{ '960px': '75vw' }" class="details-dialog" [draggable]="false"
    [resizable]="false" (onHide)="onHideEditStepDialog()">
    <form [formGroup]="stepForm">
        <div class="grid p-fluid">
            <div class="col-12 md:col-6">
                <div class="field">
                    <label [ngClass]="{'text-red-500': isInvalid(stepForm, 'step_code')}" for="step_code">{{
                        'measurement-guide.steps.table.step_code' | translate }} *</label>
                    <input type="text" pInputText id="step_code"
                        [ngClass]="{'ng-invalid ng-dirty' : isInvalid(stepForm, 'step_code')}"
                        formControlName="step_code" />
                    <small class="ng-dirty ng-invalid" *ngIf="isInvalid(stepForm, 'step_code')">{{
                        translateService.instant('app.isRequired').replace('{field}',
                        translateService.instant('measurement-guide.steps.table.step_code')) }}</small>
                </div>
                <label for="reference_point">{{
                    'measurement-guide.steps.table.reference_point' | translate }}</label>
                <div class="flex d-inline-flex">
                    <div class="field mr-3">
                        <label [ngClass]="{'text-red-500': isInvalid(stepForm, 'coordinate_x')}" for="coordinate_x">{{
                            'measurement-guide.steps.table.coordinate_x' | translate }} *</label>
                        <input type="number" pInputText id="coordinate_x"
                            [ngClass]="{'ng-invalid ng-dirty' : isInvalid(stepForm, 'coordinate_x')}"
                            formControlName="coordinate_x" />
                        <small class="ng-dirty ng-invalid" *ngIf="isInvalid(stepForm, 'coordinate_x')">{{
                            translateService.instant('app.isRequired').replace('{field}',
                            translateService.instant('measurement-guide.steps.table.coordinate_x')) }}</small>
                    </div>
                    <div class="field mr-3">
                        <label [ngClass]="{'text-red-500': isInvalid(stepForm, 'coordinate_y')}" for="coordinate_y">{{
                            'measurement-guide.steps.table.coordinate_y' | translate }} *</label>
                        <input type="number" pInputText id="coordinate_y"
                            [ngClass]="{'ng-invalid ng-dirty' : isInvalid(stepForm, 'coordinate_y')}"
                            formControlName="coordinate_y" />
                        <small class="ng-dirty ng-invalid" *ngIf="isInvalid(stepForm, 'coordinate_y')">{{
                            translateService.instant('app.isRequired').replace('{field}',
                            translateService.instant('measurement-guide.steps.table.coordinate_y')) }}</small>
                    </div>
                    <div class="field">
                        <label [ngClass]="{'text-red-500': isInvalid(stepForm, 'coordinate_z')}" for="coordinate_z">{{
                            'measurement-guide.steps.table.coordinate_z' | translate }} *</label>
                        <input type="number" pInputText id="coordinate_z"
                            [ngClass]="{'ng-invalid ng-dirty' : isInvalid(stepForm, 'coordinate_z')}"
                            formControlName="coordinate_z" />
                        <small class="ng-dirty ng-invalid" *ngIf="isInvalid(stepForm, 'coordinate_z')">{{
                            translateService.instant('app.isRequired').replace('{field}',
                            translateService.instant('measurement-guide.steps.table.coordinate_z')) }}</small>
                    </div>
                </div>
            </div>
            <div class="col-12 md:col-6">
                <div class="field">
                    <label [ngClass]="{'text-red-500': isInvalid(stepForm, 'instrument_id')}" for="instrument_id">
                        {{ 'measurement-guide.steps.table.instrument_id' | translate }} *</label>
                    <p-dropdown id="instrument_id" [options]="devices" formControlName="instrument_id"
                        [ngClass]="{'ng-invalid ng-dirty' : isInvalid(stepForm, 'instrument_id')}" optionLabel="name"
                        optionValue="id" [style]="{ width: '100%' }"></p-dropdown>
                    <small class="ng-dirty ng-invalid" *ngIf="isInvalid(stepForm, 'instrument_id')">{{
                        translateService.instant('app.isRequired').replace('{field}',
                        translateService.instant('measurement-guide.steps.table.instrument_id')) }}</small>
                </div>
            </div>
            <div class="col-12">
                <div class="field">
                    <label [ngClass]="{'text-red-500': isInvalid(stepForm, 'instructions')}" for="instructions">
                        {{'measurement-guide.steps.table.instructions' | translate }} *</label>
                    <p-editor id="instructions" formControlName="instructions" class="w-full" />
                    <small class="ng-dirty ng-invalid" *ngIf="isInvalid(stepForm, 'instructions')">{{
                        translateService.instant('app.isRequired').replace('{field}',
                        translateService.instant('measurement-guide.steps.table.instructions')) }}</small>
                </div>
            </div>
        </div>
    </form>
    <ng-template pTemplate="footer">
        <div class="flex justify-content-end align-items-center">
            <div>
                <button pButton pRipple [label]="translateService.instant('app.cancel')" icon="pi pi-times"
                    class="p-button-text" (click)="stepDialogVisible = false"></button>
                <button pButton pRipple [label]="translateService.instant('app.save')" icon="pi pi-check"
                    class="p-button-text" (click)="saveStep()"></button>
            </div>
        </div>
    </ng-template>
</p-dialog>

<!-- CLONE DIALOG -->
<p-dialog *ngIf="selectedEntity" [header]="this.translateService.instant('app.cloneDialog.header')"
    [(visible)]="cloneEntityDialogVisible" [modal]="true" [draggable]="false" [resizable]="false"
    [breakpoints]="{ '960px': '75vw' }">
    <div class="flex flex-column ">
        <label for="version">{{ this.translateService.instant('app.cloneDialog.label').replace('{field}',
            translateService.instant('measurement-guide.table.version')) }}</label>
        <input type="text" pInputText id="version" [(ngModel)]="newVersion" />
    </div>
    <ng-template pTemplate="footer">
        <div class="flex justify-content-end align-items-center">
            <div>
                <button pButton pRipple [label]="translateService.instant('app.save')" icon="pi pi-check"
                    [disabled]="newVersion === ''" class="p-button-text" (click)="cloneEntity()"></button>
            </div>
        </div>
    </ng-template>
</p-dialog>