import { Injectable, computed, effect, signal } from "@angular/core";

type User = {
    id: number,
    name: string,
    email: string,
    email_verified_at: Date,
    created_at: Date,
    updated_at: Date,
    deleted_at: Date,
    logged_at: Date
}

type State = {
    currentUser: User | undefined,
    token: string | undefined,
};

const InitialState = {
    currentUser: undefined,
    token: undefined,
};

const SESSION_KEY = 'trigo-digital-twin';


@Injectable({ providedIn: 'root' })
export class AuthState {
    private state = signal<State>(this.getSessionData() ?? InitialState);

    readonly currentUser = computed(() => this.state().currentUser);
    readonly token = computed(() => this.state().token);
    readonly isAuthenticated = computed(() => this.state().token !== undefined);

    constructor() {
        effect(() => this.setSessionData(this.state()));
    }

    setCurrentUser(user: User): void {
        this.state.mutate((state) => state.currentUser = { ...state.currentUser, ...user });
    }

    setToken(token: string): void {
        this.state.update((state) => ({ ...state, token }));
    }

    clear(): void {
        this.state.set(InitialState);
    }

    private getSessionData() {
        return JSON.parse(sessionStorage.getItem(SESSION_KEY))
    }

    private setSessionData(state: State) {
        sessionStorage.setItem(
            SESSION_KEY,
            JSON.stringify({ ...this.getSessionData, ...state })
        )
    }
}
