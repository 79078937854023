import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { LayoutService } from './service/app.layout.service';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html',
    styleUrls: [ './app.menu.component.scss' ]
})
export class AppMenuComponent implements OnInit {

    model: any[] = [];

    constructor(
        public layoutService: LayoutService,
        public translateService: TranslateService
    ) { }

    ngOnInit() {
        this.model = [
            {
                label: this.translateService.instant('app.menu.groups.sessions'),
                items: [
                    { label: this.translateService.instant('app.menu.sessions'), icon: 'manage_search', routerLink: [ '/sessions' ] },
                ]
            },
            {
                label: this.translateService.instant('app.menu.groups.guides'),
                items: [
                    { label: this.translateService.instant('app.menu.measurementGuides'), icon: 'straighten', routerLink: [ '/measurement-guides' ] },
                    { label: this.translateService.instant('app.menu.recommendationGuides'), icon: 'reviews', routerLink: [ '/recommendation-guides' ] }
                ]
            },
            {
                label: this.translateService.instant('app.menu.groups.templates'),
                items: [
                    { label: this.translateService.instant('app.menu.templates'), icon: 'description', routerLink: [ '/report-templates' ] }
                ]
            },
            {
                label: this.translateService.instant('app.menu.groups.admin'),
                items: [
                    { label: this.translateService.instant('app.menu.users'), icon: 'pi pi-fw pi-user', routerLink: [ '/users' ] },
                    { label: this.translateService.instant('app.menu.devices'), icon: 'smartphone', routerLink: [ '/devices' ] }
                ]
            }
        ];


        this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
            this.processMenuTranslation(this.model);
        })

    }

    processMenuTranslation(children: MenuItem[]) {
        for (let item of children) {
            if (item.separator)
                continue;
            item.label = this.translateService.instant(item.label);
            if (item.items) {
                this.processMenuTranslation(item.items);
            }
        }
    }
}
